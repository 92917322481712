import React from 'react'
import DefaultFooter from './Footer'
import { createTeleporter } from 'react-teleporter'
import COLOR from '@theme/colors'
const FooterTeleporter = createTeleporter()

export const FooterTarget = () => <FooterTeleporter.Target role="app-footer" />

export const FooterSource = ({ children, hideFooter }: any) => (
  <FooterTeleporter.Source>
    {hideFooter ? (
      children
    ) : (
      <>
        {children}
        <DefaultFooter />
      </>
    )}
  </FooterTeleporter.Source>
)

export const Footer = () => {
  return (
    <footer style={{ marginTop: 'auto', backgroundColor: COLOR.bgColorSecondary }} >
      <FooterTarget />
    </footer>
  )
}

export { DefaultFooter }

export default Footer
