
import React, { useState, useEffect } from 'react'
import { BlockpassLogo, MenuLogo } from '@components/Icon/CommonIcons'
import IconButton from '@mui/material/IconButton';
import { HeaderWrapper } from './Header.styled'
import { Typography } from "@blockpass-org/ui";
import useRootStore from '@hooks/useRootStore'
import { COLOR } from '@theme/colors'
import MenuHeader, { CreateServiceHeader } from '@/components/MenuHeader'
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import makeStyles from '@mui/styles/makeStyles';
import { observer } from 'mobx-react-lite'
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import get from 'lodash/get'
import {
  REDIRECT_TO_DOCUMENT
} from '@constants/constants'
import Link from "@mui/material/Link";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import withStyles from '@mui/styles/withStyles'
import SelectMenuHeader from '@/components/SelectMenuHeader';
import loadable from '@loadable/component'
import SessionExpriedDialog from '@components/SessionExpriedDialog'
const DashboardDialog = loadable(() => import('@/components/DashboardDialog'))
const Profile = loadable(() => import('@/components/Profile'))
const SelectLanguage = loadable(() => import('@/components/SelectLanguage'))
// const { SubMenuHeader, CreateServiceHeader, MenuHeader } = loadable(() => import('@/components/MenuHeader'))

const CreateServiceV3 = loadable(() => import('@components/CreateServiceV3'),
  {
    fallback: <Box style={{ width: 600, height: 534, position: 'relative' }}>
      <CircularProgress size={48} style={{
        color: COLOR.commonColor.primary,
        margin: 0,
        position: 'absolute',
        top: '240px',
        left: '275px',
      }} />
    </Box>
  })

const CreateServiceV2 = loadable(() => import('@components/CreateServiceV2'),
  {
    fallback: <Box style={{ width: 600, height: 534, position: 'relative' }}>
      <CircularProgress size={48} style={{
        color: COLOR.commonColor.primary,
        margin: 0,
        position: 'absolute',
        top: '240px',
        left: '275px',
      }} />
    </Box>
  })

const useStyles = makeStyles((theme) => ({
  container: {
    background: COLOR.commonColor.primary,
    width: '100%'
  },
  flex: {
    display: 'flex',
    height: 80
  },
  flexRight: {
    height: 80,
    backgroundColor: COLOR.commonColor.primary,
    paddingRight: 32
  },
  flexMini: {
    display: 'flex',
    height: 72
  },
  flexRightMini: {
    height: 72,
    backgroundColor: COLOR.commonColor.primary,
    paddingRight: 32
  },
  divider: {
    height: '80px !important',
    margin: 'auto !important',
    marginRight: '6px !important',
    borderColor: `rgba(255, 255, 255, 0.2) !important`
  },
  dividerMini: {
    height: '72px !important',
    margin: 'auto !important',
    marginRight: '12px !important',
    borderColor: `rgba(255, 255, 255, 0.2)  !important`
  },
  blockpassLogo: {
    display: 'flex',
    margin: 'auto',
    width: 280 - 56, //+ divider = side bar 
    cursor: 'pointer'
  },
  blockpassLogoMini: {
    display: 'flex',
    margin: 'auto',
    width: 104,
    cursor: 'pointer'
  },
  blockpassLogoText: {
    color: `${COLOR.commonColor.white} !important`,
    marginLeft: '16px  !important',
    margin: 'auto  !important',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  },
  rightPack: {
    display: 'flex',
    margin: 'auto',
    height: '100%'
  },
  iconButton: {
    padding: '0px !important'
  },
  dummyMenu: {
    width: 56,

  },
  link: {
    margin: 'auto 0px !important',
    padding: '8px 16px !important',
    minWidth: '32px !important',
    color: `${COLOR.commonColor.white} !important`
  },
}))

type HeaderProps = {
  children?: React.ReactNode
  hideHeader?: boolean
}

export const Header = observer(
  function Header(props: HeaderProps, ref: React.ForwardedRef<HTMLDivElement>) {
    const [debug, setDebug] = useState(false);
    const [kybServices, setKybServices] = useState(false);
    const {
      LocalizationStore: { translate },
      DashboardStore,
      RouteStore,
      AuthStore,
    } = useRootStore()

    useEffect(() => {
      const debug = JSON.parse(localStorage.getItem('DEBUG'));
      setDebug(debug);

      const kybServices = JSON.parse(localStorage.getItem('FEAT_KYB')) &&
        get((window as any).env, 'FEAT_ENABLE_KYB', '0') === '1'
      if (kybServices)
        setKybServices(kybServices);


    }, [DashboardStore, RouteStore]);

    const { activeHeader } = DashboardStore

    const classes = useStyles()


    const LimitedBackdrop = withStyles({
      root: {
        position: "absolute",
        zIndex: 2,
        background: 'rgba(255,255,255,0.6)'
      }
    })(Backdrop);

    const onClick = (active) => {
      const isCatchedMicro = DashboardStore.isCatchAllMicro()

      if (isCatchedMicro === false) //loading micro
        return

      let isServiceDetail = false
      if (RouteStore.location.pathname) {
        isServiceDetail = RouteStore.location.pathname.includes('services/update') && active === 4
      }
      if (activeHeader !== active || isServiceDetail) {
        DashboardStore.activeHeader = active
        switch (active) {
          case 0:
            RouteStore.push('/services')
            DashboardStore.isKYBService = false
            break
          case 1:
          case 2:
            const allowRoles = ['admin', 'operator']
            const system = AuthStore.hasPermission(allowRoles)
            if (system) {
              RouteStore.push('/administration/systemlog')
            } else {
              RouteStore.push('/logs')
            }
            break
          case 3:
            const allowRoleAdmin = ['admin']
            const admin = AuthStore.hasPermission(allowRoleAdmin)
            if (admin) {
              RouteStore.push('/administration')
            }
            break
          case 5: //company -> no active tab on header
            break
          case 6:
            RouteStore.push('/kyb/services')
            DashboardStore.isKYBService = true
            break
          default:
            RouteStore.push('/services')
            // check -> lock header
            // if(catchedMicro){ // activityLogs

            // }
            break
        }

      }
    }


    const onClickCreateService = () => {
      DashboardStore.resetCreateServiceData()

      DashboardStore.processDone = false
      DashboardStore.ignoreGenerateClientID = false

      DashboardStore.openCreateService = true


    }
    const handleMenuClick = () => {
      DashboardStore.openDrawer = !(DashboardStore.openDrawer)
    }

    const onClose = async () => {
      // update services role
      // await AuthStore.getMerchantProfile()

      if (!DashboardStore.resquestPending) {
        DashboardStore.openCreateService = false
      }

    }

    const onHandleClose = async () => {
      // update services role
      // await AuthStore.getMerchantProfile()
      DashboardStore.openCreateService = false
    }


    // const onClickHelp = () => {
    //   window.open(REDIRECT_TO_DOCUMENT, '_blank')
    // }



    const theme = useTheme();
    const downSm = useMediaQuery(theme.breakpoints.down('md')) //600-768
    const allowRoleAdmin = ['admin']
    const admin = AuthStore.hasPermission(allowRoleAdmin)

    const allowRoleLogs = ['admin', 'operator']

    const { hideHeader } = props
    const isServiceDetail = RouteStore?.location?.pathname?.includes('services/update') || false
    const catchedMicro = DashboardStore.isCatchAllMicro()

    const activityObj = (disabled) => {
      const logs = AuthStore.hasPermission(allowRoleLogs)
      return logs ? <MenuHeader label={translate('txtActivityLogs')} onClick={onClick} active={1} miniStyle={downSm} disabled={disabled}></MenuHeader> :
        <MenuHeader label={translate('txtActivityLogs')} onClick={onClick} active={2} miniStyle={downSm} disabled={disabled}></MenuHeader>
    }
    const adminToolObj = (disabled) => {
      return admin && debug ?
        <MenuHeader label={translate('txtAdministration')} onClick={onClick} active={3} miniStyle={downSm} disabled={disabled}></MenuHeader> : <></>
    }

    const headerMenu = (isTablet) => {
      if (!isTablet) {
        return (<>
          <MenuHeader label={translate('txtKycServices')} onClick={onClick} active={0} miniStyle={downSm} disabled={!catchedMicro}></MenuHeader>
          {
            kybServices ? (
              <MenuHeader label={translate('txtKybServices')} onClick={onClick} active={6} miniStyle={downSm} disabled={!catchedMicro}></MenuHeader>
            ) : (
              <></>
            )
          }
          {
            activityObj(!catchedMicro)
          }
          {
            adminToolObj(!catchedMicro)
          }</>
        )
      } else {
        return (<>
          <SelectMenuHeader onClose={onClick} catchedMicro={catchedMicro} showAdmin={admin && debug} showLogs={AuthStore.hasPermission(allowRoleLogs)} />
        </>)
      }
    }
    return !hideHeader ? (
      <>
        <HeaderWrapper >
          <Box className={classes.container}>
            <DashboardDialog title={translate('txtCreateServiceDashboard')} open={DashboardStore.openCreateService}
              withoutTitle={DashboardStore.processDone}
              onClose={onClose}
              maxWidth={'xl'}
              onBackdropClick={onClose}
              disableEscapeKeyDown={false}
              noDivider={false}
            >
              <LimitedBackdrop
                open={DashboardStore.resquestPending}
              // onClick={handleClose}
              >
                <CircularProgress size={48} style={{ color: COLOR.commonColor.primary }} />
              </LimitedBackdrop>
              {
                kybServices ? (<CreateServiceV3 handleClose={onHandleClose} />) : (<CreateServiceV2 handleClose={onHandleClose} />)
              }

            </DashboardDialog>
            {/* expired */}
            <SessionExpriedDialog open={AuthStore.openSessionExpired} />
            {/* <DashboardDialog title={translate('txtUserSessionExpired')}
              withoutTitle={false}
              onClose={null} maxWidth={'xs'}
              onBackdropClick={null}
              disableEscapeKeyDown={false}
              noDivider={true}
            >
             
            </DashboardDialog > */}

            <Box style={{ width: '100%', justifyContent: 'space-between', display: 'flex', }}>
              <div className={downSm ? classes.flexMini : classes.flex}>
                {
                  isServiceDetail ? (<IconButton aria-label="refresh" onClick={handleMenuClick} sx={{
                    '&.MuiIconButton-root': {
                      borderRadius: '4px !important',
                      width: '40px !important',
                      height: '40px !important',
                      margin: 'auto',
                      marginLeft: '16px'

                    }
                  }}>
                    <MenuLogo />
                  </IconButton>) : (<Box className={classes.dummyMenu}></Box>)
                }

                <Box className={downSm ? classes.blockpassLogoMini : classes.blockpassLogo} onClick={() => onClick(-1)}>
                  <BlockpassLogo style={{ width: 48, height: 48, cursor: 'pointer', marginLeft: downSm ? 30 : 8 }}></BlockpassLogo>
                  {
                    downSm ? (<></>) : (<Typography variant='body1' className={classes.blockpassLogoText}>
                      {translate('txtAdminConsole')}
                    </Typography>)
                  }

                </Box>
                <Divider orientation={'vertical'} className={downSm ? classes.dividerMini : classes.divider} />
                {
                  headerMenu(downSm)
                }

              </div>

              {

                <div className={downSm ? classes.flexRightMini : classes.flexRight}>
                  <Box className={classes.rightPack}>
                    <CreateServiceHeader label={translate('txtCreateServiceDashboard')} onClick={onClickCreateService} miniStyle={downSm} />
                    {/* <SubMenuHeader onClick={onClickHelp} label={translate('txtDocs')} miniStyle={downSm}></SubMenuHeader> */}

                    <Link
                      variant={"body1"}
                      // onClick={onClickHelp}
                      className={classes.link}
                      href={REDIRECT_TO_DOCUMENT}
                      target="_blank"
                      rel="noopener"

                    >
                      {translate("txtDocs")}
                    </Link>

                    <SelectLanguage></SelectLanguage>
                    <div style={{ marginLeft: 4 }}> <Profile miniStyle={downSm} /></div>
                  </Box>
                </div>
              }
            </Box>
          </Box>
          {/* </ElevationScroll> */}
        </HeaderWrapper>
      </>
    ) : <></>
  },
  { forwardRef: true }
)
export default Header