import { observable, makeObservable, toJS } from "mobx";
import BaseDomainStore from "../base/BaseDomainStore";
import RootStore from '../../stores/RootStore'
import DashboardObj from './DashboardObj'
import {validateEmail, validateUrlHttps} from '../../utils'
import get from 'lodash/get'
import {
  SERVICE_DISPLAY_KEY, KYB_SERVICE_DISPLAY_KEY, SERVICE_ADMIN_KEY,
  SERVICE_APIKEY_KEY, SERVICE_BILLING_KEY, SERVICE_TEAM_KEY, SERVICE_WEBHOOK_KEY, SERVICE_USAGE_KEY
} from '@constants/constants'

import { filterXSS } from 'xss'
// import AuthStore from "../cores/AuthStore";
import { NOTI_AUTO_HIDE_TIME } from "@workspace/shared/constants/constants";
const optionFilterXss = {
  whiteList: {},
  stripIgnoreTag: true,
  stripIgnoreTagBody: ['script'],
}

const { ContainerStore } = require('@blockpass-org/micro-bridge')



enum ActiveHeader {
  Services = 0,
  SystemLogs,
  ActivityLogs,
  Admimnistration
}
class DashboardStore extends BaseDomainStore {
  _rootStore: RootStore
  searchInput = ''
  filter= {}
  page =  0
  sort = {}
  limit= 10
  loadingList = false
  searchInputKYB = ''
  filterKYB= {}
  pageKYB =  0
  sortKYB = {}
  limitKYB= 10

  // footer
  emailSubcribe = ''
  helperTextEmailSubcribe = ''

  // header
  activeHeader = ActiveHeader.Services
  // main content
  openDrawer = true
  openCreateService = false
  openEditClientId = false
  
  //micro
  microLoaded = false
  containerStore = ContainerStore
  planConfig = {}
  displayPlanConfig = {}
  loadBillingConfigDone = false
  microHost = []
  microConfig = []

  resquestPending= false
  subcribeLoading = false
  //createService
  touchClientd = false
  verifyingClientId = true
  createServiceName = ''
  createServiceClientIdTemp = ''
  createServiceClientId = ''
  createServiceClientIdSeed = ''
  createServiceLogo = null
  createServiceThumb = null
  errorTextCreateServiceName = ''
  errorTextCreateServiceClientId = ''
  errorTextTempCreateServiceClientId = ''
  errorTextCreateServiceLogo = ''

  createdService = {}
  processDone = false
  ignoreGenerateClientID = false
  //tutorial
  tutorialComplete = false

  //service list
  services = {}
  kybServices = {}
  //shareable
  errorTextWebsiteUrl = 'txtRequired'
  shareableMethod = 'public'
  sharealbeLink = ''
  //service detail
  serviceIdSelected = ''
  serviceInfo = {}

  isUpdatingLiveStatus = false
  isUpdatingService = false
  hideFooter = false

  catchedMicroList = []
  detailIdList = []
  catchMicroDetail = false
  sentLoadingTime = false
  timeStart = 0

  //KYB
  isKYBService = false

  //login
  loginLoading = false
  constructor(rootStore: any) {
    super(DashboardObj);


    // event
    window.addEventListener("hashchange", (e) => {
      this.openCreateService = false
      this.openEditClientId = false
    })

    makeObservable(this, {
      // 
      searchInput: observable,
      filter: observable,
      page: observable,
      loadingList: observable,
      sort:observable,
      limit: observable,

      emailSubcribe: observable,
      helperTextEmailSubcribe: observable,
      activeHeader: observable,
      openDrawer : observable,
      openCreateService: observable,
      openEditClientId: observable,
      touchClientd: observable,
      containerStore: observable,
      planConfig : observable,
      displayPlanConfig: observable,
      loadBillingConfigDone: observable,
      microHost: observable,
      microConfig: observable,
      microLoaded: observable,
      createServiceName: observable,
      createServiceClientId: observable,
      createServiceClientIdTemp: observable,
      createServiceClientIdSeed: observable,
      createServiceLogo: observable,
      createServiceThumb: observable,
      errorTextCreateServiceName: observable,
      errorTextCreateServiceClientId: observable,
      errorTextTempCreateServiceClientId: observable,
      errorTextCreateServiceLogo: observable,
      tutorialComplete: observable,
      processDone: observable,
      ignoreGenerateClientID: observable,
      createdService: observable,
      verifyingClientId: observable,
      resquestPending: observable,

      services: observable,
      serviceInfo: observable,
      serviceIdSelected : observable,

      isUpdatingLiveStatus: observable,
      isUpdatingService: observable,
      hideFooter: observable,
      sentLoadingTime: observable,
      timeStart: observable,
      catchedMicroList:observable,
      detailIdList:observable,
      catchMicroDetail:observable,

      errorTextWebsiteUrl: observable,
      shareableMethod:observable,
      sharealbeLink:observable,

      isKYBService:observable,
      subcribeLoading:observable,

      loginLoading:observable,
    });

    this._rootStore = rootStore;
  }

  
  updateCache = (name) => {
    if(!this.catchedMicroList.includes(name)){
      setTimeout(() => {
        this.catchedMicroList.push(name)
        // check service detail all done
        let isAllDetailDone = true
        this.detailIdList.forEach(id => {
          if(this.isCatched(id) === false){
            isAllDetailDone = false
          }
        }); 
        this.catchMicroDetail = isAllDetailDone
      }, 3000)
     
    }
  }
  isCatched = (name) =>{
    return this.catchedMicroList.includes(name)
  }
  
  clearCacheCheck = () => {
    this.catchedMicroList = []
  }
  
  isCatchAllMicro = () => {
    return this.isCatched('ActivityLogs') &&
        this.isCatched('KybServiceDisplay') &&
        this.isCatched('ServiceDisplay') &&
        this.isCatched('Company') &&
        this.catchMicroDetail
  }
  getErrorMessage = (error: any) => {
    let msg = "txtUnauthorized";
    if (error.code === 401) {
      // AuthStore.setAuthenticated(false)
      error.message = "txtUnauthorized";
    } else if (error.code === 400) {
      msg = error.message;
    } else {
      msg = error.message;
    }
    return msg;
  };

  showNotificationWithString = (notiString) => {
    const {
      UINotificationStore,
    } = this._rootStore;
    UINotificationStore.showSnackbar({
      message: notiString,
      options: {
        variant: 'notification',
        actionLabel: '',
        // actionCallback: null,
        dismiss: true,
        preventDuplicate: false,
        autoHideDuration: NOTI_AUTO_HIDE_TIME,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left'
        },
      },
    })

  }

  showNotificationWithError = (error) => {
    const {
      UINotificationStore,
      AuthStore,
      LocalizationStore: { translate },
    } = this._rootStore;
    if (error.code === 401) {
      AuthStore.setOpenSessionExpired(true)
    } else if (error.code === 500) {
      UINotificationStore.showSnackbar({
        message: 'An unexpected error happened on the server',
        options: {
          variant: 'notification',
          actionLabel: 'RELOAD',
          dismiss: true,
          preventDuplicate: false,
          autoHideDuration: NOTI_AUTO_HIDE_TIME,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left'
          },
          actionCallback: () => {
            window.location.reload();
          },
          persist: true
        },
      })
    } else {
      UINotificationStore.showSnackbar({
        message: translate(error.message),
        options: {
          variant: 'notification',
          actionLabel: '',
          // actionCallback: null,
          dismiss: true,
          preventDuplicate: false,
          autoHideDuration: NOTI_AUTO_HIDE_TIME,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left'
          },
        },
      })
    }
  }
  
  getHostInfoByKey(key: string) {
    return this.microHost.find(host => host.key === key)
  }

  getMicroConfigDetail(key: string) {
    return this.microConfig.find(config => config.key === key)
  }

  setSearchInput = async (input: any) => {
   
    if (!input || input.trim().length <= 0) {
      this.searchInput = ''
      // reset param
      this.filter = {}
      this.page = 0
      this.sort = {}
      return
    } else if (input === this.searchInput) {
      return
    } else {
      this.searchInput = input
      this.filter = { name: this.searchInput}
    }
    
  }
  updatePage = (page = 0) => {
    this.page = page
  }
  updateSort = (data = {}) => {
    // only sort 1 field
    this.sort = {}
    this.sort[data['field']] = data['sort']

    // reset page
    this.page = 0
  }

  updateLimit = (limit = 10) => {
    this.limit = limit
  }

  // kyb
  setSearchInputKYB = async (input: any) => {
   
    if (!input || input.trim().length <= 0) {
      this.searchInputKYB = ''
      // reset param
      this.filterKYB = {}
      this.pageKYB = 0
      this.sortKYB = {}
      return
    } else if (input === this.searchInputKYB) {
      return
    } else {
      this.searchInputKYB = input
      this.filterKYB = { name: this.searchInputKYB}
    }
    
  }
  updatePageKYB = (page = 0) => {
    this.pageKYB = page
  }
  updateSortKYB = (data = {}) => {
    // only sort 1 field
    this.sortKYB = {}
    this.sortKYB[data['field']] = data['sort']

    // reset page
    this.pageKYB = 0
  }

  updateLimitKYB = (limit = 10) => {
    this.limitKYB = limit
  }

  validateEmail = (email) => {
    if(email.length <= 0){
      this.helperTextEmailSubcribe = 'txtInvalidEmailFormat'
    }
    else{
      const valid = validateEmail(email)
      if(!valid){
        this.helperTextEmailSubcribe = 'txtInvalidEmailFormat'
      }
      else{
        this.helperTextEmailSubcribe = ''
      }
    }
  }

   validUrl = (url) => {
    const displayUrl = url.replace('https://', '')
    if(displayUrl.length <= 0){
      this.errorTextWebsiteUrl = 'txtWebsiteUrlIsRequired'
    } else {
      if(validateUrlHttps(url))
      {
        this.errorTextWebsiteUrl = 'txtRequired'
      } else {
        this.errorTextWebsiteUrl = 'txtInvalidUrl'
      }
    }
   
  }
  
  formpassURL(id) {
    if(this.isKYBService){
      const formpassURL = get(window, 'env.KYB_DASHBOARD','')
      const baseUrl = formpassURL.endsWith('/')
        ? formpassURL
        : `${formpassURL}/`
      if (id === '') {
        return baseUrl
      } else {
        return `${baseUrl}${id}`
      }
    } else {
      const formpassURL = get(window, 'env.FORMPASS_URL','')
      const baseUrl = formpassURL.endsWith('/')
        ? formpassURL
        : `${formpassURL}/`
      if (id === '') {
        return baseUrl
      } else {
        return `${baseUrl}${id}/kyc_list`
      }
    }
  }

  uploadImage = async (file, fileName, isThumb = false) =>{
    const {
      AuthStore,
    } = this._rootStore;
    try{
      
      const {consoleClient} = AuthStore
      if(consoleClient){
        this.resquestPending = true
        const res = await consoleClient.uploader.uploadImage(file, fileName)
       
        if(res.result !== null){
          if(isThumb){
            this.createServiceThumb = res.result
          } else {
            this.createServiceLogo = res.result
          }
          
        } else {
          if(isThumb){
            this.createServiceThumb = ''
          } else {
            this.createServiceLogo = ''
          }
          
        }
        this.resquestPending = false
      }
    } catch (error) {
      this._rootStore.debugLog('error: ', error)  
      this.resquestPending = false
      //ignore fake logo
      if(this.createServiceLogo === 'fakeImg' || this.createServiceThumb === 'fakeImg'){

      } else {
        this.showNotificationWithError(error)
      }
      
    
    }
  }

  
  updateUnsubcribed =  async () =>{
    const {
      AuthStore,
      
    } = this._rootStore;
    try{
      const {consoleClient} = AuthStore
      if(consoleClient){
        this.subcribeLoading = true
        const res = await consoleClient.mailSubscription.unsubscribe()
        if(res.result === null){
          AuthStore.setSubscribed(false)
          this.subcribeLoading = false
          return true
        } else {
          this.subcribeLoading = false
          return false
        }
        
      }
    } catch (error) {
      this._rootStore.debugLog('error: ', error)   
      this.subcribeLoading = false
      this.showNotificationWithError(error)
      return false
    }
  }

  updateSubcribed =  async () =>{
    const {
      AuthStore,
      
    } = this._rootStore;
    try{
      const {consoleClient} = AuthStore
      if(consoleClient){
        this.subcribeLoading = true
        const res = await consoleClient.mailSubscription.subscribe(this.emailSubcribe)
        if(res.result === null){
          // set profile
          AuthStore.setSubscribed(true)
          this.subcribeLoading = false
          return true
        } else {
          this.subcribeLoading = false
          return false
        }
        
      }
    } catch (error) {
      this._rootStore.debugLog('error: ', error)   
      this.subcribeLoading = false
      this.showNotificationWithError(error)
      return false
    }
  }

  createService = async (fromWelcome: boolean = false) =>{
    const {
      AuthStore,
      LocalizationStore: { translate },
    } = this._rootStore;
    try{
      
      const {consoleClient} = AuthStore
      if(consoleClient){
        this.resquestPending = true
        //trim data
        if (this.createServiceLogo.includes('?t=')) {
          this.createServiceLogo = this.createServiceLogo.split('?')[0]
        }
        if (this.createServiceThumb.includes('?t=')) {
          this.createServiceThumb =this.createServiceThumb.split('?')[0]
        }

        //allrole => 'formpass'
        
        const res = await consoleClient.serviceManagement.createService(
          filterXSS(this.createServiceName.trim(), optionFilterXss),
          filterXSS(this.createServiceClientId, optionFilterXss),
          this.createServiceLogo, this.createServiceThumb, 'formpass',
          fromWelcome
          )
       
        if(res.result !== null){
          this.createdService = res

          this.showNotificationWithString(translate('txtCreateServiceSuccess'))
          this.resquestPending = false
          return true
        } else {
          this.resquestPending = false
          return false
        }
        
      }
    } catch (error) {
      this._rootStore.debugLog('error: ', error)   
      this.resquestPending = false
      this.showNotificationWithError(error)
      return false
    }
  }

  createKYBService = async (fromWelcome: boolean = false) =>{
    const {
      AuthStore,
      LocalizationStore: { translate },
    } = this._rootStore;
    try{
      const {consoleClient} = AuthStore
      if(consoleClient){
        this.resquestPending = true
        //trim data
        if (this.createServiceLogo.includes('?t=')) {
          this.createServiceLogo = this.createServiceLogo.split('?')[0]
        }
        if (this.createServiceThumb.includes('?t=')) {
          this.createServiceThumb =this.createServiceThumb.split('?')[0]
        }

        // no support email
        const res = await consoleClient.kybServiceManagement.createKYBService(
          filterXSS(this.createServiceName.trim(), optionFilterXss), 
          filterXSS(this.createServiceClientId, optionFilterXss) , 
          this.createServiceLogo, 
          this.createServiceThumb,
          undefined,
          fromWelcome
          )
        if(res.result !== null){
          this.createdService = res

          this.showNotificationWithString(translate('txtCreateServiceSuccess'))
          this.resquestPending = false
          return true
        } else {
          this.resquestPending = false
          return false
        }
        
      }
    } catch (error) {
      this._rootStore.debugLog('error: ', error)   
      this.resquestPending = false
      this.showNotificationWithError(error)
      return false
    }
  }

  setMarkTutorialComplete = async () =>{
    const {
      AuthStore,
    } = this._rootStore;
    try{
      const {consoleClient} = AuthStore
      if(consoleClient){
        const res = await consoleClient.merchantProfile.markTutorialComplete()
       
        if(res.result !== null){
          // tutorialCompleted
          
        }
        
      }
    } catch (error) {
      this._rootStore.debugLog('error: ', error)   
      this.showNotificationWithError(error)
    }
  }

  
//service list
  requestListService = async (page: any = 0, limit: any = 10, filter: any = {}, sort: any = {createdAt: 'desc'}) =>{
    const {
      AuthStore,
    } = this._rootStore;
   
    try{
      const {consoleClient} = AuthStore
      if(consoleClient){
        this.loadingList = true
        const res = await consoleClient.serviceManagement.getListService(filter, sort, page,limit)
       
        if(res.result !== null) {
          this.services = res
        }
        this.loadingList = false
      }} catch (error) {
        this.loadingList = false
        this._rootStore.debugLog('error: ', error)  
        this.showNotificationWithError(error)
      }
     
  }

  requestListKYBService = async (page: any = 0, limit: any = 10, filter: any = {}, sort: any = {createdAt: 'desc'}) =>{
    const {
      AuthStore,
    } = this._rootStore;
   
    try{
      const {consoleClient} = AuthStore
      if(consoleClient){
        this.loadingList = true
        const res = await consoleClient.kybServiceManagement.getListKYBService(filter, sort, page,limit)
        if(res.result !== null) {
          this.kybServices = res
        }
        this.loadingList = false
      }} catch (error) {
        this.loadingList = false
        this._rootStore.debugLog('error: ', error)  
        this.showNotificationWithError(error)
      }
     
  }
  
  getServiceList = () => {
    const objService = toJS(get(this.services, 'entities.services', {}))
    const objResult = toJS(get(this.services, 'result', []))
    let result = []
    objResult.forEach(e => {
        if(get(objService, `${e}`, null)) {
          result.push(get(objService, `${e}`, {}))
        }
    });
   
    //img cache
    result.map((k) => {
      if(k.hasOwnProperty('logo')){
         k.logo = k.logo + `?t=${Date.now()}`
      }
      if(k.hasOwnProperty('thumbnail')){
        k.thumbnail = k.thumbnail + `?t=${Date.now()}`
      }
      return k
    })
    return result
    
  }
  
  getKYBServiceList = () => {
    const objService = toJS(get(this.kybServices, 'entities.services', {}))
    const objResult = toJS(get(this.kybServices, 'result', []))
    let result = []
    objResult.forEach(e => {
        if(get(objService, `${e}`, null)) {
          result.push(get(objService, `${e}`, {}))
        }
    });
   
    //img cache
    result.map((k) => {
      if(k.hasOwnProperty('logo')){
         k.logo = k.logo + `?t=${Date.now()}`
      }
      if(k.hasOwnProperty('thumbnail')){
        k.thumbnail = k.thumbnail + `?t=${Date.now()}`
      }
      return k
    })
    return result
    
  }

  getPagingInfo = () => {
    return toJS( get(this.services, 'paging', {}))
  }
  getKYBPagingInfo = () => {
    return toJS( get(this.kybServices, 'paging', {}))
  }
  validateClientID = (value) => {
    this.createServiceClientId = value
    const regex = /^[a-zA-Z0-9_]+$/
    if (!value || !value.match(regex)) { //no need check format here -> check in edit ClientId
      this.errorTextCreateServiceClientId = ''
      return false
    } else if(value.length <= 3) {
      this.errorTextCreateServiceClientId = 'txtLengthTooShort'
      return false
    }else{
      this.errorTextCreateServiceClientId = ''
      return true
    }

  }
  validateTempClientID = (value) => {
   
    this.createServiceClientIdTemp = value
    const regex = /^[a-zA-Z0-9_]+$/
    if (!value || !value.match(regex)) {
      this.errorTextTempCreateServiceClientId = 'txtIncorrectFormat'
      return false
    } else if(value.length <= 3) {
      this.errorTextTempCreateServiceClientId = 'txtLengthTooShort'
      return false
    }else{
      this.errorTextTempCreateServiceClientId = ''
      return true
    }

  }

  isValidServiceClientId = async (clientId, isTemp = false) => {
    const {
      AuthStore,
    } = this._rootStore;
    try{
      const {consoleClient} = AuthStore
      this.verifyingClientId = true
      if(consoleClient){
       
        const rs = await consoleClient.serviceManagement.isValidServiceClientId(clientId)
       
        // rs: true|false
        if(rs) {
          this.errorTextCreateServiceClientId = ''
          if(isTemp){
            this.errorTextTempCreateServiceClientId = ''
          }
        } else {
          this.errorTextCreateServiceClientId = 'txtClientIdExisted'
          if(isTemp){
            this.errorTextTempCreateServiceClientId = 'txtClientIdExisted'
          }
        }
        
      }
      this.verifyingClientId = false
    } catch (error) {
        this._rootStore.debugLog('error: ', error)  
        this.verifyingClientId = false
        this.showNotificationWithError(error)
      }
    

  }

 
  updateService = async () => {
    const {
      AuthStore,
      LocalizationStore: { translate },
    } = this._rootStore;
    try{
      const {consoleClient} = AuthStore
      this.isUpdatingService = true
      const serviceId = get(this.serviceInfo, '_id', '')
     
      const data = {
        shareable_method: this.shareableMethod,
        shareable_link: filterXSS(`https://${this.sharealbeLink}`, optionFilterXss) //add https://
      }
      if(this.shareableMethod === 'public'){
        delete data.shareable_link
      }
      
      if(consoleClient){
        // const res = await consoleClient.serviceManagement.updateServiceLiveStatus(serviceId, isLive)
        const res = await consoleClient.serviceDisplay.updateServiceDisplay(serviceId, data)
        if(res.result !== null){
         
          this.showNotificationWithString(translate('txtUpdateServiceSuccess'))
        }
      }
      this.isUpdatingService = false
    } catch (error) {
        this._rootStore.debugLog('error: ', error)  
        this.isUpdatingService = false
        this.showNotificationWithError(error)
      }
    

  }

  updateKYBService = async () => {
    const {
      AuthStore,
      LocalizationStore: { translate },
    } = this._rootStore;
    try{
     
      const {consoleClient} = AuthStore
      this.isUpdatingService = true
      const serviceId = get(this.serviceInfo, '_id', '')
     
      const data = {
        shareableMethod: this.shareableMethod,
        shareableLink: filterXSS(`https://${this.sharealbeLink}`, optionFilterXss)//add https://
      }
      if(this.shareableMethod === 'public'){
        delete data.shareableLink
      }
      
      if(consoleClient){
        // const res = await consoleClient.serviceManagement.updateServiceLiveStatus(serviceId, isLive)
        const res = await consoleClient.kybServiceManagement.updateKYBService(serviceId, data)
        if(res.result !== null){
          this.showNotificationWithString(translate('txtUpdateServiceSuccess'))
        }
      }
      this.isUpdatingService = false
    } catch (error) {
        this._rootStore.debugLog('error: ', error)  
        this.isUpdatingService = false
        this.showNotificationWithError(error)
      }
    

  }

  
  updateKYBServiceStatus = async (isLive) => {
    const {
      AuthStore,
    } = this._rootStore;
    try{
      const {consoleClient} = AuthStore
      this.isUpdatingLiveStatus = true
      const serviceId = get(this.serviceInfo, '_id', '')
      if(consoleClient){
        await consoleClient.kybServiceManagement.updateKYBServiceLiveStatus(serviceId, isLive)
        this.setLiveStatus(isLive)
      }
      this.isUpdatingLiveStatus = false
    } catch (error) {
        this._rootStore.debugLog('error: ', error)  
        this.isUpdatingLiveStatus = false
        this.showNotificationWithError(error)
      }
    

  }

  updateServiceStatus = async (isLive) => {
    const {
      AuthStore,
    } = this._rootStore;
    try{
      const {consoleClient} = AuthStore
      this.isUpdatingLiveStatus = true
      const serviceId = get(this.serviceInfo, '_id', '')
      if(consoleClient){
        const res = await consoleClient.serviceManagement.updateServiceLiveStatus(serviceId, isLive)
       
        if(res.result !== null){
          this.setLiveStatus(isLive)
        }
      }
      this.isUpdatingLiveStatus = false
    } catch (error) {
        this._rootStore.debugLog('error: ', error)  
        this.isUpdatingLiveStatus = false
        this.showNotificationWithError(error)
      }
    

  }

  setLiveStatus = (isLive) => {
    if(this.serviceInfo.hasOwnProperty('isLive')) {
      this.serviceInfo['isLive'] = isLive
    }
  } 

  resetCreateServiceData = () => {
    this.verifyingClientId = true
    this.createServiceName = ''
    this.createServiceClientId = ''
    this.createServiceClientIdTemp = ''
    this.createServiceClientIdSeed = ''
    this.createServiceLogo = null
    this.createServiceThumb = null
    this.errorTextCreateServiceName = ''
    this.errorTextCreateServiceClientId = ''
    this.errorTextCreateServiceLogo = ''

    this.createdService = {}
  }

  //service info
  setServiceInfo = (s) => {
    this.serviceInfo = s
    if(this.isKYBService){
      this.shareableMethod =  get(this.serviceInfo, 'shareableMethod', 'public')
      this.sharealbeLink = get(this.serviceInfo, 'shareableLink', '')
    } else {
      this.shareableMethod =  get(this.serviceInfo, 'shareable_method', 'public')
      this.sharealbeLink = get(this.serviceInfo, 'shareable_link', '')
    }
   
    // this.validUrl(this.sharealbeLink)
  }
  
  getServiceInfo = () => {
    return toJS(this.serviceInfo)
  }

  getClientId = () => {
    if(this.isKYBService){
      return get(this.serviceInfo, 'clientId', '')
    } else {
      return get(this.serviceInfo, 'client_id', '')
    }
  }
  isAllowUpdateStatusService = () => {
    const { AuthStore } = this._rootStore
    const allowUpdate = ['admin', 'operator', 'owner', 'billing_owner']
    let role = AuthStore.getServiceRoleByClientId(this.getClientId())
    if( role === '') {
      role = AuthStore.getSystemRole()
    }
    return allowUpdate.includes(role)
  }  

  isServiceEnabled = () => {
    if (get(toJS(this.serviceInfo), 'status', '').toString() === 'active') {
      return true
    }
    
    return get(toJS(this.serviceInfo), 'enableRegistration', false)
  }

  setShareableMethod = (method) => {
    // if(this.serviceInfo.hasOwnProperty('shareable_method')){
    //   this.serviceInfo['shareable_method'] = method
    // }
    this.shareableMethod = method
  }

  setShareableLink = (link) => {
    this.sharealbeLink = link
  }

  checkDiffShareable = () => {
    if(get(this.serviceInfo, 'shareable_method', '') !== this.shareableMethod)
      return true
  
    if(get(this.serviceInfo, 'shareable_link', '')  !== this.sharealbeLink)
      return true
      
    return false

  }

  initDetailIdList = () => {
    //service detail
    const microObj = this.getMicroConfigDetail(
      SERVICE_DISPLAY_KEY
    )
    const id = (microObj && microObj?.id) || ''
    this.detailIdList.push(id)

    const microObjApi = this.getMicroConfigDetail(
      SERVICE_APIKEY_KEY
    )
    const idApi = (microObjApi && microObjApi?.id) || ''
    this.detailIdList.push(idApi)
    const microObjBilling = this.getMicroConfigDetail(
      SERVICE_BILLING_KEY
    )
    const idBilling = (microObjBilling && microObjBilling?.id) || ''
    this.detailIdList.push(idBilling)
    const microObjTeam = this.getMicroConfigDetail(
      SERVICE_TEAM_KEY
    )
    const idTeam = (microObjTeam && microObjTeam?.id) || ''
    this.detailIdList.push(idTeam)
    const microObjWebhook = this.getMicroConfigDetail(
      SERVICE_WEBHOOK_KEY
    )
    const idWebhook = (microObjWebhook && microObjWebhook?.id) || ''
    this.detailIdList.push(idWebhook)
    const microObjUsage = this.getMicroConfigDetail(
      SERVICE_USAGE_KEY
    )
    const idUsage = (microObjUsage && microObjUsage?.id) || ''
    this.detailIdList.push(idUsage)
    // kyb
    
    const microObjKybDisplay = this.getMicroConfigDetail(
      KYB_SERVICE_DISPLAY_KEY
    )
    const idKybDisplay = (microObjKybDisplay && microObjKybDisplay?.id) || ''
    this.detailIdList.push(idKybDisplay)

    const microObjServiceAdmin = this.getMicroConfigDetail(
      SERVICE_ADMIN_KEY
    )
    const idServiceAdmin = (microObjServiceAdmin && microObjServiceAdmin?.id) || ''
    this.detailIdList.push(idServiceAdmin)

  }
  
  // pushDataLayer = (data: any) => {
  //   const {
  //     AuthStore: { containerStore },
  //   } = this._rootStore;
  //   if(containerStore?.pushDataLayer){
  //     containerStore?.pushDataLayer(data)
  //   }
   
  // }
} 

export default DashboardStore;
