import { makeObservable, observable } from "mobx";
import BaseViewStore from "@stores/base/BaseViewStore";
const STORE_NAME = "ServiceList.ViewStore";
// ---------------------------------

class ViewStore extends BaseViewStore {
  constructor(rootStore) {
    super(rootStore, STORE_NAME);
    this._initObserver();
    makeObservable(this, {
      ready: observable,
      limit: observable,
      filter: observable,
      page: observable,
      loading: observable,
      searchInput: observable,
      sort:observable,
    });
  }

  _initObserver() {
    this.ready = false;

    this.loading = false
    this.searchInput = ''
    // paging
    this.page = 0
    this.limit = 10
    this.filter = {}
    this.sort = {}
  }

  updateFilter = (searchInput = '') => {
    
    if(searchInput.trim().length <= 0){
      this.searchInput = ''

      // reset param
      this.filter = {}
      this.page = 0
      this.sort = {}
    } else {
      this.searchInput = searchInput
      this.filter = { name: this.searchInput}
    }
    
  }

  updateSort = (data = {}) => {
    // only sort 1 field
    this.sort = {}
    this.sort[data.field] = data.sort

    // reset page
    this.page = 0
  }

  updateLimit = (limit = 10) => {
    this.limit = limit
  }

  updatePage = (page = 0) => {
    this.page = page
  }

  
  // -----------------------------------------------------//
  //  Override Life circle
  // -----------------------------------------------------//
  async start(rootStore) {
    await super.start(rootStore);
    const { DashboardStore } = this.rootStore;
    const {isKYBService} = DashboardStore
    this.loading = true
    if(isKYBService) {
      await DashboardStore.requestListKYBService(DashboardStore.pageKYB, DashboardStore.limitKYB, DashboardStore.filterKYB, DashboardStore.sortKYB);
    } else {
      await DashboardStore.requestListService(DashboardStore.page, DashboardStore.limit, DashboardStore.filter, DashboardStore.sort);
    }
    
    this.loading = false
    this.ready = true;

  }
}

export default ViewStore;
