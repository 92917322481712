import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import useViewStore from '@hooks/useViewStore'
import ViewStore from './ServiceDetail.ViewStore'

import COLOR from '@theme/colors'

import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import useRootStore from '@hooks/useRootStore'
import { SplashPage } from '../SplashPage'
import makeStyles from '@mui/styles/makeStyles'
import { serviceDetails } from './const'
import { get } from 'lodash'
import ServiceSideBar from '@/components/ServiceSideBar'
import DetailFooter from '@/components/DetailFooter'

const useStyles = makeStyles((theme) => ({
  icon: {
    width: 40,
    height: 40,
  },
  iconMenu: {
    padding: '16px 0px 16px 0px',
  },
  leftContent: {
    display: 'block',
    backgroundColor: COLOR.commonColor.white,
  },
  serviceInfo: {
    backgroundColor: COLOR.bgColorSecondary,
    marginLeft: '0px !important',
    height: 'auto',
    width: '100%',
    minHeight: '800px',
    // minWidth: '1078px'
  },
  flex: {
    display: 'flex',
  },
  micro: {
    width: '100%',
    backgroundColor: COLOR.bgColorSecondary,
  },
  boxContent: {
    height: '300px',
  },
  dummySideBar: {
    minWidth: 280,
    borderRight: 1,
    borderColor: 'rgba(0, 0, 0, 0.12)',
  },
  dummySideBarMini: {
    minWidth: 72,
    borderRight: 1,
    borderColor: 'rgba(0, 0, 0, 0.12)',
  },
}))

const ServiceDetail = observer(function ServiceDetail() {
  const classes = useStyles()
  const rootStore = useRootStore()
  const { DashboardStore, RouteStore } = rootStore
  const viewStore = useViewStore(rootStore, ViewStore, {})
  const { isKYBService } = DashboardStore
  const getDetailComponent = (key) => {
    //update selected service id
    const { serviceId } = RouteStore.params
    DashboardStore.serviceIdSelected = serviceId
    let keyActive = key
    if (key === '') {
      //default is display or kyb display
      if (isKYBService) {
        keyActive = 'kybdisplay'
      } else {
        keyActive = 'display'
      }
    }

    const com = get(serviceDetails, `${keyActive}.component`, <></>)
    return com
  }

  const { ready } = viewStore
  const { openDrawer } = DashboardStore

  const updateSelected = viewStore.getUpdateDetail()
  let borderRadiusTop = 0
  if (
    updateSelected === 'usage' ||
    updateSelected === 'plan' ||
    updateSelected === 'admin'
  ) {
    //footer style
    borderRadiusTop = 8
  }
  useEffect(() => {
    DashboardStore.hideFooter = true
  }, [DashboardStore])
  if (ready) {
    return (
      <Box className={classes.flex}>
        {/* menu - main contain */}

        <Box
          className={
            openDrawer ? classes.dummySideBar : classes.dummySideBarMini
          }
        >
          {' '}
        </Box>
        <ServiceSideBar viewStore={viewStore} />
        <Box className={classes.micro}>
          <Stack
            direction={{
              xs: 'column',
              sm: 'column',
              md: 'column',
              lg: 'row',
              xl: 'row',
            }}
            spacing={{ xs: 1, sm: 1, md: 1 }}
          >
            <Box className={classes.serviceInfo}>
              {getDetailComponent(updateSelected)}
            </Box>
          </Stack>
          <DetailFooter borderRadiusTop={borderRadiusTop} />
        </Box>
      </Box>
    )
  } else {
    return (
      <div style={{ height: '100vh', textAlign: 'center' }}>
        <SplashPage />
      </div>
    )
  }
})

export default ServiceDetail
