import { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import useRootStore from '@hooks/useRootStore'
import { Typography } from '@blockpass-org/ui'

import Box from '@mui/material/Box';
import { COLOR } from '@theme/colors'
import makeStyles from '@mui/styles/makeStyles';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import loadable from '@loadable/component'
const MenuAdmin = loadable(() => import('@components/MenuAdmin'))


const useStyles = makeStyles((theme) => ({
  menuContainer: {
    display: 'flex',
    marginBottom: 32
  },
  groupTitle: {
    paddingLeft: 12
  },
  pageContainer: {
    backgroundColor: COLOR.commonColor.white,
    margin: 32,
    padding: 32,
    overflow: 'scroll'
  }
}))

const AdminMenu = observer(function AdminMenu() {

  const [debug, setDebug] = useState([]);
  useEffect(() => {
    const debug = JSON.parse(localStorage.getItem('DEBUG'));
    setDebug(debug);

  }, []);


  const UI_ADMIN_MENU_CONFIG = [
    {
      group: 'support',
      title: 'txtSupport',
      displayOrder: 0,
      oneLineItems: 4,
      menu: [
        [{
          title: 'txtAccountDeletion',
          desc: 'txtAccountDeletionDesc',
          route: '',
          disabled: !debug,
          displayOrder: 0
        },
        {
          title: 'txtCheckDebugger',
          desc: 'txtCheckDebuggerDesc',
          route: '',
          disabled: !debug,
          displayOrder: 1
        },
        {
          title: 'txtFlowDebugger',
          desc: 'txtFlowDebuggerDesc',
          route: '',
          disabled: !debug,
          displayOrder: 2
        },
        {
          title: 'txtUserDebugger',
          desc: 'txtUserDebuggerDesc',
          route: '',
          disabled: !debug,
          displayOrder: 3
        }]
      ]
    },
    {
      group: 'content',
      title: 'txtContent',
      displayOrder: 2,
      menu: [
        [{
          title: 'txtMarkerPlace',
          desc: 'txtMarkerPlaceDesc',
          route: '/administration/marketplace',
          disabled: !debug,
          displayOrder: 0
        },
        {
          title: 'txtNotifications',
          desc: 'txtNotificationsDesc',
          route: '',
          disabled: !debug,
          displayOrder: 1
        }]
      ]
    },
    {
      group: 'billing',
      title: 'txtBilling',
      displayOrder: 2,
      menu: [
        [{
          title: 'txtPlanConfig',
          desc: 'txtPlanConfigDesc',
          route: '/administration/billingconsole',
          disabled: !debug,
          displayOrder: 0
        },
        {
          title: 'txtServicesUsage',
          desc: 'txtServicesUsageDesc',
          route: '',
          disabled: !debug,
          displayOrder: 1
        }]
      ]
    },
    {
      group: 'configuration',
      title: 'txtConfiguration',
      displayOrder: 3,
      menu: [
        [{
          title: 'txtAttributesGroupConfig',
          desc: 'txtAttributesGroupConfigDesc',
          route: '/administration/identitygroup',
          disabled: !debug,
          displayOrder: 0
        },
        {
          title: 'txtAttributesConfig',
          desc: 'txtAttributesConfigDesc',
          route: '/administration/identityfield',
          disabled: !debug,
          displayOrder: 1
        },
        {
          title: 'txtCertificatesOrderConfig',
          desc: 'txtCertificatesOrderConfigDesc',
          route: '',
          disabled: !debug,
          displayOrder: 2
        },
        {
          title: 'txtChecksConfig',
          desc: 'txtChecksConfigDesc',
          route: '/administration/checkkyc',
          disabled: !debug,
          displayOrder: 3
        }],
        [{
          title: 'txtFlowsConfig',
          desc: 'txtFlowsConfigDesc',
          route: '/administration/flowconfig',
          disabled: !debug,
          displayOrder: 4
        },
        {
          title: 'txtRuntimeConfig',
          desc: 'txtRuntimeConfigDesc',
          route: '/administration/runningflow',
          disabled: !debug,
          displayOrder: 5
        }]
      ]
    },
    {
      group: 'tools',
      title: 'txtTools',
      displayOrder: 4,
      menu: [
        [{
          title: 'txtIdEngine',
          desc: 'txtIdEngineDesc',
          route: '/administration/smartengineui',
          disabled: !debug,
          displayOrder: 0
        }],
        [{
          title: 'txtListingRunningCheck',
          desc: 'txtListingRunningCheckDesc',
          route: '/administration/listingrunningcheck',
          disabled: !debug,
          displayOrder: 1
        }]

      ]
    },
  ]

  const {
    LocalizationStore: { translate },
    RouteStore
  } = useRootStore()
  const classes = useStyles()

  const onHandleClick = (path: string) => {
    RouteStore.push(path)
  }

  const theme = useTheme();
  const matcheLg = useMediaQuery(theme.breakpoints.up('lg'))
  const matcheXl = useMediaQuery(theme.breakpoints.up('xl'))

  return (
    <Box className={classes.pageContainer}
    >
      {
        UI_ADMIN_MENU_CONFIG.map(group => {
          return (
            <>
              <Typography variant='h4' className={classes.groupTitle}>{translate(group.title)}</Typography>
              {
                group.menu.map(list => {
                  if (matcheLg || matcheXl) {
                    return (
                      <div className={classes.menuContainer}>
                        {
                          list.map(item => {
                            return (
                              <MenuAdmin disabled={item.disabled} desc={item.desc} title={item.title} onClick={() => onHandleClick(item.route || '/')} />
                            )
                          })
                        }
                      </div>
                    )
                  } else {
                    return (
                      <Stack
                        direction={{ xs: 'column', sm: 'column', md: 'column', lg: 'row', xl: 'row' }}
                        spacing={{ xs: 1, sm: 1, md: 1 }}
                      >
                        {
                          list.map(item => {
                            return (
                              <MenuAdmin disabled={item.disabled} desc={item.desc} title={item.title} onClick={() => onHandleClick(item.route || '/')} />
                            )
                          })
                        }
                      </Stack>
                    )
                  }

                })
              }
            </>
          )
        })
      }
    </Box>
  )
})

export default AdminMenu
