import React from 'react'
import { observer } from 'mobx-react-lite'
import { DownArrowIcon } from '@components/Icon/CommonIcons'
import IconButton from '@mui/material/IconButton';
import makeStyles from '@mui/styles/makeStyles';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import { Typography } from "@blockpass-org/ui";
import { COLOR } from '@theme/colors'
import useRootStore from '@hooks/useRootStore'
import { styled } from '@mui/material/styles';
import { MenuProps } from '@mui/material/Menu';
import { get } from 'lodash'
const MENU_LIST_ITEM = [
  {
    index: 0,
    name: 'kycServices',
    text: 'txtKycServices',
    activeIndex: 0
  },
  {
    index: 1,
    name: 'kybServices',
    text: 'txtKybServices',
    activeIndex: 6
  },
  {
    index: 2,
    name: 'activityLogs',
    text: 'txtActivityLogs',
    activeIndex: 1
  },
  {
    index: 3,
    name: 'systemLogs',
    text: 'txtActivityLogs',
    activeIndex: 2
  },
  {
    index: 4,
    name: 'adminTool',
    text: 'txtAdministration',
    activeIndex: 3
  }
]
const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    borderRadius: 4,
    backgroundColor: COLOR.commonColor.primary,
    minWidth: '79px !important',
    height: '24px !important',
    padding: 8,
    paddingBottom: 6,
    paddingTop: 6,
    paddingRight: '8px !important',
    paddingLeft: '8px !important',
    margin: 'auto !important',
    '&:hover': {
      background: COLOR.primaryGreyScale[400],

    }
  },
  containerSelected: {
    display: 'flex',
    borderRadius: 4,
    backgroundColor: COLOR.primaryGreyScale[600],
    minWidth: '79px !important',
    height: '24px !important',
    padding: 0,
    paddingBottom: 6,
    paddingTop: 6,
    paddingRight: '8px !important',
    paddingLeft: '8px !important',
    margin: 'auto !important'
  },
  lang: {
    margin: 'auto !important',
    marginRight: '8px !important',
    marginLeft: '8px !important',
    padding: 0,
    paddingTop: '1px !important'
  },
  upIcon: {
    margin: 'auto',
    transform: 'rotate(180deg)',
    padding: 0
  },

  downIcon: {

    margin: 'auto',
    padding: 0
  },
  iconButton: {
    padding: '0px !important'
  },
  dummy: {
    width: 1,
    marginLeft: -8,
    marginBottom: -16,
    height: 38,
    position: 'absolute'
  },
  displayText: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%'
  },
  textPrimary: {
    color: `${COLOR.commonColor.primary} !important`
  },
  textSecondary: {
    color: `${COLOR.commonColor.textSecondary} !important`
  }
}))

export const SelectMenuHeader = (props: any) => {
  const classes = useStyles()
  const {
    LocalizationStore,
    DashboardStore,
  } = useRootStore()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const dummy = document.getElementById('dummyHeaderMenu')
    setAnchorEl(dummy);
  };
  const handleClose = (index: any) => {
    setAnchorEl(null);
    props.onClose && props.onClose(index)
  };

  let style = classes.container
  let styleSelected = classes.containerSelected
  const { activeHeader } = DashboardStore

  const kybServices = JSON.parse(localStorage.getItem('FEAT_KYB')) &&
    get((window as any).env, 'FEAT_ENABLE_KYB', '0') === '1'
  let listMenu = MENU_LIST_ITEM

  const getIcon = (open) => {
    if (open)
      return <DownArrowIcon className={classes.upIcon} />
    else return <DownArrowIcon className={classes.downIcon} />

  }


  const catchedMicro = props.catchedMicro

  const showAdmin = props.showAdmin
  const showLogs = props.showLogs
  if (!showAdmin) {
    listMenu = listMenu.filter((item) => {
      return item.name !== 'adminTool'
    })
  }
  if (!showLogs) {
    listMenu = listMenu.filter((item) => {

      return item.name !== 'activityLogs'
    })
  } else {
    listMenu = listMenu.filter((item) => {
      return item.name !== 'systemLogs'
    })
  }

  if (!kybServices) {
    listMenu = listMenu.filter((item) => {
      return item.name !== 'kybServices'
    })
  }

  let activeTitle = ''
  listMenu.forEach((item) => {
    if (item.activeIndex === activeHeader) {
      activeTitle = item.text
    }
  })

  const StyledMenu = styled((props: MenuProps) => (
    <Menu
      elevation={0}
      {...props}
    />
  ))(({ theme }) => ({
    '& .MuiPaper-root': {
      minWidth: 192,
      marginTop: -6,
      boxShadow: '0px 4px 8px 3px rgba(0, 0, 0, 0.15), 0px 1px 3px rgba(0, 0, 0, 0.3);',
      color:
        theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],

      '& .MuiMenu-list': {
        padding: '8px !important',
        borderRadius: 4,
      },
      '& .MuiMenuItem-root': {
        padding: "10px 16px",
        height: 40,
        borderRadius: 4,
        '&:hover': {
          backgroundColor: COLOR.commonColor.mainBackground,
        },
        '& .MuiSvgIcon-root': {
          fontSize: 18,
          color: COLOR.commonColor.textPrimary,
          marginRight: theme.spacing(2),
        },
        '&:active': {
          backgroundColor: COLOR.commonColor.white,
          // color: COLOR.commonColor.primary
        },
      },
      '& .MuiListItemButton-root': {
        padding: "8px 8px 8px 8px"
      },
      '& .Mui-selected': {
        backgroundColor: `${COLOR.primaryGreyScale[50]} !important`,

        '&:hover': {
          backgroundColor: COLOR.commonColor.primary,
        }
      }
    },
  }));
  return (
    <Box className={open ? styleSelected : style}
    >
      <div style={{ display: 'flex', cursor: 'pointer' }} onClick={handleClick}>
        <div className={classes.dummy} id='dummyHeaderMenu' ></div>

        <Typography variant="body2" style={{ color: COLOR.commonColor.white }} className={classes.lang}>
          {LocalizationStore.translate(activeTitle)}
        </Typography>
        <IconButton color="primary" component="span" className={classes.iconButton}>
          {
            getIcon(open)
          }

        </IconButton>
      </div>
      <StyledMenu
        id="language-list"
        anchorEl={anchorEl}
        open={open}
        onClose={() => handleClose(activeHeader)}
        MenuListProps={{
          'aria-labelledby': 'language-list',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        disableScrollLock={true}
      >
        {

          listMenu.map((item) => {

            const ElmText = (<Box className={classes.displayText}>
              <Typography variant='body2' >{LocalizationStore.translate(item.text)}</Typography>

            </Box>)
            return (<MenuItem key={item.activeIndex}
              selected={activeHeader === item.activeIndex}
              disabled={!catchedMicro}
              onClick={() => handleClose(item.activeIndex)}> {ElmText}</MenuItem>)
          })
        }
      </StyledMenu>
    </Box >


  )
}

export default observer(SelectMenuHeader)

