import { useState, useEffect } from 'react'
import { useObserver } from 'mobx-react'
import makeStyles from '@mui/styles/makeStyles';
import { Button, Typography } from "@blockpass-org/ui";
import { COLOR } from '@theme/colors'
import Box from '@mui/material/Box';
import get from 'lodash/get'
import useRootStore from '@hooks/useRootStore'
import Anime from 'react-anime';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import withStyles from '@mui/styles/withStyles'
import loadable from '@loadable/component'
const DashboardDialog = loadable(() => import('@/components/DashboardDialog'))
const CreateServiceV3 = loadable(() => import('@components/CreateServiceV3'),
  {
    fallback: <Box style={{ width: 600, height: 534, position: 'relative' }}>
      <CircularProgress size={48} style={{
        color: COLOR.commonColor.primary,
        margin: 0,
        position: 'absolute',
        top: '240px',
        left: '275px',
      }} />
    </Box>
  })
const CreateServiceV2 = loadable(() => import('@components/CreateServiceV2'),
  {
    fallback: <Box style={{ width: 600, height: 534, position: 'relative' }}>
      <CircularProgress size={48} style={{
        color: COLOR.commonColor.primary,
        margin: 0,
        position: 'absolute',
        top: '240px',
        left: '275px',
      }} />
    </Box>
  })
const useStyles = makeStyles((theme) => ({
  container: {
    padding: 0,
    width: '412px !important',
  },
  button: {
    width: '100%',
    height: 48,
  },
}))

export const Welcome = (props: any) => {
  const [open, setOpen] = useState(false);

  const [kybServices, setKybServices] = useState(false);
  useEffect(() => {
    const kybServices = JSON.parse(localStorage.getItem('FEAT_KYB')) &&
      get((window as any).env, 'FEAT_ENABLE_KYB', '0') === '1'
    if (kybServices)
      setKybServices(kybServices);

  }, []);

  const classes = useStyles()
  const {
    LocalizationStore: { translate },
    AuthStore,
    RouteStore,
    DashboardStore
  } = useRootStore()

  // const theme = useTheme();
  // const matcheLg = useMediaQuery(theme.breakpoints.up('lg'))
  // const matcheXl = useMediaQuery(theme.breakpoints.up('xl'))

  const onClickCreateService = () => {
    DashboardStore.resetCreateServiceData()
    DashboardStore.processDone = false
    DashboardStore.ignoreGenerateClientID = false
    setOpen(true)
  }

  const onClickGoToServiceList = async () => {
    // to service list
    RouteStore.push('/services')
    //mark finish tutorial
    // if (AuthStore.getFinishTutorial() === false) {
    //   await DashboardStore.setMarkTutorialComplete()
    // }
    AuthStore.setFinishTutorial()

  }

  const onClose = async () => {
    if (!DashboardStore.resquestPending) {
      // to service list
      setOpen(false)
    }
  }

  const onHandleClose = async (needUpdateProfile) => {
    // update services role
    if (needUpdateProfile)
      await AuthStore.getMerchantProfile()

    if (!DashboardStore.resquestPending) {
      setOpen(false)
    }

  }
  let hasService = false
  // never show LATER button
  // if (Object.keys(AuthStore.getServiceRole()).length > 0) {
  //   hasService = true
  // }

  return useObserver(() => {

    const LimitedBackdrop = withStyles({
      root: {
        position: "absolute",
        zIndex: 2,
        background: 'rgba(255,255,255,0.6)'
      }
    })(Backdrop);
    return (
      <Box className={classes.container}>
        <DashboardDialog title={translate('txtCreateServiceDashboard')} open={open}
          withoutTitle={DashboardStore.processDone}
          onClose={onClose}
          maxWidth={'xl'}
          onBackdropClick={onClose}
          disableEscapeKeyDown={false}
          noDivider={false}
        >
          <LimitedBackdrop
            open={DashboardStore.resquestPending}
          // onClick={handleClose}
          >
            <CircularProgress size={48} style={{ color: COLOR.commonColor.primary }} />
          </LimitedBackdrop>
          {
            kybServices ? <CreateServiceV3 handleClose={onHandleClose} fromWelcome /> : <CreateServiceV2 handleClose={onHandleClose} fromWelcome />
          }
        </DashboardDialog>
        <Anime opacity={[0, 1]} translateY={'-1.5em'} delay={(e, i) => 3100 + (i * 500)} easing='linear' duration={300}>
          <Box mb={2}>
            <Typography variant="h5">
              {translate('txtWelcomeTitle')}
            </Typography>
          </Box>
          <Box mb={2}>
            <Typography variant="body1" color='textSecondary'>
              {translate('txtWelcomeBody')}
            </Typography>
          </Box>
          <Box mb={2}>
            <Button onClick={onClickCreateService} color="primary" variant="contained" className={classes.button}>
              {translate('txtCreateServiceDashboard')}
            </Button>
          </Box>
          {
            hasService ? (<Box mb={2}>
              <Button onClick={onClickGoToServiceList} color="primary" variant='text' className={classes.button}>
                {translate('txtGoToServiceList')}
              </Button>
            </Box>) : (<></>)
          }
        </Anime >

      </Box >
    )
  })
}

export default (Welcome)

