
import { useEffect, useState } from 'react'
import { ADMIN_BILLING_CONSOLE_KEY } from '@constants/constants'
import CircularProgress from '@mui/material/CircularProgress';
import { observer } from 'mobx-react-lite'
import Cookies from 'js-cookie'
import Box from '@mui/material/Box';
import useRootStore from '@hooks/useRootStore'
import { COLOR } from '@theme/colors'
import makeStyles from '@mui/styles/makeStyles';
import { useHistory } from 'react-router-dom'

import loadable from '@loadable/component'
const MicroFrontend = loadable(() => import('../../MicroFrontend'))


const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: COLOR.commonColor.white,
    margin: '32px 0px 32px 0px',
    padding: 32,
    minHeight: 'calc(100vh - 400px);'
  },
}))

const AdminBillingConsole = observer(function AdminBillingConsole() {
  const [verifying, setVerifying] = useState(false);


  useEffect(() => {
    DashboardStore.hideFooter = true
    const { microLoaded } = DashboardStore
    if (microLoaded) {
      return
    }
    else {
      fetchData();
    }

  });

  const classes = useStyles()
  const history = useHistory()
  const {
    LocalizationStore,
    DashboardStore,
    RouteStore,
    UINotificationStore,
    AuthStore
  } = useRootStore()
  const { containerStore } = DashboardStore
  const microObj = DashboardStore.getMicroConfigDetail(
    ADMIN_BILLING_CONSOLE_KEY
  )

  const fetchData = async () => {
    try {
      DashboardStore.microLoaded = true
      setVerifying(false)

    } catch (error) {
      // Cookies.remove(COOKIES_SSID, {
      //   domain: COOKIES_DOMAIN,
      // })
      Cookies.set('logged_in', 'no')
      AuthStore.redirectToOauth()
      setVerifying(false)
    }
  }

  const handleResetLoaded = async event => {
    DashboardStore.microLoaded = false
  }

  const handleShowSnackbar = async (options) => {
    UINotificationStore.showSnackbar(options)
  }
  const handleAuthorizeError = async (code) => {
    try {
      if (code === 401) { //expired
        //show popup -> onAction -> setAuthenticated = false
        AuthStore.setOpenSessionExpired(true)
      } else { //unauthorized
        RouteStore.push('/403')
      }
    } catch (error) {
      setVerifying(false)
    }
  }
  const token = AuthStore.accessToken || ''
  containerStore.baseUrl = (microObj && microObj.backendHost) || ''
  containerStore.accessToken = token
  containerStore.language = LocalizationStore.activeLanguage || 'en'
  containerStore.onAuthorizeError = handleAuthorizeError
  containerStore.onShowSnackbar = handleShowSnackbar
  containerStore.onResetLoaded = handleResetLoaded

  containerStore.acHost = (microObj && microObj.acHost) || ''
  // get detail id
  const { planId } = RouteStore.params
  containerStore.planId = planId || ''


  const host = (microObj && microObj?.host) || ''
  try {
    return (
      <Box className={classes.container}>
        {host === '' || verifying || !DashboardStore.microLoaded ? (
          <div >
            <div style={{ position: 'relative', width: '100%', height: "768px" }}>
              <Box style={{ position: 'absolute', top: '50%', left: "50%", marginTop: '-64px' }} >
                <CircularProgress />
              </Box>
            </div>
          </div>
        ) : (
          <MicroFrontend
            history={history}
            host={host}
            name={microObj.id}
            containerStore={containerStore}
          />
        )}
      </Box>
    )
  } catch (error) {
    console.error('Micro fronted error: ', error)
    //redirect to root page
    // document.location.href = "/";

    // reload page
    window.location.reload()
  }
})

export default AdminBillingConsole

