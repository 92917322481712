import { observable, action, computed, makeObservable } from 'mobx'
import get from 'lodash/get'
import set from 'lodash/set'
import LanguageDb from '../../lang'
import camelCase from 'lodash/camelCase'
import isEmpty from 'lodash/isEmpty'
import BaseStore from '../../stores/base/BaseStore'
import { localizations as ExtLanguageDb } from '../../extends'
import RootStore from '../RootStore'
import { tryToParseErrorMessage } from '../../utils'

class LocalizationStore extends BaseStore {
  LanguageDb: any
  _rootStore: RootStore
  activeLanguage: any
  languages: any
  constructor(rootStore: RootStore) {
    super()

    this._rootStore = rootStore
    this._init()

    makeObservable(this, {
      activeLanguage: observable,
      LanguageDb: observable,
      activeLanguageDb: computed,
      setActiveLanguage: action,
    })
  }

  _init() {
    this.activeLanguage = 'en'
    this.LanguageDb = LanguageDb
    this.languages = [
      {
        title: 'EN',
        code: 'en',
        language: this.translate('English'),
        auto: '',
      },
      {
        title: 'RU',
        code: 'ru',
        language: this.translate('Russian'),
        auto: this.translate('txtAutoTranslate'),
      },
      {
        title: 'JP',
        code: 'jp',
        language:  this.translate('Japanese'),
        auto: this.translate('txtAutoTranslate'),
      },
      {
        title: 'FR',
        code: 'fr',
        language: this.translate('French'),
        auto: this.translate('txtAutoTranslate'),
      },
      {
        title: 'ZH',
        code: 'cn',
        language: this.translate('Chinese'),
        auto: this.translate('txtAutoTranslate'),
      },
    ]
  }

  // ---------------------------------------------//
  //  Private
  // ---------------------------------------------//
  _templateReplace(text: any, templateOptions = []) {
    return templateOptions.reduce((temp, pattern, index) => {
      const reg = new RegExp('<' + index + '>', 'g')
      return temp.replace(reg, pattern)
    }, text)
  }

  // ---------------------------------------------//
  //  Public
  // ---------------------------------------------//
  translate = (
    text = '',
    options: {
      templateOptions?: string[]
      fallbackRawValue?: string
    } = { templateOptions: [], fallbackRawValue: '' }
  ) => {
    // TODO: define return type
    if (!text) return
    if (typeof text !== 'string') return tryToParseErrorMessage(text)
    const { activeLanguageDb } = this
    const txtCode = text.startsWith('txt')
      ? camelCase(text)
      : camelCase(`txt ${text}`)
    const { templateOptions = [] } = options
    const translatedText = get(
      activeLanguageDb,
      txtCode,
      options.fallbackRawValue || txtCode
    )
    if (!isEmpty(templateOptions))
      return this._templateReplace(translatedText, templateOptions)
    else return translatedText
  }

  hasKey = (text: string = '') => {
    if (typeof text !== 'string') return false
    const txtCode = text.startsWith('txt')
      ? camelCase(text)
      : camelCase(`txt ${text}`)
    const { activeLanguageDb } = this
    return !!activeLanguageDb[txtCode]
  }

  addKeyValue = (key: string, val: string, lan = 'en') => {
    if (!key || typeof key !== 'string') return
    const txtCode = key.startsWith('txt')
      ? camelCase(key)
      : camelCase(`txt ${key}`)
    // this.debugLog('addKeyValue', txtCode, val)
    set(this.LanguageDb[lan], txtCode, val)
  }

  setActiveLanguage = (lang: any) => {
    if (!lang) return
    this.activeLanguage = lang
    const { DashboardStore: { containerStore }} = this._rootStore
    containerStore.emitUpdateLanguage(lang || 'en')
  }

  get activeLanguageDb() {
    const { activeLanguage, LanguageDb } = this
    return { ...LanguageDb[activeLanguage], ...ExtLanguageDb[activeLanguage] }
  }

  

  listLanguages() {
    return this.languages
  }

  getTitleLanguage = () => {
    let title = ''
    this.languages.forEach(lang => {
      if(this.activeLanguage === lang.code)
        title = lang.title
    });
    return title
  }

  // ---------------------------------------------//
  //  Life cycle
  // ---------------------------------------------//
  async start() {}
  async stop() {}
}
export default LocalizationStore
