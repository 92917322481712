import { useEffect } from 'react';
import useRootStore from '@hooks/useRootStore'
interface Props {
  name: string;
  host: string;
  history: any;
  containerStore: any
}

const MicroFrontendCache = ({ history, name, host, containerStore }: Props) => {
  const {
    DashboardStore,
    AuthStore
  } = useRootStore()
  useEffect(() => {
    try {
      const scriptId = `micro-frontend-script-${name}`;

      if (document.getElementById(scriptId)) {

        return;
      }

      if (name === '') {
        return;
      }

      const renderRemoteJS = async () => {
        if (AuthStore.isAuthenticated === false) {
          return
        }
        const manifest = await fetch(`${host}/asset-manifest.json`).then((res) =>
          res.json()
        );

        const script = document.createElement('script');
        script.id = scriptId;
        script.crossOrigin = '';
        script.src = `${host}${manifest.files['main.js']}`;
        script.onload = () => {
          // no render
          // renderMicroFrontend();
        };
        document.head.appendChild(script);
        DashboardStore.updateCache(name)

      };

      renderRemoteJS();
      return () => {

        (window as any)[`unmount${name}`] &&
          (window as any)[`unmount${name}`](`${name}-container`);
      };
    } catch (error) {
      console.error('Micro fronted error: ', error)
      //redirect to root page
      // document.location.href = "/";

      // reload page
      window.location.reload()
    }
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [history, name, host, containerStore]);
  // return <></>;
  return <main id={`${name}-container`} />;
};

export default MicroFrontendCache;