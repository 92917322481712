import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { Typography } from "@blockpass-org/ui";
import useRootStore from '@hooks/useRootStore'
import { getCurrentYear } from '../../utils'
import Stack from '@mui/material/Stack';
import { BlockpassFooterIcon } from '@components/Icon/CommonIcons'
import {
  FooterWrapper,
} from './DetailFooter.styled'
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';

import loadable from '@loadable/component'

const Subscribe = loadable(() => import('@components/Subcribe'))
const MoreInfo = loadable(() => import('@components/MoreInfo'))
const ConnectWithUs = loadable(() => import('@components/ConnectWithUs'))

interface Props {
  borderRadiusTop: any
}

const DetailFooter = observer(function DetailFooter(props: Props) {

  const rootStore = useRootStore();
  const {
    AuthStore,
    DashboardStore
  } = rootStore

  useEffect(() => {
    DashboardStore.hideFooter = true
  })

  const currentYear = getCurrentYear()
  const isSubscribe = AuthStore.getSubscribed()
  const { borderRadiusTop } = props
  return (
    <Box style={{ padding: '0px 32px 32px 32px' }}>
      <Box style={{
        backgroundColor: '#FFFFFF', padding: '32px 24px 0px 24px',
        borderRadius: 8,
        borderTopLeftRadius: borderRadiusTop, borderTopRightRadius: borderRadiusTop
      }} >
        <FooterWrapper  >
          <Stack
            direction={{ xs: 'column', sm: 'column', md: 'column', lg: 'row', xl: 'row' }}
            spacing={{ xs: 4, sm: 4, md: 4, lg: 1, xl: 1 }}
            justifyContent='space-between'
            alignItems={{ xs: 'flex-start', sm: 'flex-start', md: 'flex-start', lg: 'center', xl: 'center' }}
            width={'100%'}
          >
            <Box style={{ height: '100%' }}>
              <BlockpassFooterIcon />
            </Box>
            <MoreInfo />
            <Subscribe isSubscribe={isSubscribe} />
          </Stack>
        </FooterWrapper>
        <Divider style={{ width: '100%', marginTop: 24 }} />
        <Stack
          direction={{ xs: 'column', sm: 'column', md: 'column', lg: 'row', xl: 'row' }}
          spacing={{ xs: 4, sm: 4, md: 4, lg: 1, xl: 1 }}
          justifyContent='space-between'
          alignItems={{ xs: 'flex-start', sm: 'flex-start', md: 'flex-start', lg: 'center', xl: 'center' }}
          width={'100%'}
          style={{ padding: '24px 0px' }}
        >
          <ConnectWithUs />
          <Typography variant='body2' color='textSecondary' style={{ textAlign: 'center' }}>{'© Blockpass ' + currentYear}</Typography>
        </Stack>
      </Box>

    </Box>
  )

})

export default DetailFooter
