import { observable, makeObservable, action } from 'mobx'
import BaseStore from '@stores/base/BaseStore'
import RootStore from '../RootStore'
import { BannerProps } from '@/components/Banner/type'
import React from 'react'

interface ShowBannerProps {
  message: React.ReactNode
  variant?: BannerProps['variant']
  color?: BannerProps['color']
  action?: BannerProps['action']
  onClose?: BannerProps['onClose']
  multiline?: boolean
}

class UIBannerStore extends BaseStore {
  rootStore: RootStore
  show: boolean
  multiline?: boolean
  message: React.ReactNode
  action?: BannerProps['action']
  onClose?: BannerProps['onClose']
  variant: BannerProps['variant']
  color: BannerProps['color']

  constructor(rootStore: RootStore) {
    super()

    this.rootStore = rootStore
    this.show = false
    this.variant = 'standard'
    this.color = 'info'
    this.multiline = false
    this.action = undefined
    this.onClose = undefined

    makeObservable(this, {
      show: observable,
      variant: observable,
      color: observable,

      showBanner: action,
      closeBanner: action,
    })
  }

  showBanner({
    message,
    variant,
    color,
    onClose,
    action,
    multiline,
  }: ShowBannerProps) {
    this.message = message
    this.variant = variant || this.variant
    this.color = color || this.color
    this.onClose = onClose
    this.multiline = multiline
    this.action = action
    this.show = true
  }

  closeBanner() {
    this.reset()
    this.show = false
  }

  reset() {
    this.message = ''
    this.variant = 'standard'
    this.color = 'info'
    this.action = undefined
  }

  // ---------------------------------------------//
  //  Life cycle
  // ---------------------------------------------//
  async start() {}
  async stop() {}
}

export default UIBannerStore
