
import TagManager from 'react-gtm-module'
import upperFirst from 'lodash/upperFirst'
import toLower from 'lodash/toLower'
import isString from 'lodash/isString'
import trimEnd from 'lodash/trimEnd'
import trimStart from 'lodash/trimStart'
import debounce from 'lodash/debounce'
import UCookies from 'universal-cookie'
import network from '../constants/network'
import { duration } from '@mui/material/styles'
;(window as any).UCookies = UCookies

export const safeInvoke = (func: any, ...params) => func && func(...params)

export const isDev = () => {
  return process.env.NODE_ENV !== 'production'
}

export const waitMs = (ms: any) =>
  new Promise((resolve) => {
    setTimeout(() => {
      resolve(true)
    }, ms)
  })

export const getCookie = (cname: any) => {
  var name = cname + '='
  var decodedCookie = decodeURIComponent(document.cookie)
  var ca = decodedCookie.split(';')
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ''
}

export const deleteCookie = (cname: any) => {
  // document.cookie = cname + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;'
  const cookies = new UCookies()
  cookies.remove(cname, { path: '/', domain: '.blockpass.org' })
  if (process.env.NODE_ENV !== 'production')
    cookies.remove(cname, { path: '/', domain: 'localhost' })
}

export const setCookie = (cname: any, cvalue: any, exday: any) => {
  var d = exday
  var expires = 'expires=' + d.toUTCString()
  document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/'
}

export const isIOS = () => {
  return (
    [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod',
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  )
}

export const isMobile = function () {
  var check = false
  ;(function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\\-(n|u)|c55\/|capi|ccwa|cdm\\-|cell|chtm|cldc|cmd\\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\\-s|devi|dica|dmob|do(c|p)o|ds(12|\\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\\-|_)|g1 u|g560|gene|gf\\-5|g\\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\\-(m|p|t)|hei\\-|hi(pt|ta)|hp( i|ip)|hs\\-c|ht(c(\\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\\-(20|go|ma)|i230|iac( |\\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\\-[a-w])|libw|lynx|m1\\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\\-2|po(ck|rt|se)|prox|psio|pt\\-g|qa\\-a|qc(07|12|21|32|60|\\-[2-7]|i\\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\\-|oo|p\\-)|sdk\/|se(c(\\-|0|1)|47|mc|nd|ri)|sgh\\-|shar|sie(\\-|m)|sk\\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\\-|v\\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\\-|tdg\\-|tel(i|m)|tim\\-|t\\-mo|to(pl|sh)|ts(70|m\\-|m3|m5)|tx\\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\\-|your|zeto|zte\\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true
  })(navigator.userAgent || navigator.vendor || (window as any).opera)
  return check
}

export const lazyLoadModuleDataHoc = (promiseFunc: any) => async () => {
  return (await promiseFunc()).default
}

// export function isBase64(v: any, opts?: any) {
//   if (v instanceof Boolean || typeof v === 'boolean') {
//     return false
//   }
//   if (!(opts instanceof Object)) {
//     opts = {}
//   }
//   if (opts.allowEmpty === false && v === '') {
//     return false
//   }
//   var regex =
//     '(?:[A-Za-z0-9+\\/]{4})*(?:[A-Za-z0-9+\\/]{2}==|[A-Za-z0-9+/]{3}=)?'
//   var mimeRegex = '(data:\\w+\\/[a-zA-Z\\+\\-\\.]+;base64,)'
//   if (opts.mimeRequired === true) {
//     regex = mimeRegex + regex
//   } else if (opts.allowMime === true) {
//     regex = mimeRegex + '?' + regex
//   }
//   if (opts.paddingRequired === false) {
//     regex =
//       '(?:[A-Za-z0-9+\\/]{4})*(?:[A-Za-z0-9+\\/]{2}(==)?|[A-Za-z0-9+\\/]{3}=?)?'
//   }
//   return new RegExp('^' + regex + '$', 'gi').test(v)
// }

// export function base64ToBuffer(b64: any) {
//   if (!isBase64(b64)) return null
//   return Buffer.from(b64, 'base64')
// }

export const convertTitleCase = (text = '') =>
  text
    .split(' ')
    .map((word) => upperFirst(toLower(word)))
    .join(' ')

export const checkCameraDevice = async () => {
  try {
    const devices = await navigator.mediaDevices.enumerateDevices()
    return devices || []
  } catch (err) {
    console.error('checkCameraDevice', err)
    return []
  }
}

export const clone = (obj: any) => {
  return JSON.parse(JSON.stringify(obj))
}

export const getOnlineStatus = () => {
  return typeof navigator !== 'undefined' &&
    typeof navigator.onLine === 'boolean'
    ? navigator.onLine
      ? network.ONLINE
      : network.NOCONNECTIVITY
    : network.UNKNOWN
}

export const delay = (delayInms: any) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(true)
    }, delayInms)
  })
}

export const scrollToField = async (arrayRef: any, field: any) => {
  if (!arrayRef || Object.keys(arrayRef).length === 0 || !arrayRef[field]) {
    return
  }
  const ele = arrayRef[field]
  // add delay to make sure the browser scroll to position correctly
  await delay(duration.enteringScreen)
  ele.scrollIntoView({ block: 'center', behavior: 'smooth' })
}

export const forceRepaint = (element: any) => {
  if (!element) return // element.style.display = 'none'

  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
  element.offsetHeight
  element.style.display = 'flex'
}

export const getRect = (element: any) => {
  if (!element) {
    return {
      bottom: 0,
      height: 0,
      left: 0,
      right: 0,
      top: 0,
      width: 0,
    }
  }
  return element.getBoundingClientRect()
}

export const sortByAlphabet = (a: any, b: any, direction = 'ASC') => {
  const lowerA = a.toLowerCase()
  const lowerB = b.toLowerCase()
  if (lowerA < lowerB) {
    return direction === 'ASC' ? -1 : 1
  }
  if (lowerA > lowerB) {
    return direction === 'ASC' ? 1 : -1
  }
  return 0
}

export const clamp = (v: number, min: number, max: number) =>
  Math.min(Math.max(v, min), max)

export const getCurrentYear = (): string => {
  const date = new Date()
  return date.getFullYear().toString()
}

export const stringToBoolean = (string: string) => {
  if (!string) return false

  switch (string.toLowerCase().trim()) {
    case 'true':
    case 'yes':
    case '1':
      return true
    case 'false':
    case 'no':
    case '0':
    case null:
      return false
    default:
      return Boolean(string)
  }
}

export const toTitleCase = (value: string) => {
  return value.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  });
}

export const nopFunc = (_value?: any): void => {}

export const getMiddleEllipsisValue = (
  value: string | undefined,
  ellipsisOptions = { start: 8, end: 8 },
  maxLength = 25
): string => {
  if (!isString(value)) return ''
  if (value.length < maxLength) return value
  const { start, end } = ellipsisOptions
  const valueLength = value.length

  return `${value.slice(0, start)}...${value.slice(
    valueLength - end,
    valueLength
  )}`
}

export const trimEndSpace = (v: any) => (isString(v) ? trimEnd(v) : v)

export const trimStartSpace = (v: any) => (isString(v) ? trimStart(v) : v)

export const asyncDebounce = <F extends (...args: any[]) => Promise<any>>(
  func: F,
  wait?: number
) => {
  const debounced = debounce((resolve, reject, args) => {
    func(...args)
      .then(resolve)
      .catch(reject)
  }, wait)

  return (...args: Parameters<F>): ReturnType<F> =>
    new Promise((resolve, reject) => {
      debounced(resolve, reject, args)
    }) as ReturnType<F>
}

export const tryToParseErrorMessage = (message: object | number) => {
  if (typeof message === 'number') return message.toString()
  if (typeof message === 'object') {
    return Object.entries(message)
      .filter(([key, value], index) => index < 2)
      .map(([key, value]) => `${key}-${value}`)
      .join(', ')
  }
}

export const validateEmail = (email: any) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  }

export const validateUrlHttps = (url: any) => {
    const re = /[(http(s)?):\\/\\/(www\\.)?a-zA-Z0-9@:%._\\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)/
    return re.test(String(url).toLowerCase())
  }
  

  export const pushDataLayer = (dataLayer) =>{
    const keyList = ['event', 'env', 'screen_name', 'Status', 'status', 'component_id', 'item_label', 'item_value', 'chip_label',
     'button_label', 'textField_label', 'value', 'client_id', 'tab_label', 'text_in_link', 'subscription', 'group', 'step', 
     'session_in_step','section_in_step', 'submit_results','date_vale', 'age_restriction_value', 'error_message', 'submit_result']
    let dataObj = {}
    for (const key of keyList) {
      dataObj[key] = undefined;
    }
  
    for (const p in dataLayer) {
      dataObj[p] = dataLayer[p]
    }
  
    TagManager.dataLayer({
      dataLayer: dataObj,
    });
  }