import { observer } from 'mobx-react-lite'
import { useCallback, useEffect, useMemo } from 'react'
import useRootStore from '@/hooks/useRootStore'
import { useTheme } from '@mui/styles'
import { Button, Typography } from '@blockpass-org/ui'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import { Box } from '@mui/material'

export const TwoFAWarning = observer(function TwoFAWarning() {
  const rootStore = useRootStore()
  const theme = useTheme()
  const translate = rootStore.LocalizationStore.translate

  const onClose = useCallback(() => {
    rootStore.UIBannerStore.closeBanner()
  }, [rootStore.UIBannerStore])

  const onEnableTwoFAClick = useCallback(() => {
    rootStore.AuthStore.openEnableTwoFa()
  }, [rootStore.AuthStore])

  const message = useMemo(() => {
    return (
      <>
        <Typography variant="subtitle1">
          {translate('txtTwoFaBannerTitle')}
        </Typography>
        <Typography variant="body2" sx={{ mt: 0.5 }}>
          {translate('txtTwoFaBannerDesc')}
        </Typography>
      </>
    )
  }, [translate])

  const action = useMemo(() => {
    return (
      <Box sx={{ alignSelf: 'center' }}>
        <Button
          variant="outlined"
          size="small"
          onClick={onEnableTwoFAClick}
          sx={{
            mr: 1,
            backgroundColor: 'white',
            borderColor: 'white',
            '&:hover': {
              backgroundColor: 'white',
              borderColor: 'white',
            },
            boxShadow: theme.shadows[2],
          }}
        >
          {translate('txtTwoFaButtonEnableNow')}
        </Button>
        <IconButton size="small" onClick={onClose} sx={{ p: '6px' }}>
          <CloseIcon fontSize="small" />
        </IconButton>
      </Box>
    )
  }, [onClose, onEnableTwoFAClick, theme.shadows, translate])

  useEffect(() => {
    if (rootStore.AuthStore.userProfile.haveTwoFA) {
      return
    }

    rootStore.UIBannerStore.showBanner({
      message,
      action,
      color: 'warning',
    })

    return () => {
      rootStore.UIBannerStore.closeBanner()
    }
  }, [action, message, onClose, rootStore.AuthStore.userProfile.haveTwoFA, rootStore.UIBannerStore])

  return null
})
