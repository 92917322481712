import React from 'react'
import { observer } from 'mobx-react-lite'
import Box from '@mui/material/Box';
import { COLOR } from '@theme/colors'
import makeStyles from '@mui/styles/makeStyles';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Welcome as WelcomeText } from '@/components/Welcome/Welcome';
import Anime from 'react-anime';

import loadable from '@loadable/component'
const AnimatedWelcome = loadable(() => import('@components/AnimatedWelcome'))
const SelectLanguage = loadable(() => import('@components/SelectLanguage'))


const useStyles = makeStyles((theme) => ({
  animated: {
    width: 550,
    height: 550,
    // marginBottom: '64px !important'
  },
  text: {
    width: 415,
    marginTop: 'auto !important',
    marginBottom: 'auto !important'
  },
  textSmall: {
    width: 415,
    marginTop: '64px !important',
    marginBottom: 'auto !important',
    marginLeft: 'auto !important',
    marginRight: 'auto !important',
  },
  language: {
    display: 'grid',
    justifyContent: 'flex-end'
  },
  pageContainer: {
    backgroundColor: COLOR.commonColor.white,
    margin: 'auto',
    padding: 32,
    display: 'flex',
    height: '100%'
  },
  center: {
    margin: 'auto',
  },
  container: {
    margin: 'auto'
  }
}))

const Welcome = observer(function Welcome() {
  const classes = useStyles()

  const theme = useTheme();
  const matcheLg = useMediaQuery(theme.breakpoints.up('lg'))
  const matcheXl = useMediaQuery(theme.breakpoints.up('xl'))

  return (
    <Box className={classes.pageContainer} >

      <Box className={classes.container}>
        <Stack
          direction={{ xs: 'column', sm: 'column', md: 'column', lg: 'row', xl: 'row' }}
          spacing={{ xs: 1, sm: 1, md: 1 }}
          className={classes.center}
        >
          <Box className={classes.animated}><AnimatedWelcome /></Box>
          <Box className={matcheLg || matcheXl ? classes.text : classes.textSmall}> <WelcomeText /></Box>
        </Stack >
      </Box>
      <Anime opacity={[0, 1]} translateY={'-1.5em'} delay={(e, i) => 2800} easing='linear' duration={300}>
        <Box className={classes.language}>
          <SelectLanguage welcome ></SelectLanguage>
        </Box>
      </Anime>
    </Box>
  )
})

export default Welcome
