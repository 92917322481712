import React, { PropsWithChildren } from 'react'
import Fade from '@mui/material/Fade'
import Paper from '@mui/material/Paper'
import {
  ErrorIcon,
  SuccessIcon,
  WarningIcon,
} from '@/components/Icon/SnackbarIcons'
import { styled } from '@mui/material/styles'
import { capitalize } from '@mui/material/utils'
import { darken, lighten, useThemeProps } from '@mui/system'
import clsx from 'clsx'
import { bannerClasses, getBannerUtilityClass } from './bannerClasses'
import { unstable_composeClasses as composeClasses } from '@mui/base'
import { Box, IconButton, Theme } from '@mui/material'
import { BannerProps } from './type'
import CloseIcon from '@mui/icons-material/Close'

const useUtilityClasses = (ownerState: BannerProps) => {
  const { variant, color, classes } = ownerState

  const slots = {
    root: ['root', `${variant}${capitalize(color as string)}`, `${variant}`],
    icon: ['icon'],
    message: ['message'],
    action: ['action'],
  }

  return composeClasses(slots, getBannerUtilityClass, classes)
}

type BannerStylesOverridesProp = {
  theme?: Theme
  ownerState: BannerProps
}

const BannerRoot = styled(Paper, {
  name: 'MuiBanner',
  slot: 'Root',
  overridesResolver: (props, styles) => {
    const { ownerState } = props

    return [
      styles.root,
      styles[ownerState.variant],
      styles[`${ownerState.variant}${capitalize(ownerState.color)}`],
    ]
  },
})<BannerStylesOverridesProp>(({ theme, ownerState }) => {
  const getColor = theme.palette.mode === 'light' ? darken : lighten
  const getBackgroundColor = theme.palette.mode === 'light' ? lighten : darken
  const color = ownerState.color
  const multiline = ownerState.multiline
  const contained = ownerState.contained

  return {
    ...theme.typography.body2,
    backgroundColor: 'transparent',
    position: contained ? 'relative' : 'fixed',
    zIndex: 10,
    top: 0,
    left: 0,
    width: '100%',
    borderRadius: 0,
    display: 'flex',
    gap: 8,
    padding: 16,
    ...(multiline && {
      flexDirection: 'row',
      alignItems: 'flex-start',
      padding: '16px 16px 16px 16px',
    }),
    ...(color &&
      ownerState.variant === 'filled' && {
        color: '#fff',
        backgroundColor:
          theme.palette[color === 'info' ? 'primary' : color].main,
        [`& .${bannerClasses.icon}`]: {
          color:
            theme.palette[color === 'info' ? 'primary' : color].contrastText ||
            '#fff',
        },
      }),
    ...(color &&
      ownerState.variant === 'standard' && {
        color: theme.palette.text.primary,
        backgroundColor: getBackgroundColor(theme.palette[color].light, 0.85),
        [`& .${bannerClasses.icon}`]: {
          color: theme.palette[color].main,
        },
      }),
    ...(color &&
      ownerState.variant === 'outlined' && {
        color: getColor(theme.palette[color].light, 0.6),
        border: `1px solid ${theme.palette[color].light}`,
        [`& .${bannerClasses.icon}`]: {
          color: theme.palette[color].contrastText,
        },
      }),
  }
})

const BannerIcon = styled('div', {
  name: 'MuiBanner',
  slot: 'Icon',
  overridesResolver: (props, styles) => styles.icon,
})<BannerStylesOverridesProp>({
  marginRight: 12,
  padding: '0',
  display: 'flex',
  fontSize: 22,
  opacity: 0.9,
})

const BannerMessage = styled('div', {
  name: 'MuiBanner',
  slot: 'Message',
  overridesResolver: (props, styles) => styles.message,
})<BannerStylesOverridesProp>({
  padding: '0',
  minWidth: 0,
  overflow: 'auto',
})

const BannerAction = styled('div', {
  name: 'MuiBanner',
  slot: 'Action',
  overridesResolver: (props, styles) => styles.action,
})<BannerStylesOverridesProp>(({ theme, ownerState }) => {
  return {
    display: 'flex',
    alignItems: 'flex-start',
    padding: '4px 0 0 16px',
    marginLeft: 'auto',
    marginRight: -8,
    ...(ownerState.multiline && {
      marginTop: '-8px',
      padding: 0,
    }),
    ...(ownerState.variant === 'standard' && {
      color: theme.palette.text.secondary,
    }),
  }
})

const defaultIconMapping = {
  success: <SuccessIcon fontSize="inherit" />,
  warning: <WarningIcon fontSize="inherit" />,
  error: <ErrorIcon fontSize="inherit" />,
  info: <ErrorIcon fontSize="inherit" />,
}

interface Props extends Omit<BannerProps, 'ref'> {
  contained?: boolean
  multiline?: boolean
}

export const Banner = React.forwardRef<HTMLDivElement, Props>(
  (inProps: PropsWithChildren<Props>, ref) => {
    const props = useThemeProps({ props: inProps, name: 'MuiBanner' })
    const {
      action,
      children,
      color = 'info',
      variant = 'filled',
      role = 'banner',
      open = false,
      className,
      closeText = 'Close',
      icon,
      onClose,
      sx,
      ...other
    } = props
    const ownerState = {
      ...props,
      color,
      variant,
    }
    const classes = useUtilityClasses(ownerState)

    return (
      <Fade in={open}>
        <BannerRoot
          ref={ref}
          ownerState={ownerState}
          role={role}
          elevation={0}
          className={clsx(classes.root, className)}
          sx={sx}
          {...other}
        >
          <Box sx={{ flex: 1, display: 'flex' }}>
            {icon !== false ? (
              <BannerIcon ownerState={ownerState} className={classes.icon}>
                {icon || defaultIconMapping[color]}
              </BannerIcon>
            ) : null}
            <BannerMessage ownerState={ownerState} className={classes.message}>
              {children}
            </BannerMessage>
          </Box>
          {action != null ? (
            <BannerAction ownerState={ownerState} className={classes.action}>
              {action}
            </BannerAction>
          ) : null}
          {action == null && onClose ? (
            <BannerAction ownerState={ownerState} className={classes.action}>
              <IconButton
                aria-label={closeText}
                title={closeText}
                color="inherit"
                onClick={onClose}
                sx={{ p: ownerState.multiline ? '8px' : '6px' }}
              >
                <CloseIcon
                  fontSize={ownerState.multiline ? 'medium' : 'small'}
                />
              </IconButton>
            </BannerAction>
          ) : null}
        </BannerRoot>
      </Fade>
    )
  }
)

Banner.displayName = 'Banner'
