import { observable, makeObservable } from 'mobx'
import { matchPath } from 'react-router-dom'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import BaseStore from '../base/BaseStore'
import RootStore from '../RootStore'
import ReactGA from "react-ga4";

const LOCATION_TITLE_MAPPING = {
  '/companies': 'ACv2 | Company',
  // '/administration/systemlog': 'Activity Logs',
  '/logs': 'ACv2 | Activity Logs',
  '/administration': 'ACv2 | Admin Tools',
  '/403': 'ACv2 | 403',
  '/404': 'ACv2 | 404',
  '/services': 'ACv2 | Service List'

}
class RouteStore extends BaseStore {
  _rootStore: RootStore
  activeRoutes: any
  history: any
  lastAction: any
  location: any
  params: any
  prevLocation: any
  routeState: any
  constructor(rootStore: RootStore) {
    super()

    this._rootStore = rootStore
    this._init()
    
    makeObservable(this, {
      params: observable,
      routeState: observable,
      location: observable,
    })

   
  }

  _init() {
    this.routeState = new Map()
    this.activeRoutes = []
    this.params = {}
    this.location = ''
    this.prevLocation = ''
    this.history = null
    this.lastAction = ''
  }

  // ---------------------------------------------//
  //  Public
  // ---------------------------------------------//
  push = (location, state = {}) => {
    this.debugLog('push', location, state)
    this.prevLocation = this.location
    // kun todo - check route
    // if (!this.hasRoute(location)) return this.replace('/')

    // timing
    this._rootStore.DashboardStore.timeStart = Date.now()

    // UI flag
    if(location.includes('403') || location.includes('404')) {
      this._rootStore.DashboardStore.hideFooter = true
    } else {
      this._rootStore.DashboardStore.hideFooter = false
    }
    if (!isEmpty(state))
      this.addRouteState((state as any).key, (state as any).value)
    this.lastAction = 'push'
    this.history && this.history.push(location)
    if(!location.includes('update') && !location.includes('systemlog') ){
      let title = 'ACv2 | Service List'
      if(LOCATION_TITLE_MAPPING.hasOwnProperty(location)){
        title = LOCATION_TITLE_MAPPING[location]
      }
      ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: title });
    } 
    
  }

  replace = (location, state?: any) => {
    this.debugLog('replace', location, state)
    this.prevLocation = this.location
    if (!isEmpty(state)) this.addRouteState(state.key, state.value)
    this.lastAction = 'replace'
    this.history && this.history.replace(location)
  }

  go = (n) => {
    this.prevLocation = this.location
    this.history && this.history.go(n)
    this.lastAction = 'go'
  }

  goBack = () => {
    this.debugLog('goBack')
    if (this.history) {
      const length = this.history.length
      const backTo = get(this.history, ['entries', length - 2, 'pathname'])
      this.debugLog('goBackTo', backTo, length - 1)
      this.prevLocation = this.location
      this.history && this.history.goBack()
    }
    this.lastAction = 'pop'
  }

  goBackToDetail = () => {
    this.debugLog('goBackToDetail')
    const { prevLocation } = this
    if (prevLocation.pathname === '/detail') {
      // If the previous location's pathname is '/detail', we just need to go back.
      this.goBack()
    } else {
      /**
       * Else, we need to determine how many steps to go back until we reach
       * the detail page. For now, we cannot know how many steps is needed so
       * in our application, the steps is set to fixed value: 2.
       */
      this.go(-2)
    }
  }

  goForward = () => {
    this.prevLocation = this.location
    this.history && this.history.goForward()
  }

  hasRoute = (p) => {
    const ins = this.activeRoutes.find((itm) => {
      const isMatch = matchPath( itm, p );
      return isMatch;
    });
    return !!ins;
  };
  
  addRouteState = (key, value) => {
    this.routeState.set(key, value)
  }

  getRouteState = (key) => {
    const ins = this.routeState.get(key)
    if (!ins) return null
    return ins
  }

  removeRouteState = (key) => {
    this.routeState.delete(key)
  }

  clearRouteState = () => {
    this.routeState.clear()
  }

  checkRoute = (key) => {
    const isMatch = matchPath(this.location.pathname , key)
    return get(isMatch, 'isExact', false)
  }

  getActiveMenu = () => {
    const paths = [ '/services', '/administration/systemlog', '/logs', '/administration', '', '/companies', '/kyb/services']
    
    let activeIndex = -1
    paths.forEach((p,i) => {
       if(this.checkRoute(p))
       {
        activeIndex = i
       }
    });
    if(this.location.pathname === '/') { // root
      return 0
    }
    if(this.location.pathname.includes('/administration/systemlog'))
      return 1
    // 
    if(this.location.pathname.includes('/administration'))
      return 3
    
    if(this.location.pathname.includes('/services/update'))
      return 4
      
    if(this.location.pathname.includes('/companies'))
      return 5
    if(this.location.pathname.includes('/kyb/services'))
      return 6
    // 403 
    if(this.location.pathname.includes('/403'))
      return 100
    if(this.location.pathname.includes('/404'))
      return 100
    return activeIndex
  }
  // ---------------------------------------------//
  //  Public - WebLink
  // ---------------------------------------------//
  /**
   * Load Blockpass Verify by change window.location.href
   */


 

  getAppleStoreLink = () => {
    const wenv = (window as any).env || {}
    const env = wenv.BLOCKPASS_ENV || 'prod'
    if (env === 'staging') return 'https://testflight.apple.com/join/JavudHsa'
    return 'https://apps.apple.com/us/app/blockpass/id1592260586'
  }

  getGooglePlayStoreLink = () => {
    const wenv = (window as any).env || {}
    const env = wenv.BLOCKPASS_ENV || 'prod'
    if (env === 'staging')
      return 'https://play.google.com/apps/testing/com.blockpass_mobile.staging '
    return 'https://play.google.com/store/apps/details?id=org.blockpass.mobile'
  }

  openVerifyWith = () => {
    const wenv = (window as any).env || {}
    const { urlParams } = this._rootStore
    const env = wenv.BLOCKPASS_ENV || 'prod'
    const url = new URL('https://verify-with.blockpass.org')
    url.searchParams.append('env', env)
    url.searchParams.append('auto', '1')
    for (const key of Object.keys(urlParams)) {
      const val = urlParams[key]
      if (!val) continue
      url.searchParams.append(key, val)
    }
    window.open(url.toString(), '_blank')
  }

  openAuthSetting = () => {
    const wenv = (window as any).env || {}
    const env = wenv.BLOCKPASS_ENV || 'prod'
    let url = new URL('https://oauth.blockpass.org')
    switch (env) {
      case 'production':
      case 'prod': {
        url = new URL('https://oauth.blockpass.org')
        break
      }
      case 'stag':
      case 'staging': {
        url = new URL('http://sandbox-oauth.blockpass.org')
        break
      }
      case 'qa':
      default: {
        url = new URL('https://qa-oauth.blockpass.org')
        break
      }
    }
    url.searchParams.append('acctype', 'bp_wid')
    window.open(url.toString(), '_blank')
  }

  // ---------------------------------------------//
  //  Life cycle
  // ---------------------------------------------//
  async start() {
    super.start()
    this.clearRouteState()
  }

  async stop() {
    this.clearRouteState()
  }
}
export default RouteStore
