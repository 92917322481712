import BannerProvider from '@/providers/UIBannerProvider'
import Box from '@mui/material/Box'

export const ServiceListLayout = ({ children }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: '32px 32px 0px 32px',
      }}
    >
      <BannerProvider />
      {children}
    </Box>
  )
}
