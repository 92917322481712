import React from 'react'
import { useObserver } from 'mobx-react'
import useRootStore from '@hooks/useRootStore'
import { Typography, Button } from '@blockpass-org/ui'
import { makeStyles } from '@mui/styles'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'

import { COLOR } from "@workspace/shared/theme/colors";

const useStyles = makeStyles(() => ({

  flex: {
    display: 'flex',
    margin: '0px 16px 16px 16px !important'
  },
  dialogTitle: {
    padding: '24px 24px 16px 24px !important',
    backgroundColor: COLOR.commonColor.white
  },

  textFieldInput: {
    margin: '0px 16px 16px 16px !important'
  },
}))

export const SessionExpriedDialog = (props: any) => {
  const rootStore = useRootStore()
  const { LocalizationStore, AuthStore } = rootStore
  const classes = useStyles()


  const handleConfirm = async () => {
    AuthStore.setAuthenticated(false)
    AuthStore.setOpenSessionExpired(false)
    props.handleClose && props.handleClose()
  }

  return useObserver(() => {
    return (
      <Dialog
        // modal={true}
        fullWidth
        open={props.open}
        maxWidth={'md'}
        sx={{
          '& .MuiDialog-paper': {
            width: 444,
            borderRadius: '8px !important',
            boxShadow: '0px 6px 28px 5px rgba(0, 0, 0, 0.12), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 8px 9px -5px rgba(0, 0, 0, 0.2); !important'
          }
        }}

      >
        <DialogTitle className={classes.dialogTitle}>
          <Typography variant='subtitle1' color='textPrimary'>
            {LocalizationStore?.translate("txtUserSessionExpired")}
          </Typography>

        </DialogTitle>
        <div style={{ padding: '0px 8px 24px 8px' }}>
          <Typography variant="body2" color={'textSecondary'} className={classes.textFieldInput}>
            {LocalizationStore?.translate(
              'txtUserSessionExpiredDesc'
            )}
          </Typography>
          <div style={{ marginTop: 24, textAlign: 'right', paddingRight: 8 }}>

            <Button
              onClick={() => handleConfirm()}
              color="primary"
              variant="contained"
              style={{ marginRight: '8px' }}
            >
              {LocalizationStore?.translate('txtLogin').toUpperCase()}
            </Button>
          </div>
        </div>
      </Dialog>
    )
  })
}

export default SessionExpriedDialog
