
import { observer } from 'mobx-react-lite'
import makeStyles from '@mui/styles/makeStyles';
import { Typography } from "@blockpass-org/ui";
import Button, { ButtonProps } from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { COLOR } from '@theme/colors'
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box'
import useRootStore from '@hooks/useRootStore'
import { UnionIcon } from '../Icon/CommonIcons';
const useStyles = makeStyles((theme) => ({
  containerButton: {
    minWidth: '112px !important',
    height: '48px !important',
    display: 'block',
    margin: 'auto !important',
  },
  containerButtonUnderline: {
    minWidth: '112px !important',
    height: '48px !important',
    display: 'block',
    // borderBottom: `2px solid ${COLOR.commonColor.white}`,
    backgroundColor: 'rgba(255, 255, 255, 0.16)',
    margin: 'auto !important',
  },
  containerButtonMini: {
    minWidth: '87px !important',
    height: '44px !important',
    display: 'block',
    margin: 'auto !important',
  },
  containerButtonUnderlineMini: {
    minWidth: '87px !important',
    height: '44px !important',
    display: 'block',
    // borderBottom: `2px solid ${COLOR.commonColor.white}`,
    margin: 'auto !important',
  },
  boxCreate: {
    whiteSpace: 'nowrap',
    height: '80px !important',
    display: 'flex',
    margin: '0px 6px !important'
  },
  boxCreateMini: {
    whiteSpace: 'nowrap',
    height: '72px !important',
    display: 'flex',
    margin: '0px 4px !important'
  },
  boxHeader: {
    whiteSpace: 'nowrap',
    height: '80px !important',
    display: 'flex',
    margin: '0px 6px !important'
  },
  bxHeaderMini: {
    whiteSpace: 'nowrap',
    height: '72px !important',
    display: 'flex'
  },
  boxSubHeaderMini: {
    height: '72px !important',
    minWidth: '48px !important',
    display: 'flex',
    margin: '0px 4px !important'
  },
  containerCreate: {
    whiteSpace: 'nowrap',
    // minWidth: '154px !important',
    backgroundColor: `${COLOR.commonColor.white} !important`,
    height: '40px !important',
    // display: 'block !important',
    border: '0px !important',
    margin: 'auto !important',
    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.16), 0px 2px 4px rgba(0, 0, 0, 0.12), 0px 1px 8px rgba(0, 0, 0, 0.1)'
  },
  containerCreateMini: {
    whiteSpace: 'nowrap',
    width: '40px !important',
    height: '40px !important',
    backgroundColor: `${COLOR.commonColor.white} !important`,
    display: 'block !important',
    border: '0px !important',
    margin: 'auto !important',
    borderRadius: 4,
    boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.2)'
  },
  subContainer: {
    minWidth: '32px !important',
    height: '48px !important',
    padding: '8px 16px !important',
    margin: 'auto 0px !important',
  },
  subContainerMini: {
    height: '44px !important',
    padding: '8px 16px !important',
    margin: 'auto 0px !important',
    minWidth: '32px !important',
  },
  textNormal: {
    color: `${COLOR.commonColor.white} !important`
  },
  textPrimary: {
    color: `${COLOR.commonColor.primary} !important`
  },
}))

const ColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: COLOR.commonColor.white,
  backgroundColor: COLOR.commonColor.primary,
  borderRadius: 4,
  textTransform: 'capitalize !important' as any,
  borderTop: `0x solid ${COLOR.commonColor.primary}`,
  // borderBottom: `2px solid ${COLOR.commonColor.primary}`,
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.08)',
    borderTop: `0px solid ${COLOR.commonColor.primary}`,
  },
}));

const SubColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: COLOR.commonColor.white,
  backgroundColor: COLOR.commonColor.primary,
  borderRadius: 0,
  textTransform: 'capitalize !important' as any,
  '&:hover': {
    backgroundColor: COLOR.commonColor.primary,
    textDecoration: 'underline',
  },
}));

export const MenuHeader = (props: any) => {
  const classes = useStyles()
  const {
    DashboardStore
  } = useRootStore()
  const { activeHeader } = DashboardStore
  const { label, onClick, active, miniStyle, disabled } = props

  const onClickMenu = () => {
    if (onClick) {
      onClick(active)
    }
  }
  if (miniStyle) {
    return (
      <Box className={classes.boxCreateMini}>
        <ColorButton disabled={disabled} onClick={onClickMenu} className={active === activeHeader ? classes.containerButtonUnderlineMini : classes.containerButtonMini} >
          <Typography variant='body2' className={classes.textNormal}> {label}</Typography>
        </ColorButton >
      </Box>
    )
  } else {
    return (
      <Box className={classes.boxCreate}>
        <ColorButton disabled={disabled} onClick={onClickMenu} className={active === activeHeader ? classes.containerButtonUnderline : classes.containerButton} >
          <Typography variant='body1' className={classes.textNormal}> {label}</Typography>
        </ColorButton >
      </Box>
    )
  }


}

export const CreateServiceHeader = (props: any) => {
  const classes = useStyles()
  // const {
  //   LocalizationStore: { translate },
  //   AuthStore
  // } = useRootStore()
  const { label, onClick, miniStyle } = props
  const onClickMenu = () => {
    if (onClick) {
      onClick()
    }
  }
  if (miniStyle) {

    return (
      <Box className={classes.boxCreateMini}>
        <IconButton color="primary" onClick={onClickMenu} className={classes.containerCreateMini}>
          <UnionIcon />
        </IconButton>
      </Box>
    )
  } else {
    return (
      <Box className={classes.boxCreate}>
        <Button onClick={onClickMenu} className={classes.containerCreate} variant='outlined'
          startIcon={<UnionIcon />} >
          <Typography variant='button' className={classes.textPrimary}> {label}</Typography>
        </Button >
      </Box>
    )
  }

}

export const SubMenuHeader = (props: any) => {
  const classes = useStyles()
  // const {
  //   LocalizationStore: { translate },
  //   AuthStore
  // } = useRootStore()
  const { label, onClick, miniStyle } = props
  const onClickMenu = () => {
    if (onClick) {
      onClick()
    }
  }
  if (miniStyle) {
    return (
      <Box className={classes.boxSubHeaderMini}>
        <SubColorButton onClick={onClickMenu} className={classes.subContainerMini} >
          <Typography variant='body2' className={classes.textNormal}> {label}</Typography>
        </SubColorButton >
      </Box>
    )
  } else {
    return (
      <Box className={classes.boxHeader}>
        <SubColorButton onClick={onClickMenu} className={classes.subContainer} >
          <Typography variant='body1' className={classes.textNormal}> {label}</Typography>
        </SubColorButton >
      </Box>
    )
  }

}
export default observer(MenuHeader)

