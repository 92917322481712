import useRootStore from '@/hooks/useRootStore'
import { observer } from 'mobx-react-lite'
import { Banner } from '@/components/Banner/Banner'

const BannerProvider = observer(function BannerProvider() {
  const rootStore = useRootStore()
  const { show, message, color, variant, action, onClose, multiline } =
    rootStore.UIBannerStore

  if (!show) return null

  return (
    <Banner
      open={show}
      className="blockpass-banner"
      color={color}
      variant={variant}
      sx={{
        position: 'relative',
        boxSizing: 'border-box',
        mb: 3,
      }}
      onClose={onClose}
      action={action}
      multiline={multiline}
      contained
    >
      {message}
    </Banner>
  )
})

export default BannerProvider
