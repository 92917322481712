// import { useState } from 'react'
import { observer } from 'mobx-react-lite'
import useRootStore from '@/hooks/useRootStore'
import { Button, Typography } from '@blockpass-org/ui'
import { BlockpassLogoLogin, BlockpassLogoBig } from '@components/Icon/CommonIcons'
import { COLOR } from '@theme/colors'
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import {
  REDIRECT_TO_MERCHANT_TERMS, REDIRECT_TO_PRIVACY, REDIRECT_TO_BLOCKPASS, REDIRECT_TO_HELP,
  KYB_SERVICE_DISPLAY_KEY, SERVICE_DISPLAY_KEY, ACTIVITY_LOGS_KEY, COMPANY_KEY, SERVICE_ADMIN_KEY,
  SERVICE_APIKEY_KEY, SERVICE_BILLING_KEY, SERVICE_TEAM_KEY, SERVICE_WEBHOOK_KEY, SERVICE_USAGE_KEY
} from '@constants/constants'
import MicroFrontendCache from '../../MicroFrontendCache'
import { getCurrentYear } from '../../utils'

export const LoginPage = observer(() => {

  const rootStore = useRootStore()

  const {
    LocalizationStore: { translate },
    DashboardStore
  } = rootStore

  //cache
  const microObjLogs = DashboardStore.getMicroConfigDetail(
    ACTIVITY_LOGS_KEY
  )
  const hostLogs = (microObjLogs && microObjLogs?.host) || ''

  const microObjCompany = DashboardStore.getMicroConfigDetail(
    COMPANY_KEY
  )
  const hostCompany = (microObjCompany && microObjCompany?.host) || ''
  //service detail
  const microObj = DashboardStore.getMicroConfigDetail(
    SERVICE_DISPLAY_KEY
  )
  const host = (microObj && microObj?.host) || ''

  const microObjApi = DashboardStore.getMicroConfigDetail(
    SERVICE_APIKEY_KEY
  )
  const hostApi = (microObjApi && microObjApi?.host) || ''

  const microObjBilling = DashboardStore.getMicroConfigDetail(
    SERVICE_BILLING_KEY
  )
  const hostBilling = (microObjBilling && microObjBilling?.host) || ''

  const microObjTeam = DashboardStore.getMicroConfigDetail(
    SERVICE_TEAM_KEY
  )
  const hostTeam = (microObjTeam && microObjTeam?.host) || ''

  const microObjWebhook = DashboardStore.getMicroConfigDetail(
    SERVICE_WEBHOOK_KEY
  )
  const hostWebhook = (microObjWebhook && microObjWebhook?.host) || ''

  const microObjUsage = DashboardStore.getMicroConfigDetail(
    SERVICE_USAGE_KEY
  )
  const hostUsage = (microObjUsage && microObjUsage?.host) || ''

  const microObjKybDisplay = DashboardStore.getMicroConfigDetail(
    KYB_SERVICE_DISPLAY_KEY
  )
  const hostKybDisplay = (microObjKybDisplay && microObjKybDisplay?.host) || ''

  const microObjAdmin = DashboardStore.getMicroConfigDetail(
    SERVICE_ADMIN_KEY
  )
  const hostAdmin = (microObjAdmin && microObjAdmin?.host) || ''

  const doLogin = async () => {
    try {
      await rootStore.AuthStore.signin()
      // rootStore.AuthStore.signinByOAuth()
    } catch (err) {
      DashboardStore.loginLoading = false
      rootStore.AuthStore.signout()
    } finally {
      DashboardStore.loginLoading = false
    }
  }

  const footer = () => {
    const currentYear = getCurrentYear()

    return <Stack
      direction={{ xs: 'column', sm: 'column', md: 'column', lg: 'row', xl: 'row' }}
      spacing={{ xs: 4, sm: 4, md: 4, lg: 1, xl: 1 }}
      justifyContent='space-between'
      alignItems={{ xs: 'flex-start', sm: 'flex-start', md: 'flex-start', lg: 'center', xl: 'center' }}
      width={'calc(100% - 128px)'}
      style={{ padding: '32px 64px', maxWidth: '1280px', margin: 'auto' }}
    >
      <Box style={{ display: 'flex' }}>
        <Link
          // onClick={() => {
          //   window.open(REDIRECT_TO_BLOCKPASS, '_blank')
          // }}
          underline="hover"
          color={COLOR.textColor.secondary}
          style={{ marginRight: 24 }}
          href={REDIRECT_TO_BLOCKPASS}
          target="_blank"
          rel="noopener"
        >
          <Typography variant="body2" color='textSecondary'>
            Blockpass.org
          </Typography>
        </Link>
        <Link
          // onClick={() => {
          //   window.open(REDIRECT_TO_MERCHANT_TERMS, '_blank')
          // }}
          underline="hover"
          color={COLOR.textColor.secondary}
          style={{ marginRight: 24 }}
          href={REDIRECT_TO_MERCHANT_TERMS}
          target="_blank"
          rel="noopener"
        >
          <Typography variant="body2" color='textSecondary'>
            {translate('txtBusinessTerms')}
          </Typography>
        </Link>
        <Link
          // onClick={() => {
          //   window.open(REDIRECT_TO_HELP, '_blank')
          // }}
          underline="hover"
          color={COLOR.textColor.secondary}
          style={{ marginRight: 24 }}
          href={REDIRECT_TO_HELP}
          target="_blank"
          rel="noopener"
        >
          <Typography variant="body2" color='textSecondary'>
            {translate('txtHelp')}
          </Typography>
        </Link>
        <Link
          // onClick={() => {
          //   window.open(REDIRECT_TO_PRIVACY, '_blank')
          // }}
          underline="hover"
          color={COLOR.textColor.secondary}
          style={{ marginRight: 24 }}
          href={REDIRECT_TO_PRIVACY}
          target="_blank"
          rel="noopener"
        >
          <Typography variant="body2" color='textSecondary'>
            {translate('txtPrivacy')}
          </Typography>
        </Link>
      </Box>
      <Typography variant='body2' color='textSecondary' style={{ textAlign: 'center' }}>{'© Blockpass ' + currentYear}</Typography>
    </Stack>
  }
  return (
    <>
      <MicroFrontendCache
        history={window.history}
        host={host}
        name={microObj?.id || ''}
        containerStore={{}}
      />
      <MicroFrontendCache
        history={window.history}
        host={hostLogs}
        name={microObjLogs?.id || ''}
        containerStore={{}}
      />
      <MicroFrontendCache
        history={window.history}
        host={hostCompany}
        name={microObjCompany?.id || ''}
        containerStore={{}}
      />
      <MicroFrontendCache
        history={window.history}
        host={hostApi}
        name={microObjApi?.id || ''}
        containerStore={{}}
      />
      <MicroFrontendCache
        history={window.history}
        host={hostTeam}
        name={microObjTeam?.id || ''}
        containerStore={{}}
      />
      <MicroFrontendCache
        history={window.history}
        host={hostBilling}
        name={microObjBilling?.id || ''}
        containerStore={{}}
      />
      <MicroFrontendCache
        history={window.history}
        host={hostWebhook}
        name={microObjWebhook?.id || ''}
        containerStore={{}}
      />
      <MicroFrontendCache
        history={window.history}
        host={hostUsage}
        name={microObjUsage?.id || ''}
        containerStore={{}}
      />
      <MicroFrontendCache
        history={window.history}
        host={hostKybDisplay}
        name={microObjKybDisplay?.id}
        containerStore={{}}
      />
      <MicroFrontendCache
        history={window.history}
        host={hostAdmin}
        name={microObjAdmin?.id}
        containerStore={{}}
      />
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 'calc(100% - 84px)',
        backgroundColor: COLOR.commonColor.white,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundImage: `url('data:image/svg+xml,<svg width="768" height="666" viewBox="0 0 768 666" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g clip-path="url(%23clip0_2763_30403)"><rect width="768" height="768" transform="translate(0 -102)" fill="url(%23pattern0)" fill-opacity="0.06"/><rect width="768" height="768" transform="translate(0 -102)" fill="url(%23paint0_radial_2763_30403)"/></g><defs><pattern id="pattern0" patternContentUnits="objectBoundingBox" width="0.05625" height="0.05625"><use xlink:href="%23image0_2763_30403" transform="scale(0.00234375)"/></pattern><radialGradient id="paint0_radial_2763_30403" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(384 384) rotate(-90) scale(384)"><stop stop-color="white" stop-opacity="0"/><stop offset="1" stop-color="white"/><stop offset="1" stop-color="white"/></radialGradient><clipPath id="clip0_2763_30403"><rect width="768" height="768" fill="white" transform="translate(0 -102)"/></clipPath><image id="image0_2763_30403" width="24" height="24" xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAADxJREFUSA3t0rEJAEAIxdDv7b+z3gYpxC7WQeFhcjv9bvcnHkBhiSRCAQz8IolQAAO/SCIUwKB+0VgtggHmUQIsFeZn2wAAAABJRU5ErkJggg=="/></defs></svg>')`
      }}>
        <div style={{
          maxWidth: '1280px'
        }}>
          <div
            style={{
              textAlign: 'center',
              width: '360px',
              margin: 'auto'
            }}
          >
            <BlockpassLogoBig style={{ width: 80, height: 80, marginBottom: 32 }} />
            <div style={{ display: 'flex', marginBottom: 12, justifyContent: 'center' }}>
              <Typography variant='h4'>
                {translate('txtWelcomeTo')}
                <Typography variant='h4' color={COLOR.commonColor.primary} component={'span'}>
                  &nbsp;Blockpass
                </Typography>
              </Typography>


            </div>
            <Typography variant='body1' color={COLOR.textColor.secondary} style={{ marginBottom: 32 }}>
              {translate('txtToGetStart')}
            </Typography>
            <Button
              loading={DashboardStore.loginLoading}
              color="primary"
              variant="contained"
              onClick={() => doLogin()}
              startIcon={<BlockpassLogoLogin />}
              style={{ width: '100%', height: 48 }}
            >
              {translate('txtLogin')}

            </Button>
          </div>
        </div>
      </div>
      {
        footer()
      }
    </>
  )
})
export default LoginPage


