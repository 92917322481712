import { observable, makeObservable, toJS } from 'mobx'
import get from 'lodash/get'
import { getCookie, setCookie, deleteCookie } from '../../utils'
import Cookies from 'js-cookie'
import BaseStore from '../base/BaseStore'
import RootStore from '../RootStore'
import { AuthClient } from '@blockpass-org/bp-auth-client'
import { BLOCKPASS_SERVICE } from '@blockpass-org/utils-http'
import AdminConsoleClient from '@blockpass-org/bp-admin-console-client'
import { NOTI_AUTO_HIDE_TIME } from '@workspace/shared/constants/constants'
const pjson = require('../../../package.json')
const env = get(window, 'env.BLOCKPASS_ENV')

const bpService = BLOCKPASS_SERVICE.ac

const COOKIES_SSID = get((window as any).env, 'COOKIES_SSID', 'SSID')
class AuthStore extends BaseStore {
  _rootStore: RootStore
  authClient: AuthClient
  accessToken = ''
  userProfile: any = {}
  verifying = true
  consoleClient: AdminConsoleClient

  isAuthenticated = false
  openSessionExpired = false
  constructor(rootStore: RootStore) {
    super()
    makeObservable(this, {
      userProfile: observable,
      accessToken: observable,
      verifying: observable,
      // consoleClient: observable,
      isAuthenticated: observable,
      openSessionExpired: observable,
    })
    this._rootStore = rootStore
    this._init()
  }

  async _init() {
    this.isAuthenticated = false
    this.verifying = true
    this.consoleClient = null
    this.authClient = new AuthClient(bpService, 'web', pjson.version, env)

    const token = Cookies.get(COOKIES_SSID)
    if (token) {
      this.setAccessToken(token)

      await this.getMerchantProfile()
      await this.getMicroHost()
      // await this.getPlanConfig()
      // await this.getPlanDisplayConfig()
      // await this.getBillingConfig()
      this.setAuthenticated(true)
      this.verifying = false
    } else {
      this.setAuthenticated(false)
      this.verifying = false
    }
  }

  getErrorMessage = (error: any) => {
    let msg = 'txtUnauthorized'
    if (error.code === 401) {
      // this.setAuthenticated(false)
      error.message = 'txtUnauthorized'
    } else if (error.code === 400) {
      msg = error.message
    } else {
      msg = error.message
    }
    return msg
  }
  showNotificationWithString = (notiString) => {
    const { UINotificationStore } = this._rootStore
    UINotificationStore.showSnackbar({
      message: notiString,
      options: {
        variant: 'notification',
        actionLabel: '',
        // actionCallback: null,
        dismiss: true,
        preventDuplicate: false,
        autoHideDuration: NOTI_AUTO_HIDE_TIME,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
      },
    })
  }

  showNotificationWithError = (error) => {
    const {
      UINotificationStore,
      LocalizationStore: { translate },
    } = this._rootStore
    if (error.code === 401) {
      this.setOpenSessionExpired(true)
    } else if (error.code === 500) {
      UINotificationStore.showSnackbar({
        message: 'An unexpected error happened on the server',
        options: {
          variant: 'notification',
          actionLabel: 'RELOAD',
          dismiss: true,
          preventDuplicate: false,
          autoHideDuration: NOTI_AUTO_HIDE_TIME,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          actionCallback: () => {
            window.location.reload()
          },
          persist: true,
        },
      })
    } else {
      UINotificationStore.showSnackbar({
        message: translate(error.message),
        options: {
          variant: 'notification',
          actionLabel: '',
          // actionCallback: null,
          dismiss: true,
          preventDuplicate: false,
          autoHideDuration: NOTI_AUTO_HIDE_TIME,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
        },
      })
    }
  }

  getBillingConfig = async () => {
    const { DashboardStore } = this._rootStore
    try {
      if (this.consoleClient) {
        const res = await this.consoleClient.runtimeConfig.getRuntimeConfigList(
          ['billing_service_plan_info', 'billing_service_display_plans']
        )

        if (res.result !== null) {
          const objConfig = res.entities.runtimeConfigs
          const valuePlanConfig = get(
            objConfig,
            'billing_service_plan_info.value',
            ''
          )
          const valueDisplayPlanConfig = get(
            objConfig,
            'billing_service_display_plans.value',
            ''
          )

          DashboardStore.displayPlanConfig = JSON.parse(
            valueDisplayPlanConfig as string
          )
          DashboardStore.planConfig = JSON.parse(valuePlanConfig as string)

          DashboardStore.loadBillingConfigDone = true
        }

        return true
      }
    } catch (error) {
      this._rootStore.debugLog('error: ', error)
      this.showNotificationWithError(error)

      return false
    }
    return false
  }

  getPlanConfig = async () => {
    const { DashboardStore } = this._rootStore
    try {
      if (this.consoleClient) {
        const res =
          await this.consoleClient.runtimeConfig.getRunTimeConfigDetail(
            'billing_service_plan_info'
          )

        if (res.result !== null) {
          const objConfig = res.entities.runtimeConfigs
          const value = get(objConfig, 'billing_service_plan_info.value', '')

          DashboardStore.planConfig = JSON.parse(value as string)
        }

        return true
      }
    } catch (error) {
      this._rootStore.debugLog('error: ', error)
      this.showNotificationWithError(error)

      return false
    }
    return false
  }

  getPlanDisplayConfig = async () => {
    const { DashboardStore } = this._rootStore
    try {
      if (this.consoleClient) {
        const res =
          await this.consoleClient.runtimeConfig.getRunTimeConfigDetail(
            'billing_service_display_plans'
          )

        if (res.result !== null) {
          const objConfig = res.entities.runtimeConfigs
          const value = get(
            objConfig,
            'billing_service_display_plans.value',
            ''
          )

          DashboardStore.displayPlanConfig = JSON.parse(value as string)
        }

        return true
      }
    } catch (error) {
      this._rootStore.debugLog('error: ', error)
      this.showNotificationWithError(error)
      return false
    }
    return false
  }

  getMicroHost = async () => {
    const { DashboardStore } = this._rootStore
    try {
      if (this.consoleClient) {
        const res = await this.consoleClient.runtimeConfig.getMicroFEConfig()

        if (res.result !== null) {
          const objConfig = res.entities.runtimeConfigs
          const result = Object.keys(objConfig).map((key) => objConfig[key])
          DashboardStore.microHost = result

          const microConfigData = get(
            objConfig,
            'micro_frontend_config.value',
            ''
          ).toString()
          DashboardStore.microConfig = JSON.parse(microConfigData)
          DashboardStore.initDetailIdList()
        }

        return true
      }
    } catch (error) {
      this._rootStore.debugLog('error: ', error)
      this.showNotificationWithError(error)
      return false
    }
    return false
  }

  getMerchantProfile = async () => {
    try {
      if (this.consoleClient) {
        const res = await this.consoleClient.merchantProfile.getProfile()

        if (res.result !== null) {
          const svId = res.result

          this.userProfile = res.entities.users[svId]
          this.mergeServiceRole()
        }

        return true
      }
    } catch (error) {
      this._rootStore.debugLog('error: ', error)
      this.showNotificationWithError(error)
      this.signout()
      return false
    }

    this.signout()
    return false
  }

  mergeServiceRole = () => {
    // serviceRole + kybServiceRole -> serviceRole
    if (
      this.userProfile.hasOwnProperty('serviceRole') &&
      this.userProfile.hasOwnProperty('kybServiceRole')
    ) {
      this.userProfile['serviceRole'] = Object.assign(
        toJS(this.userProfile['serviceRole']),
        toJS(this.userProfile['kybServiceRole'])
      )
    }
  }

  _setDevCookies(ssid: string) {
    const name = get(window, 'env.COOKIES_SSID', 'QASSID')
    const expAt = new Date(Date.now() + 24 * 60 * 60 * 1000)
    setCookie(name, ssid, expAt)
  }

  _storeMemoryToken({ expiry, accessToken }) {
    // this.memoryToken = accessToken
  }

  setAccessToken = (token) => {
    this.accessToken = token
    this.consoleClient = new AdminConsoleClient(this.accessToken, env)

    //cookies
  }

  async withLocalizeError<R>(action: () => Promise<R>): Promise<R> {
    try {
      return await action()
    } catch (error) {
      const translatedText = this._rootStore.LocalizationStore.translate(
        error.message,
        {
          fallbackRawValue: error.message,
        }
      )
      throw new Error(translatedText)
    }
  }

  setUserProfile = (profile: any) => {
    this.userProfile = profile
    this.mergeServiceRole()
  }

  redirectToOauth = () => {
    //show login screen
    this.setAuthenticated(false)
  }

  openOauth = () => {
    const authHost = get(
      (window as any).env,
      'AUTH_HOST',
      'oauth.blockpass.org'
    )
    // const redirectLink = get((window as any), 'location.href', 'location.origin')
    const redirectLink = get(
      window as any,
      'location.origin',
      'location.origin'
    )
    // https://qa-oauth.blockpass.org/?client_id=blockpass-intl&redirect_uri=<ac_v2_url>
    const urlOauth = `${authHost}/?client_id=blockpass-intl&redirect_uri=${encodeURIComponent(
      redirectLink
    )}`
    window.open(urlOauth, '_self')
  }

  openEnableTwoFa = () => {
    const authHost = get(
      (window as any).env,
      'AUTH_HOST',
      'oauth.blockpass.org'
    )
    window.open(authHost, '_blank')
  }

  getFinishTutorial = () => {
    return get(
      this.userProfile,
      'hasCompleted.FIRST_TIME_ACCESS_TUTORIAL',
      true
    )
  }

  setFinishTutorial = () => {
    // @ts-ignore
    this.userProfile.hasCompleted = {}
    // @ts-ignore
    this.userProfile.hasCompleted.FIRST_TIME_ACCESS_TUTORIAL = true
  }
  getServiceRole = () => {
    return get(this.userProfile, 'serviceRole', '')
  }

  getServiceRoleByClientId = (clientId) => {
    return get(this.userProfile, `serviceRole.${clientId}`, '')
  }
  getSystemRole = () => {
    return get(this.userProfile, 'systemRole', 'user')
  }
  getMicroUserProfile = (clientId) => {
    //update service role
    let cloneObj = { ...toJS(this.userProfile) }
    cloneObj['serviceRole'] = get(
      this.userProfile,
      `serviceRole.${clientId}`,
      ''
    )
    return cloneObj
  }
  getDisplayUserProfile = () => {
    return toJS(this.userProfile)
  }
  getSubscribed = () => {
    return get(toJS(this.userProfile), 'isSubscribeNewsLetter', false)
  }
  setSubscribed = (b) => {
    this.userProfile['isSubscribeNewsLetter'] = b
  }
  // ---------------------------------------------//
  //  Scope
  // ---------------------------------------------//
  hasScope = (scope) => {
    // if (!scope) return true
    // return this.scopes.includes(scope)

    return true
  }

  hasPermission(permissions) {
    return permissions.indexOf(this.getSystemRole()) !== -1
  }
  // ---------------------------------------------//
  //  Public - Register, Login
  // ---------------------------------------------//
  getTokenFromCookies = () => {
    const cookiesKey = get(window, 'env.COOKIES_SSID', 'SSID')
    return getCookie(cookiesKey)
  }

  getToken = (cookiesKey: string) => {
    return getCookie(cookiesKey)
  }

  ///////blockpass login///////
  public setAuthenticated(value: boolean) {
    this.isAuthenticated = value
  }

  public setOpenSessionExpired(value: boolean) {
    this.openSessionExpired = value
  }

  public signin = async (callback?: VoidFunction) => {
    const { DashboardStore } = this._rootStore
    try {
      DashboardStore.loginLoading = true
      const ssid = await this.authClient.openLoginWindow()
      this.setAccessToken(ssid)

      await this.getMerchantProfile()
      await this.getMicroHost()

      DashboardStore.loginLoading = false

      this.setAuthenticated(true)
      // callback && callback()
    } catch (error) {
      DashboardStore.loginLoading = false
    }
  }

  public signinByOAuth = async (callback?: VoidFunction) => {
    this.setAuthenticated(false)
    this.openOauth()
  }

  public signout = async (callback?: VoidFunction) => {
    if (!this.accessToken) return
    const cookiesKey = get(window, 'env.COOKIES_SSID', 'SSID')
    deleteCookie(cookiesKey)
    this.setAuthenticated(false)
    try {
      await this.authClient.logout(this.accessToken)
    } catch (error) {}
    callback && callback()
  }

  // ---------------------------------------------//
  //  Life cycle
  // ---------------------------------------------//
  async start() {
    // this._rootStore.on(EV_BROWSER_MSG, this._onBrowserEvent)
  }

  async stop() {
    // this._rootStore.off(EV_BROWSER_MSG, this._onBrowserEvent)
  }
}

export default AuthStore
