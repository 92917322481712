import React from 'react'
import COLOR from '@theme/colors'
import { LayoutMain, MainWrapper } from './Main.styled'
export const Layout: React.FC = ({ children }) => {
  return (
    <MainWrapper style={{ backgroundColor: COLOR.bgColorSecondary, marginTop: 80 }}>
      <LayoutMain>{children}</LayoutMain>
    </MainWrapper>
  )
}

export default Layout
