/* eslint-disable */
// prettier-ignore
export default {
  "txPersonalAttributeDesc": "You can request additional information from your users during onboarding. These attributes are not verified by Blockpass.",
  "txt2FA": "2FA",
  "txt404": "The page you're looking for cannot be found!",
  "txtAML": "AML",
  "txtAMLCheck": "AML check",
  "txtAMLDesc1": "Screen against a global database of PEP/Sanction lists, and adverse media, and identify if your customers are politically exposed, sanctioned or have an adverse media profile.",
  "txtAMLDesc2": "This check requires users to provide their full name  and date of birth.",
  "txtAPIAndWebhooks": "API and webhooks",
  "txtAccountDeletion": "Account deletion",
  "txtAccountDeletionDesc": "Delete mobile and web accounts",
  "txtAction": "Action",
  "txtActive": "Active",
  "txtActivityLogs": "Activity Logs",
  "txtAdd": "Add",
  "txtAddCompany": "Add company",
  "txtAddMember": "Add member",
  "txtAddMemberDesc": "Member will get an email that gives access to this service.",
  "txtAddMemberEmailIsRequired": "Please enter your member’s email.",
  "txtAddMembers": "Add members",
  "txtAddNewBpOperatorToService": "Assign",
  "txtAddNewCompany": "Add new company",
  "txtAddNewOperatorSuccess": "Add new operator successfully.",
  "txtAddNewsContent": "Add News",
  "txtAddNotification": "Add Notification",
  "txtAddOperatorToService": "Add operator to service",
  "txtAddPaymentMethod": "Add payment method",
  "txtAddPaymentMethodFail": "Something wrong here. Please add payment method later.",
  "txtAddPaymentMethodSuccessfully": "Your payment card is successfully added",
  "txtAddRichContent": "Add Rich Content",
  "txtAddTag": "Add tag",
  "txtAddTagPlaceholder": "Type a label of tag...",
  "txtAddTagSuccess": "”<0>” tag added successfully.",
  "txtAddingTag": "Adding tag ",
  "txtAdmin": "Admin",
  "txtAdminConsole": "Admin Console",
  "txtAdminSettingsUpdateSuccess": "Admin settings updated successfully.",
  "txtAdministration": "Administration",
  "txtAdvancedApprovalDesc": "Advanced KYC Bot: ",
  "txtAdvancedApprovalDesc1": "Set of 10+ rules to auto approve or reject users.",
  "txtAdvancedApprovalTooltip": "Advanced KYC Bot is not available for this plan. Contact us if you want to enable it.",
  "txtAfterReturn": "after the API has returned the data ",
  "txtAgeRestriction": "Age restriction",
  "txtAgeRestrictionDesc1": "You can exclude applicants based on their age. ",
  "txtAgeRestrictionDesc2": "Applicant must be at least",
  "txtAgeRestrictionDesc3": "years old.",
  "txtAllPlanFeatureHeader": "All plans include the following features",
  "txtAllTimeAreUTC": "(All time are UTC)",
  "txtAllowed": "Allowed",
  "txtAllowedCountries": "allowed countries",
  "txtAnnouncements": "Announcements",
  "txtAnyoneWithTheLink": "Anyone with the link",
  "txtAnyoneWithTheLinkDesc1": "Anyone with this link can register with your service.",
  "txtAnyoneWithTheLinkDesc2": "This page allows applicants to send their KYC info to your dashboard. Copy and paste the link in an email or any place you want to share it.",
  "txtApiKey": "API key",
  "txtApiKeyCopied": "Your API key copied",
  "txtApiKeyDeleteDesc": "Once deleted, the API key cannot be recovered. If you’re not entirely sure that the key can be deleted, you can set the status to inactive instead.",
  "txtApiKeys": "API Keys",
  "txtApply": " apply.",
  "txtApproveAdvanced": "Advanced KYC Bot",
  "txtApproveBasic": "KYC Bot",
  "txtApproveDesc1": "You can save time by selecting the mode of review. \n",
  "txtApproveDesc2": "KYC bots are recommended for large volume of users. See ",
  "txtApproveDesc3": "more details",
  "txtApproveDesc4": " in the documentation",
  "txtApproveFreeManual": "Manual: Approve or reject each user manually. Select a plan to speed up your onboarding process.",
  "txtApproveNone": "Manual review",
  "txtApproverTooltipkyc": "Can review and approve KYC. Cannot delete profiles or update settings",
  "txtApproverkyc": "KYC Approver",
  "txtAssignCompanyToService": "Add info of company",
  "txtAssignRole": "Assigned role",
  "txtAssignUserRole": "Assigned role",
  "txtAttributeDataDesc": "Once a profile is archived, only metadata remains, identity attributes are deleted.",
  "txtAttributesConfig": "Attributes config",
  "txtAttributesConfigDesc": "Configure attributes available in user profiles",
  "txtAttributesGroupConfig": "Attributes group config",
  "txtAttributesGroupConfigDesc": "Configure group of attributes available in user profiles",
  "txtAutoApproval": "Auto approval",
  "txtAutoArchive": "Auto archive the user's profile",
  "txtAutoArchiveTooltip": "Auto archive the user's profile @@ar_ch_ti_me@@ seconds after the API has returned the data",
  "txtAutoTranslate": "(auto-translated)",
  "txtAutomationRules": "Automation rules",
  "txtAvatar": "Avatar",
  "txtAvaxAddress": "Avalanche address",
  "txtBack": "Back",
  "txtBasicApprovalDesc": "KYC Bot:  ",
  "txtBasicApprovalDesc1": "Auto approve users with clear checks.",
  "txtBasicInfo": "Basic info",
  "txtBestPractices": "best practices",
  "txtBilling": "Billing",
  "txtBillingInfo": "Billing information",
  "txtBillingInfomation": "Billing information",
  "txtBillingOwner": "Billing owner",
  "txtBinanceAddress": "Binance (BSC) address",
  "txtBitcoinAddress": "Bitcoin address",
  "txtBlockpass": "Blockpass",
  "txtBlockpassAccess": "Blockpass access",
  "txtBlockpassAccessDesc": "Allow Blockpass support team to access your KYC dashboard",
  "txtBlockpassAccessPermission": "You do not have permission to access this feature",
  "txtBlockpassKycRules": "Blockpass KYC rules",
  "txtBpKYCRules": "Blockpass KYC rules",
  "txtBrowse": "Browse",
  "txtBtcAdress": "Bitcoin address",
  "txtBusinessInfomation": "Business information",
  "txtBusinessTerms": "Business terms",
  "txtCanNotSetTagsOnChainKYCForMultipleCryptoService": "Can not set tags onchainKyc for services have multiple crypto field",
  "txtCancel": "Cancel",
  "txtCardHolderName": "Card Holder Name",
  "txtCardInfomation": "Card infomation",
  "txtCardanoAddress": "Cardano (ADA) address",
  "txtCaution": "Caution !",
  "txtCautionDesc1": "If you are currently onboarding users, it is not recommended to change plan. Please contact us for assistance.",
  "txtCautionDesc2": "Otherwise, you can continue to the configuration.",
  "txtCertificatesOrderConfig": "Certificates order config",
  "txtCertificatesOrderConfigDesc": "Configure certificates order in users profiles",
  "txtChangeBillingPlan": "Change Billing Plan",
  "txtChangePlan": "Change plan",
  "txtChangeWebhook": "Updated webhook",
  "txtChartEmptyTooltip": "This chart will populate when your services are used by users.",
  "txtCheckDebugger": "Check debugger",
  "txtCheckDebuggerDesc": "Search checks by Check ID. A check usually issue a certificate",
  "txtChecksConfig": "Checks config",
  "txtChecksConfigDesc": "Configure attributes and certificate defining the check",
  "txtChinese": "Chinese",
  "txtClickOrDragToUpload": "Click or drag to upload your logo",
  "txtClientId": "Client ID",
  "txtClientIdExisted": "Client Id already exists, please use a different client Id",
  "txtClientIdTooltip": "Unique identifier of your service. \nIt is automatically generated based\non your service name",
  "txtClose": "Close",
  "txtCodeHasBeenCopied": "code has been copied",
  "txtCodeSample": "Code sample",
  "txtCompany": "Company",
  "txtCompanyAddress": "Company Address",
  "txtCompanyAddressCity": "City",
  "txtCompanyAddressCountry": "Country",
  "txtCompanyAddressLine1": "Address line 1",
  "txtCompanyAddressLine1IsRequired": "Company address line 1 is required.",
  "txtCompanyAddressLine2": "Address line 2 (optional)",
  "txtCompanyAddressPostalCode": "Postal code",
  "txtCompanyAddressPostalCodeRequired": "Postal code is required.",
  "txtCompanyAddressState": "State",
  "txtCompanyCity": "City",
  "txtCompanyCountryIsRequired": "Country is required",
  "txtCompanyCustomRequest": "Message",
  "txtCompanyEmailIsRequired": "Company email is required.",
  "txtCompanyGroup": "3. Company",
  "txtCompanyInfo": "Company Info",
  "txtCompanyInfomation": "Company information",
  "txtCompanyName": "Company Name",
  "txtCompanyNameIsRequired": "Company name is required",
  "txtCompanyNameRepIsRequired": "Name of company representative is required.",
  "txtCompanyNumber": "Company Number",
  "txtCompanyNumberIsRequired": "Company number is required",
  "txtCompanyPhoneRepIsRequired": "Phone of company representative is required.",
  "txtCompanyPostalCode": "Postal Code",
  "txtCompanyRepresentative": "Name of company representative",
  "txtCompanyState": "State",
  "txtCompanyVAT": "Tax or VAT number",
  "txtCompanyVATNumber": "Tax or VAT number",
  "txtCompanyVATNumberIsRequired": "Company VAT number is required",
  "txtConfiguration": "Configuration",
  "txtConfirm": "Confirm",
  "txtConfirmDeleteCompanyInfo": "If you go back to the previous step, any entered company information will be erased.",
  "txtConnectWithUs": "Connect with us",
  "txtContactSale": "Contact sales",
  "txtContactSaleTeam": "Contact our sales team",
  "txtContactSupport": "Contact sales",
  "txtContent": "Content",
  "txtContinue": "Continue",
  "txtContinueToConfigureService": "Continue to configure your service",
  "txtCopied": "Copied!",
  "txtCopy": "Copy",
  "txtCopyAndClose": "Copy and close",
  "txtCopyApiKeyWarning": "You won't be able to retrieve it as soon as you close the popup. Store it somewhere safe. \nIf lost, you will have to generate a new key.",
  "txtCopyLink": "Copy link",
  "txtCopyright": "© Blockpass ",
  "txtCount": "Count",
  "txtCountryRestriction": "Country restrictions",
  "txtCountryRestrictionDesc1": "You can exclude residents from some countries or accept only residents of others. This option requires users to provide their Residential address.",
  "txtCountryRestrictionDesc2": "Country provided here is an example only. You should check with your Compliance Officer regarding the countries to restrict.",
  "txtCountryRestrictionDescAllow": "\bAllow only residents from",
  "txtCountryRestrictionDescExclude": "Exclude residents from",
  "txtCoutryOfIncorporation": "Country of incorporation",
  "txtCreate": "Create",
  "txtCreateAPIKey": "Create API Key",
  "txtCreateAPIKeyFooter1": "Publicly exposing an API key can result in your users' personal data being compromised. ",
  "txtCreateAPIKeyFooter2": "You must follow ",
  "txtCreateAPIKeyFooter3": " for securely using API keys.",
  "txtCreateAccount": "Created new account",
  "txtCreateApiKeySuccess": "New API key created",
  "txtCreateBillingPlan": "Create Billing Plan",
  "txtCreateCertificate": "Created new certificate",
  "txtCreateCheckConfig": "Create Check Config",
  "txtCreateCompany": "Create company",
  "txtCreateFlowConfig": "Create Flow Config",
  "txtCreateIdentityField": "Created identity field",
  "txtCreateKYCSettings": "Created KYC settings",
  "txtCreateNewTag": "Create a tag ",
  "txtCreateRuntimeCfg": "Created runtime configuration",
  "txtCreateService": "Created new service",
  "txtCreateServiceDashboard": "Create service",
  "txtCreateServiceSuccess": "New service created successfully",
  "txtCreateVersion": "Created new API version",
  "txtCreateWebhook": "Created webhook",
  "txtCreatedAt": "Created At",
  "txtCryptoAddresses": "Crypto Addresses",
  "txtCurrentPlan": "Current plan",
  "txtCustomRegistrationUrl": "Custom registration url",
  "txtCustomUrlInvalid": "This URL is invalid.",
  "txtCustomiseAppLink": "App link for mobile",
  "txtDAY": "Day",
  "txtDOB": "Date of birth",
  "txtDashboard": "Dashboard",
  "txtDataExport": "Data export",
  "txtDate": "Date",
  "txtDelete": "Delete",
  "txtDeleteAPIKey": "Delete API Key",
  "txtDeleteApiKeySuccess": "API key deleted",
  "txtDeleteBulkIdentityRecordConfirmMessage": "Are you sure you want to delete these accounts?",
  "txtDeleteThisApiKey": "Delete this API key",
  "txtDeleteVersion": "Deleted API version",
  "txtDeleteWebhook": "Deleted webhook",
  "txtDescription": "Description",
  "txtDescriptionIsRequired": "Please enter a description for this API key",
  "txtDetachPaymentMethod": "Detach Payment Method",
  "txtDetails": "Details",
  "txtDisabled": "Disabled",
  "txtDiscard": "Discard",
  "txtDiscardRichContent": "Discard Rich Content",
  "txtDisplay": "Display",
  "txtDoNotSendAddMember": "You don't have permission to invite a new member. Please contact the service owner. Error code 403.",
  "txtDocs": "Docs",
  "txtDocumentation": "Documentation",
  "txtDonNotHaveService": "You don't have any service in this company",
  "txtDontHavePaymentMethod": "You don't have any payment method, please add a payment method below",
  "txtDowngradeBpOperator": "Downgrade",
  "txtDownload": "Download",
  "txtDownloadLogs": "Download logs",
  "txtDrivingLicense": "Driving license",
  "txtDropFileHere": "Drop your logo here",
  "txtEditClientId": "Edit Client ID",
  "txtEditClientIdWarning": "This Client ID cannot be changed later and must be unique.",
  "txtEditConfig": "Edit configuration",
  "txtEditMembers": "Edit members",
  "txtEditNewsContent": "Edit News",
  "txtEditSearch": "Edit search",
  "txtEmail": "Email",
  "txtEmailAddress": "Email address",
  "txtEmailRepresentative": "Email of Company representative",
  "txtEmailWrongFormat": "Wrong email format",
  "txtEnable": "Enable",
  "txtEnableApiKeys": "Enable API keys",
  "txtEnableApiKeysDes": "Allow customers to use API keys to access users' data.",
  "txtEnableEndUserRegistrations": "Enable end-user registrations",
  "txtEnableEndUserRegistrationsDes": "Allows end users to register for verification with this service.",
  "txtEnableKycDashboard": "Enable KYC dashboard",
  "txtEnableKycDashboardDes": "Allow customers to access the KYC Dashboard.",
  "txtEnableService": "Enable Service",
  "txtEnableServiceDes": "This option is dangerous; turning the service on and off frequently can cause serious errors.",
  "txtEnabled": "Enabled",
  "txtEnabled2FA": "Enabled 2FA",
  "txtEndDate": "End date",
  "txtEnglish": "English",
  "txtEnterpriseDesc1": "Minimum 100 profiles per month",
  "txtEnterpriseDesc2": " for more info.",
  "txtEnterpriseSentDesc1": "Thank you for choosing the Enterprise plan. Our Sales team will contact you as soon as possible and configure the service following your requirement. \n",
  "txtEnterpriseSentDesc2": "In the meantime, please add a payment method and complete your billing information, it is required to enable your plan",
  "txtErrorUnsupportFile": "Unsupported file",
  "txtEthAddress": "Ethereum address",
  "txtEthereumAddress": "Ethereum address",
  "txtEvent": "Event",
  "txtExample": "Example",
  "txtExcept": "except",
  "txtExcluded": "Excluded",
  "txtExcludedCountries": "excluded countries",
  "txtExpiryDate": "Expiry Date",
  "txtExploreKyc": "Explore your KYC Connect Dashboard. ",
  "txtExportServiceConfig": "Export service config",
  "txtFaceMatch": "Face Match",
  "txtFaceMatchCheck": "Face Match check",
  "txtFaceMatchDesc1": "Blockpass compares the picture on the user's ID with the live selfie requested during registration.",
  "txtFaceMatchDesc2": "This check requires users to provide their Identity document and a live selfie.",
  "txtFacematchCheck": "Facematch Check",
  "txtFailedToSendMemberInvitation": "Failed to send member invitation",
  "txtFamilyName": "Family name",
  "txtFaq": "FAQ",
  "txtFileNotSupport": "This file is not supported",
  "txtFillCompanyInfo": "Fill info based on an existing company below or add new company.",
  "txtFlowAddress": "Flow (FLOW) address",
  "txtFlowDebugger": "Flow debugger",
  "txtFlowDebuggerDesc": "Search flows by Flow ID. Flows can include multiple checks",
  "txtFlowsConfig": "Flows config",
  "txtFlowsConfigDesc": "Configure checks defining the flow",
  "txtForbidden": "You do not have permission to perform this action",
  "txtForceAddOperator": "Add operator to service",
  "txtForceAddOperatorDesc": "Service owners did not allow Blockpass, double check that you have authorization to assign operators",
  "txtFreePlanDescription": "You receive unverified identities. Limited to 10 per day.",
  "txtFreeTrial": "Free Trial",
  "txtFrench": "French",
  "txtFunctionUnavailable": "Function temporarily unavailable",
  "txtGeneralInfomation": "General infomation",
  "txtGeoRestriction": "Geographic restrictions",
  "txtGetServiceDisplayFailed": "Get services display fail.",
  "txtGetServiceListFailed": "Get service list fail.",
  "txtGetServiceWebhookListFailed": "Get services webhooks fail.",
  "txtGivenName": "Given name",
  "txtGoToDashboard": "GO TO DASHBOARD",
  "txtGoToServiceDashboard": "Go to service dashboard",
  "txtGoToServiceList": "LATER",
  "txtGotoDashboardDesc1": "Go to the KYC Connect dashboard for this service.",
  "txtGotoDashboardDesc2": "The dashboard is where your team can review the Identities uploaded to your service by individuals.",
  "txtHelp": "Help",
  "txtIdEngine": "ID Engine",
  "txtIdEngineDesc": "Test ID Engine OCR tool",
  "txtIdentityDesc1": "Blockpass verifies Passports, National ID cards and Driving Licenses. ",
  "txtIdentityDesc2": "Users are requested to take a live picture of their document.",
  "txtIdentityDesc3": "This option requires users to provide their Identity document, full name and date of birth.",
  "txtIdentityDocument": "Identity Document",
  "txtIdentityDocumentCheck": "Identity Document check",
  "txtIdentityLink1": "See list of supported countries",
  "txtImageTooBig": "Image is too big",
  "txtImgDimensionBetween10242048": "Please upload image between 512x512 & 2048x2048 pixel",
  "txtImgDimensionRule": "PNG, JPEG. Min 512x512 pixel,   max 2048x2048 pixel.",
  "txtImpactChanging": "Impact charging",
  "txtInactive": "Inactive",
  "txtIncludeRawDataExceptPictureTooltip": "Return metadata and identity attributes except pictures",
  "txtIncludeRawDataIncludePictureTooltip": "Return metadata and identity attributes including pictures",
  "txtIncludeRawDataTooltip": "Return metadata and identity attributes except pictures",
  "txtIncluding": "including",
  "txtIncorrectFormat": "Field should only contain normal / UPPER characters, numbers, and underscore (_)",
  "txtInfomation": "Information",
  "txtInputYourServiceName": "Input your service name",
  "txtInternalServerError": "An unexpected error happened on server",
  "txtInvalidEmailFormat": "Invalid email format",
  "txtInvalidEmails": "Invalid emails",
  "txtInvalidParams": "Request contain invalid parameters, please recheck your input",
  "txtInvalidURL": "URL is invalid",
  "txtInvalidUrl": "This URL is invalid",
  "txtInvitationRemoved": "Invitation has been removed",
  "txtInvitationResent": "Invitation is resent",
  "txtInvitationSent": "Invitation has been sent to",
  "txtInviteAlreadySent": "Invite already sent",
  "txtInviteUser": "Invited user",
  "txtIsCopiedToClipboard": "is copied to clipboard.",
  "txtIsSuccess": "Success",
  "txtJapanese": "Japanese",
  "txtJobId": "Job Id",
  "txtKYCBot": "KYC Bot",
  "txtKYCDashboardAccess": "KYC Dashboard access",
  "txtKybDashboard": "KYB Dashboard",
  "txtKybService": "KYB Service",
  "txtKybServiceDesc": "KYB stands for \"Know Your Business\". Create a KYB service to verify the identities of businesses.",
  "txtKybServiceName": "KYB Service name",
  "txtKybServices": "KYB Services(WIP)",
  "txtKycDashboard": "KYC Dashboard",
  "txtKycService": "KYC Service",
  "txtKycServiceDesc": "KYC stands for \"Know Your Customer\". Create a KYC service to verify the identities of individuals.",
  "txtKycServiceName": "KYC Service name",
  "txtKycServices": "KYC Services",
  "txtLast180Days": "Last 180 days",
  "txtLast30Days": "Last 30 days",
  "txtLast7Days": "Last 7 days",
  "txtLast90Days": "Last 90 days",
  "txtLastMonth": "Last month",
  "txtLastRunAt": "Last run at",
  "txtLatestInvoice": "Latest invoice",
  "txtLatestInvoiceDesc": "Invoices are sent to the Billing owner email address every month. You can also download the last issued invoice below.",
  "txtLearnMoreAboutApiKey": "Learn more about Blockpass API ",
  "txtLengthTooShort": "Field length is too short",
  "txtLink": "Link",
  "txtListDeleteEmail": "List of emails you want to delete, separated by commas, up to 50 emails",
  "txtListOfServices": "List of services",
  "txtListingRunningCheck": "Listing Running Check",
  "txtListingRunningCheckDesc": "Listing Running Check desciption",
  "txtLive": "Live",
  "txtLockUser": "Locked user",
  "txtLogin": "Log in",
  "txtLogo": "Logo",
  "txtLogoDesc": "PNG, JPEG, JPG. \nMin 512x512 px, max 2048x2048 px",
  "txtLogoRestriction": "Image size must be between 1024 x 1024 and 2048 x 2048 pixels",
  "txtLogoSizeError": "The logo you just uploaded is not the correct size.",
  "txtLogout": "Logout",
  "txtLogs": "Logs",
  "txtLongDescription": "Long description",
  "txtLongDescriptionTooltip": "Full description of your service (unformatted text only)",
  "txtMONTH": "Month",
  "txtMarkerPlace": "Marketplace",
  "txtMarkerPlaceDesc": "Manage marketplace entries",
  "txtMarketPlace": "Marketplace - WIP",
  "txtMarketplace": "Marketplace",
  "txtMarketplaceSettingUpdateSuccess": "Marketplace settings updated successfully.",
  "txtMaticAddress": "Polygon (Matic) address",
  "txtMaxEmailsExceeded": "Maximum 50 emails per deletion",
  "txtMemberInfomation": "Member information",
  "txtMetaDataOnlyTooltip": "Return only metadata (profile status, IDs,...)",
  "txtMore": "More",
  "txtMoreDetails": "More details",
  "txtMoreInfomation": "More infomation",
  "txtName": "Name",
  "txtNameOfCompany": "Name of company",
  "txtNameOfCompanyIsRequired": "Name of company is required",
  "txtNameOnCard": "Name on card",
  "txtNameOnCardIncomplete": "The name on card is incomplete.",
  "txtNameRepresentative": "Name of Company representative",
  "txtNationalID": "National ID Card",
  "txtNationalId": "National ID",
  "txtNewCompany": "New company",
  "txtNewsContent": "News content",
  "txtNext": "Next",
  "txtNoActivityLogs": "No activities match your search",
  "txtNoActivityLogsDesc": "Try being less specific or using different keywords.",
  "txtNoApiKeyDesc": "An API key is required to extract individuals’ identities, including metadata, personal info, and images. ",
  "txtNoApiKeys": "No API keys",
  "txtNoCompanyAdd": "You haven't added any Company information yet",
  "txtNoCompanyAddDesc": "Click below to add the first Company information.",
  "txtNoCountryRestriction": "No country. Use the search to add a country to the list",
  "txtNoData": "No records found, try broaden your query!",
  "txtNoDataMemberInService": "No operators added, please add operator to this service first! ",
  "txtNoFieldsAreUpdated": "No fields are updated",
  "txtNoInvoiceDesc1": "Invoices are sent to the Billing owner email address every month. ",
  "txtNoInvoiceDesc2": "No issued invoice to download now.",
  "txtNoServiceMatch": "No services match your search",
  "txtNoServiceMatchDesc": "Try being less specific or using different keywords.",
  "txtNoSetupCost": "No setup cost",
  "txtNoUsageDetail": "No data to display",
  "txtNoUsageDetailDesc": "There are currently no statistics available.",
  "txtNone": "None",
  "txtNoneApprovalDesc": "Manual: ",
  "txtNoneApprovalDesc1": "Approve or reject each user manually.",
  "txtNormalDesc1": "Maximum 100 profiles per month.",
  "txtNotStoreCard": "Blockpass does not store your card details.",
  "txtNotifications": "Notifications",
  "txtNotificationsDesc": "Send push notifications to mobile users",
  "txtOnboarding": "Onboarding",
  "txtOnboardingGroup": "2. Onboarding",
  "txtOnboardingSetting": "Onboarding settings",
  "txtOnly": " only ",
  "txtOpenDashboard": "Open dashboard",
  "txtOperatorAssignment": "Operator Assignment",
  "txtOperatorAssignmentKYC": "Operator Assignment",
  "txtOperatorList": "Operator List",
  "txtOverview": "Overview",
  "txtPOA": "Proof of Address",
  "txtPage403Desc1": "You don't have permission to\n access this service",
  "txtPage403Desc2": "Please return to Services and find the right service or contact the service owner.",
  "txtParametersValidationError": "Request contain invalid parameters, please recheck your input",
  "txtPassport": "Passport",
  "txtPassword": "Password",
  "txtPayAsYouGo": "Pay as you go",
  "txtPaymentMethod": "Payment method",
  "txtPaymentMethodDeleted": "Payment method cannot be retrieved or was deleted. ",
  "txtPermissionDenied": "You do not have permission to perform this action",
  "txtPersonalAtt": "Personal attributes",
  "txtPersonalAttributes": "Personal attributes",
  "txtPhoneNumber": "Phone number",
  "txtPhoneNumberWrongFormat": "Phone number must be digits",
  "txtPhoneRepresentative": "Phone number of Company representative",
  "txtPickYourServiceName": "Pick a name for your service",
  "txtPlan": "Plan",
  "txtPlanConfig": "Plans config",
  "txtPlanConfigDesc": "the process of sending people bills asking them to pay money owed",
  "txtPlanDescription1": "Less than @@num_ident@@ identities per day and per business.\n",
  "txtPlanDescription2": "Processing time more than 24 hours. Standard ",
  "txtPleaseCopyAPIKey": "Please copy the API key",
  "txtPleaseReplacePaymentMethod": "Please replace payment method.",
  "txtPleaseSelectOperator": "Please select operator.",
  "txtPleaseSelectUser": "Please select user.",
  "txtPostalCodeWrongFormat": "Format is wrong.",
  "txtPrivacy": "Privacy",
  "txtPrivacyPolicy": "Privacy policy",
  "txtProfile": "Profile",
  "txtPromote": "Promote",
  "txtPromoteBpOperator": "Promote",
  "txtPromoteOperator": "Add Operator",
  "txtPromoteOperatorSuccess": "Promote operator sussessfully.",
  "txtProofOfAddress": "Proof of Address",
  "txtProvideNameOfService": "Provide a name for your service",
  "txtPublicLinkDesc": "Anyone on the internet with the link can submit their KYC profile.",
  "txtPublishService": "Published service",
  "txtPushNotification": "Push notification",
  "txtRedirect": "Redirect",
  "txtRemove": "Remove",
  "txtRemoveAccess": "Remove access",
  "txtRemoveBlockpassAccess": "Remove Blockpass access",
  "txtRemoveBlockpassAccessDesc": "Are you sure you want to remove Blockpass access? This will prevent Blockpass support team from accessing your KYC dashboard.",
  "txtRemoveBpOperator": "Remove",
  "txtRemoveInvitation": "Remove invitation",
  "txtRemoveOperator": "Remove operator",
  "txtRemoveOperatorDesc": "Are you sure you want to remove this operator?",
  "txtRemoveOperatorSuccess": "Remove operator successfully.",
  "txtRemoveTagConfirmContent": "Are you sure you want to remove this tag?",
  "txtRemoveTagConfirmTitle": "Remove \"<0>” tag",
  "txtRemoveTagSuccess": "”<0>” tag has been removed.",
  "txtRemoveThisMember": "Remove this member",
  "txtRemoveUser": "Removed user",
  "txtReplace": "Replace",
  "txtReplacePaymentMethod": "Replace payment method",
  "txtReplacePaymentMethodDesc1": "The new card will be used for all your services ",
  "txtReplacePaymentMethodDesc2": "Note, payment method changes will affect many services that use this \npayment method.",
  "txtReport": "Report",
  "txtRepresentative": "Representative",
  "txtRequestAccess": "Request access",
  "txtRequestEnterprise": "Request for Enterprise plan",
  "txtRequestEnterpriseSent": "Request for Enterprise plan sent!",
  "txtRequired": "* required",
  "txtRequiredOneIdentityDocument": "Please select at least one identity document",
  "txtRequirementPlaceHolder": "Tell us more about your requirements and timeline",
  "txtResAddress": "Residential Address",
  "txtResend": "Resend",
  "txtResidentialAddress": "Residential address",
  "txtRestricted": "Restricted",
  "txtRestrictedDesc1": "Only people authenticated on your website can register.",
  "txtRestrictedDesc2": "Select this option if you want to submit the user refId to the widget.",
  "txtRestrictedDesc3": "Please input the url where the widget is installed on your website.",
  "txtRestrictedDescLink": "More info in the documentation.",
  "txtRestrictedLinkDesc1": "Force users log in to your website before they can submit their KYC profile.",
  "txtRestrictedLinkDesc2": "Select this option if you want to send the user refId to the widget. ",
  "txtRestrictedLinkDesc3": "More info in the documentation.",
  "txtReturnAttributeData1": "Return metadata and identity attributes",
  "txtReturnAttributeData2": "pictures",
  "txtReturnAttributeDataExceptPicture1": "Return metadata and identity attributes",
  "txtReturnAttributeDataExceptPicture2": "pictures",
  "txtReturnMetaData1": "Return",
  "txtReturnMetaData2": "metadata (profile status, IDs,...)",
  "txtReturnToServices": "Return to services",
  "txtRevoke": "Revoke",
  "txtRevokeOperator": "Revoke operator",
  "txtRevokeOperatorDesc": "Are you sure you want to revoke this operator?",
  "txtRevokeOperatorSuccess": "Revoke operator successfully.",
  "txtRole": "Role",
  "txtRuleArchiveTime": "A number from 1 to 9999",
  "txtRunningFlowConfig": "Running Flow config",
  "txtRunningFlowConfigDesc": "Running Flow config desciption",
  "txtRuntimeConfig": "Runtime config",
  "txtRuntimeConfigDesc": "Configure console and dashboard parameters",
  "txtRussian": "Russian",
  "txtSAVE": "SAVE",
  "txtSampleCode": "Sample code",
  "txtSave": "Save",
  "txtSaveChange": "Save changes",
  "txtSaveChanges": "Save changes",
  "txtScheduleDeleteProfilesSuccess": "Schedule delete profiles successfully",
  "txtScheduleForBulkDeletion": "Schedule for bulk deletion",
  "txtScope": "Scope",
  "txtSearchActivityLogs": "Search by Username/email",
  "txtSearchByEmail": "Search by email",
  "txtSearchByNameOrClientId": "Search by name or client ID",
  "txtSearchCountry": "search country",
  "txtSearchPlaceHolder": "Search...",
  "txtSeconds": "second(s)",
  "txtSeeList": "See list.",
  "txtSelectACryptoAddress": "Select a crypto address",
  "txtSelectAPlan": "Select a plan",
  "txtSelectAPlanToUnlock": "UPGRADE YOUR PLAN",
  "txtSelectCryptoAddress": "Please select a crypto address.",
  "txtSelectIdentityAttributes": "Selected Identity attributes",
  "txtSelectPlan": "Select plan",
  "txtSelfie": "Selfie",
  "txtSendInvite": "Send invite",
  "txtSendInviteEmail": "Invite sent! It is valid for 3 days",
  "txtServiceAccountant": "Accountant",
  "txtServiceAccountantTooltip": "Can only view the Usage tab and Plan settings in the Admin Console.",
  "txtServiceAdmin": "Service admin",
  "txtServiceAllowBlockpassAccess": "Allow Blockpass access",
  "txtServiceBillingOwner": "Billing Owner",
  "txtServiceBillingOwnerTooltip": "All permissions in console and dashboard. Can update company information and payment method.",
  "txtServiceClientIdIsExisted": "Client Id already exists, please use a different client Id",
  "txtServiceCompanyInfomation": "’s company information",
  "txtServiceDetail": "Service detail",
  "txtServiceEmailIsRequired": "Support email is required",
  "txtServiceListInvalidPage": "Invalid paging value",
  "txtServiceLogo": "Service logo",
  "txtServiceLogoDesc": "You can upload a logo for your service now or update it later in Settings.",
  "txtServiceName": "Service name",
  "txtServiceNameIsRequired": "Service name is required",
  "txtServiceNameOrClientId": "Service name or client ID",
  "txtServiceNameOrClientIdPlaceholder": "Enter service name or client ID",
  "txtServiceNameTooltip": "Choose a name for your service (max 64 characters)",
  "txtServiceNotFound": "Service not found.",
  "txtServiceOwner": "Manager",
  "txtServiceOwnerTooltip": "All permissions in console and dashboard.",
  "txtServicePortalIsRequired": "Support portal is required",
  "txtServiceReadOnlyRole": "Read-only",
  "txtServiceReadOnlyRoleTooltip": "Can only view profiles. Cannot process KYC or update settings",
  "txtServiceReviewerTooltipkyc": "Can only process KYC. Cannot approve, delete profiles or update settings",
  "txtServiceReviewerkyc": "KYC Reviewer",
  "txtServiceTags": "Service tags",
  "txtServiceTagsDes": "Add or remove any tags for this service",
  "txtServices": "Services",
  "txtServicesUsage": "Recent services usage",
  "txtServicesUsageDesc": "the process of sending people bills asking them to pay money owed",
  "txtSettings": "Settings",
  "txtShareableDesc": "This page allows applicants to send their KYC info to your dashboard. Copy and paste the link in an email or any place you want to share it.",
  "txtShareableLink": "Shareable link",
  "txtShareableLinkMustBeSetIfUsingRestrictedShareableMethod": "Missing shareable link",
  "txtShortDescription": "Short description",
  "txtShortDescriptionTooltip": "A short text describing your service (unformatted text only)",
  "txtShowLess": "Show less",
  "txtShowMore": "Show more",
  "txtShowRefId": "Show refid",
  "txtShowSession": "Show session",
  "txtShowThisService": "Show this service",
  "txtShowThisServiceDes": "Turn on or off this service to display it on the marketplace.",
  "txtSolanaAddress": "Solana (SOL) address",
  "txtStartDate": "Start date",
  "txtStartingAt": "Starting at",
  "txtStartingAtTooltip": "Additional information not available.",
  "txtStatus": "Status",
  "txtStringMaxLengthError": "Must be <0> or fewer characters long",
  "txtStripeError": "Something went wrong when verifying your billing information. Please contact us for support.",
  "txtSubscribe": "Subscribe",
  "txtSubscribeTo": "Subscribe to our Newsletter",
  "txtSubscribed": "Subscribed",
  "txtSubscription": "Subscription",
  "txtSupport": "Support",
  "txtSupportPortal": "URL of your Support web portal",
  "txtSupportPortalTooltip": "URL of your customer service portal website",
  "txtSupportrEmail": "Your Support email",
  "txtSupportrEmailTooltip": "Customer support email address. This address is used if you do not provide a support portal url. Default address is support@blockpass.org",
  "txtTags": "Tag(s)",
  "txtTaxNumberWrongFormat": "Invalid format.",
  "txtTeam": "Team",
  "txtTestService": "Test service",
  "txtTheRequestWasMadeButNoResponseWasReceived": "Network error",
  "txtThisFieldIsRequired": "This field is required.",
  "txtTitleAllow": "Allowed countries",
  "txtTitleExclude": "Excluded countries",
  "txtToGetStart": "To get started, please log in.",
  "txtToday": "Today",
  "txtTools": "Tools",
  "txtTooltipAddressCountryRestriction": "Address is required if Country restrictions are enabled",
  "txtTooltipAddressProofOfAddress": "Address is required if Proof of address is selected",
  "txtTotal": "Total",
  "txtTotalForMonth": "Total for month ",
  "txtTwoFaBannerDesc": "To protect your data, we now require Two-Factor Authentication (2FA). You can enable it now or you'll be asked to enable it at your next login.",
  "txtTwoFaBannerTitle": "Secure your account with 2FA",
  "txtTwoFaButtonEnableNow": "Enable 2FA now",
  "txtTypeSomthing": "Type something...",
  "txtUSDperCheck": "USD/check",
  "txtUnAuthorized": "Session is expired or invalid, please login again",
  "txtUnauthorized": "Session is expired or invalid, please login again",
  "txtUnlimittedCollaborator": "Unlimited collaborators",
  "txtUnsubscribe": "Unsubscribe",
  "txtUpdate": "Update",
  "txtUpdateAPIKey": "Update API Key",
  "txtUpdateApiKey": "Update API key",
  "txtUpdateApiKeySuccess": "API key updated",
  "txtUpdateApproveMode": "Update Approve Mode",
  "txtUpdateBillingPlanDisplay": "Update Billing Plan Display",
  "txtUpdateBillingPlanKycBot": "Update KYC Bot configuration",
  "txtUpdateBillingPlanVerification": "Update KYC Check configuration",
  "txtUpdateBpOperatorRole": "Change role",
  "txtUpdateCertificate": "Updated certificate",
  "txtUpdateCheckConfig": "Update Check Config",
  "txtUpdateCompany": "Update company",
  "txtUpdateCompanyInfoDesc": "Update the company information.",
  "txtUpdateEnterpriseDesc1": "Free storage of personal data",
  "txtUpdateFlowConfig": "Update Flow Config",
  "txtUpdateFreePlanDescription": "You receive unverified identities. Data retention: 1 week",
  "txtUpdateIdentityField": "Updated identity field",
  "txtUpdateInfo": "Update information",
  "txtUpdateKYCSettings": "Updated KYC settings",
  "txtUpdateNormalDesc1": "Personal data retention: up to 4 weeks",
  "txtUpdateOperator": "Update operator",
  "txtUpdateOperatorSuccess": "Update operator successfully.",
  "txtUpdatePlanDescription1": "Personal data retention: up to 4 weeks",
  "txtUpdatePlanDescription2": "Processing time may vary. Standard ",
  "txtUpdatePlanSuccess": "Update Service Billing plan successfully",
  "txtUpdateRuntimeCfg": "Updated runtime configuration",
  "txtUpdateService": "Updated service",
  "txtUpdateServiceSuccess": "All changes saved",
  "txtUpdateWebhook": "Update Webhook",
  "txtUpdateWebhookSuccess": "Webhook updated",
  "txtUploadALogo": "Upload a logo ",
  "txtUrlPrivacyPolicy": "URL of your Privacy Policy",
  "txtUrlPrivacyPolicyTooltip": "A link to your Privacy Policy   will be displayed before user registration",
  "txtUrlTermsAndConditions": "URL of your Terms & Conditions",
  "txtUrlTermsAndConditionsTooltip": "A link to your Terms & Conditions will be displayed before user registration",
  "txtUsage": "Usage",
  "txtUsageMetrics": "Usage metrics",
  "txtUserDebugger": "User debugger",
  "txtUserDebuggerDesc": "Search users by email or supportId",
  "txtUserId": "User ID",
  "txtUserSessionExpired": "User session expired",
  "txtUserSessionExpiredDesc": "You have been logged out. Please log in again",
  "txtUsername": "Username",
  "txtUsernameOrEmail": "Username or email",
  "txtUsernameOrEmailPlaceholder": "Enter user name or email",
  "txtVATNumber": "VAT number",
  "txtVerification": "Verifications",
  "txtVerificationGroup": "1. Verifications",
  "txtVerifications": "Verifications",
  "txtViewDetails": "View details",
  "txtVisitThePage": "Visit the page",
  "txtWEEK": "Week",
  "txtWarningApprovalMode": "Please confirm the mode of approval before moving to the next step",
  "txtWavesAddress": "Waves address",
  "txtWebhooks": "Webhooks",
  "txtWebsite": "URL of your website",
  "txtWebsiteIntegration": "Widget integration",
  "txtWebsiteTooltip": "Link to your official website",
  "txtWebsiteUrlIsRequired": "Please fill out this field",
  "txtWelcomeBody": "Create, configure and monitor services to verify the identity of your customers.",
  "txtWelcomeTitle": "Welcome to Blockpass Admin Console",
  "txtWelcomeTo": "Welcome to",
  "txtWhoCanResgister": "Who can register?",
  "txtWillBeDeleted": "will be deleted. ",
  "txtYEAR": "Year",
  "txtYesterday": "Yesterday",
  "txtYourApiKey": "Your API key",
  "txtYourCard": "Your card",
  "txtYourCompanyInfo": "Your company information",
  "txtYourEmailAddress": "Your email address",
  "txtYourLink": "Your link",
  "txtYourLinkCopiedToClipboard": "Your link copied to clipboard",
  "txtYourPaymentMethod": "Your payment method",
  "txtYourPaymentMethodGroup": "4. Payment method",
  "txtYourServiceConfigCopiedToClipboard": "Your Service Config copied to clipboard",
  "txtYourServiceCreated": "Your service has been created",
  "txtYourServiceLink": "Your service link",
  "txtYourWebsite": "https://your-website.com",
  "txtYourWebsiteUrl": "Your Website URL",
  "txtYourWebsiteUrlDesc": "Please enter the website URL where you installed the widget",
  "txtdelete": "delete",
  "txtdonwloadMonitorReport": "Download Monitor Report",
  "txtsettingUpdated": "Setting Updated"
} // eslint-disable-line no-template-curly-in-string