import React from 'react'
import { useObserver } from 'mobx-react'
import makeStyles from '@mui/styles/makeStyles'
import { Typography, Pill } from '@blockpass-org/ui'
import { COLOR } from '@theme/colors'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Grow from '@mui/material/Grow'
import MuiDrawer from '@mui/material/Drawer'
import useRootStore from '@hooks/useRootStore'
import { styled, Theme, CSSObject } from '@mui/material/styles'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import Skeleton from '@mui/material/Skeleton'
import DashboardTooltip from '@components/DashboardTooltip'
import loadable from '@loadable/component'

const ServiceDetailTools = loadable(
  () => import('@/components/ServiceDetailTools')
)
const ServiceDetailMoreTools = loadable(
  () => import('@/components/ServiceDetailMoreTools')
)
const ServiceDetailMenu = loadable(
  () => import('@components/ServiceDetailMenu')
)
const ButtonCopy2 = loadable(() => import('@components/ButtonCopy2'))
const BlockpassSwitch = loadable(() => import('@components/BlockpassSwitch'))
// const DashboardTooltip = loadable(() => import('@components/DashboardTooltip/DashboardTooltip'))
const useStyles = makeStyles((theme) => ({
  containerOpen: {
    margin: 0,
    width: 280 - 24,
    padding: '16px 12px',
    backgroundColor: COLOR.commonColor.white,
    height: 'calc(100vh - 90px)',
    overflow: 'scroll',
  },
  container: {
    margin: 0,
    width: 72,
    textAlign: 'center',
    backgroundColor: COLOR.commonColor.white,
  },
  divider: {
    backgroundColor: '#000 !important',
    opacity: '0.2 !important',
    margin: '12px -12px !important',
  },
  dividerInvisible: {
    backgroundColor: '#000 !important',
    opacity: '0.0 !important',
    margin: '18px -12px !important',
  },
  dividerInvisible2: {
    backgroundColor: '#000 !important',
    opacity: '0.0 !important',
    margin: '20px -12px !important',
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '0px 16px',
  },
  flexTag: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '0px 16px',
    marginBottom: 8,
  },
  textOverflow: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}))

// custome drawer
const drawerWidth = 280

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  position: 'initial',
})

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `72px`,
  position: 'initial',
  // position: 'fixed',
  // top: 80
})

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  minHeight: 825,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}))

export const ServiceSideBar = (props: any) => {
  const classes = useStyles()
  const rootStore = useRootStore()
  const {
    LocalizationStore: { translate },
    DashboardStore,
  } = rootStore
  const viewStore = props.viewStore

  return useObserver(() => {
    // if select from servicelist -> has service info
    const { serviceInfo, openDrawer, isKYBService } = DashboardStore

    const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
      if (isKYBService) {
        await DashboardStore.updateKYBServiceStatus(event.target.checked)
      } else {
        await DashboardStore.updateServiceStatus(event.target.checked)
      }
    }

    // const isAllowUpdateStatus = DashboardStore.isAllowUpdateStatusService()
    // const isEnabled = DashboardStore.isServiceEnabled()
    // let toolTipContent = ''

    // if (!isAllowUpdateStatus || !isEnabled) {
    //   toolTipContent =
    //     'Please contact Sales department to change your Service status'
    // }

    let showSkeleton = false
    if (isEmpty(serviceInfo)) {
      showSkeleton = true
    }

    const objServiceName = document?.getElementById('service-name')
    let needTootipServiceName = false
    if (
      objServiceName &&
      objServiceName.scrollWidth > objServiceName.clientWidth
    ) {
      needTootipServiceName = true
    } else {
      needTootipServiceName = false
    }

    const objServiceClientId = document?.getElementById('service-clientid')
    let needTootipServiceClientId = false
    if (
      objServiceClientId &&
      objServiceClientId.scrollWidth > objServiceClientId.clientWidth
    ) {
      needTootipServiceClientId = true
    }

    const serviceTagObj = () => {
      if (isKYBService) {
        return (
          <Box className={classes.flexTag}>
            <Pill
              size="small"
              label={'KYB'}
              variant="outlined"
              type={'attention'}
            />
          </Box>
        )
      } else {
        return (
          <Box className={classes.flexTag}>
            <Pill
              size="small"
              label={'KYC'}
              variant="outlined"
              type={'success'}
            />
          </Box>
        )
      }
    }

    const serviceNameObj = () => {
      if (needTootipServiceName) {
        return (
          <DashboardTooltip title={get(serviceInfo, 'name', '')} arrow>
            <Box className={classes.flex}>
              <Typography
                id="service-name"
                className={classes.textOverflow}
                variant="subtitle1"
              >
                {get(serviceInfo, 'name', '')}
              </Typography>
            </Box>
          </DashboardTooltip>
        )
      } else {
        return (
          <Box className={classes.flex}>
            <Typography
              id="service-name"
              className={classes.textOverflow}
              variant="subtitle1"
            >
              {get(serviceInfo, 'name', '')}
            </Typography>
          </Box>
        )
      }
    }

    const serviceClientIdObj = () => {
      if (needTootipServiceClientId) {
        return (
          <Box className={classes.flex}>
            <DashboardTooltip title={DashboardStore.getClientId()} arrow>
              <Box style={{ width: 'calc(100% - 28px)' }}>
                <Typography
                  id="service-clientid"
                  className={classes.textOverflow}
                  variant="body2"
                  color="textSecondary"
                >
                  {DashboardStore.getClientId()}
                </Typography>
              </Box>
            </DashboardTooltip>
            <Box>
              <ButtonCopy2
                copyValue={DashboardStore.getClientId()}
                border={false}
                size={'28'}
                displayMessage={`${translate('txtClientId')} ${translate(
                  'txtIsCopiedToClipboard'
                )}`}
              />
            </Box>
          </Box>
        )
      } else {
        return (
          <Box className={classes.flex}>
            <Typography
              id="service-clientid"
              className={classes.textOverflow}
              variant="body2"
              color="textSecondary"
            >
              {DashboardStore.getClientId()}
            </Typography>
            <Box>
              <ButtonCopy2
                copyValue={DashboardStore.getClientId()}
                border={false}
                size={'28'}
                displayMessage={`${translate('txtClientId')} ${translate(
                  'txtIsCopiedToClipboard'
                )}`}
              />
            </Box>
          </Box>
        )
      }
    }

    return (
      <Drawer
        variant="permanent"
        open={openDrawer}
        style={{
          height: '100%',
          position: 'fixed',
          top: 80,
          left: 0,
          width: 'auto',
          zIndex: 100,
        }}
      >
        <Box className={openDrawer ? classes.containerOpen : classes.container}>
          {
            <>
              <Grow in={openDrawer} style={{ transformOrigin: 'left' }}>
                <Box style={{ height: !openDrawer ? 36 : 'auto' }}>
                  {showSkeleton ? (
                    <>
                      <Skeleton
                        sx={{ bgcolor: `${COLOR.primaryGreyScale[1300]}` }}
                        variant="rounded"
                        width={50}
                        height={24}
                        style={{ marginLeft: 16 }}
                      />
                    </>
                  ) : (
                    <>{serviceTagObj()}</>
                  )}
                  {showSkeleton ? (
                    <>
                      <Skeleton
                        sx={{ bgcolor: `${COLOR.primaryGreyScale[1300]}` }}
                        variant="rounded"
                        width={172}
                        height={24}
                        style={{ marginLeft: 16, marginTop: 2 }}
                      />
                    </>
                  ) : (
                    <>{serviceNameObj()}</>
                  )}

                  {showSkeleton ? (
                    <>
                      <Skeleton
                        sx={{ bgcolor: `${COLOR.primaryGreyScale[1300]}` }}
                        variant="rounded"
                        width={224}
                        height={20}
                        style={{ marginLeft: 16, marginTop: 2 }}
                      />
                    </>
                  ) : (
                    <>{serviceClientIdObj()}</>
                  )}

                  <Box className={classes.flex} style={{ marginTop: 16 }}>
                    {showSkeleton ? (
                      <>
                        <Skeleton
                          sx={{ bgcolor: `${COLOR.primaryGreyScale[1300]}` }}
                          variant="rounded"
                          width={43}
                          height={24}
                          style={{ marginLeft: 0, marginTop: 8 }}
                        />
                      </>
                    ) : (
                      <>
                        <Typography variant="body1">
                          {translate('txtLive')}
                        </Typography>
                      </>
                    )}
                    <Box>
                      {showSkeleton ? (
                        <>
                          <Skeleton
                            sx={{ bgcolor: `${COLOR.primaryGreyScale[1300]}` }}
                            variant="rounded"
                            width={52}
                            height={32}
                            style={{ marginTop: 8 }}
                          />
                        </>
                      ) : (
                        <>
                          <BlockpassSwitch
                            disabled={DashboardStore.isUpdatingLiveStatus}
                            onChange={handleChange}
                            name={'live'}
                            checked={get(serviceInfo, 'isLive', false)}
                          />
                        </>
                      )}
                    </Box>
                  </Box>
                </Box>
              </Grow>
              <Grow in={!openDrawer} style={{ transformOrigin: 'left' }}>
                <Box style={{ height: openDrawer ? 0 : 'auto' }}>
                  <BlockpassSwitch
                    disabled={DashboardStore.isUpdatingLiveStatus}
                    onChange={handleChange}
                    name={'live'}
                    checked={get(serviceInfo, 'isLive', false)}
                  />
                </Box>
              </Grow>
            </>
          }

          <Divider
            className={
              showSkeleton ? classes.dividerInvisible : classes.divider
            }
          />

          <Box style={{ margin: '0px 16px' }}>
            <Grow in={openDrawer} style={{ transformOrigin: 'left' }}>
              <Box style={{ height: !openDrawer ? 0 : 'auto' }}>
                {showSkeleton ? (
                  <>
                    <Skeleton
                      sx={{ bgcolor: `${COLOR.primaryGreyScale[1300]}` }}
                      variant="rounded"
                      width={63}
                      height={24}
                      style={{ marginLeft: 0 }}
                    />
                  </>
                ) : (
                  <>
                    <Typography
                      variant="subtitle2"
                      color="textSecondary"
                      style={{ paddingBottom: '8px' }}
                    >
                      {translate('txtSettings')}
                    </Typography>
                  </>
                )}

                <ServiceDetailMenu viewStore={viewStore} />
              </Box>
            </Grow>
            <Grow in={!openDrawer} style={{ transformOrigin: 'left' }}>
              <Box style={{ height: openDrawer ? 0 : 'auto' }}>
                <ServiceDetailMenu viewStore={viewStore} />
              </Box>
            </Grow>
          </Box>

          <Divider
            className={
              showSkeleton ? classes.dividerInvisible2 : classes.divider
            }
          />

          <Box style={{ margin: openDrawer ? '0px 16px' : '0px' }}>
            <Grow in={openDrawer} style={{ transformOrigin: 'left' }}>
              <Box style={{ height: !openDrawer ? 0 : 'auto' }}>
                {showSkeleton ? (
                  <>
                    <Skeleton
                      sx={{ bgcolor: `${COLOR.primaryGreyScale[1300]}` }}
                      variant="rounded"
                      width={63}
                      height={24}
                      style={{ marginLeft: 0 }}
                    />
                  </>
                ) : (
                  <>
                    <Typography
                      variant="subtitle2"
                      color="textSecondary"
                      style={{ paddingBottom: '8px' }}
                    >
                      {translate('txtTools')}
                    </Typography>
                  </>
                )}
                <ServiceDetailTools />
              </Box>
            </Grow>
            <Grow in={!openDrawer} style={{ transformOrigin: 'left' }}>
              <Box style={{ height: openDrawer ? 0 : 'auto' }}>
                <ServiceDetailTools />
              </Box>
            </Grow>
          </Box>

          <Divider
            className={
              showSkeleton ? classes.dividerInvisible2 : classes.divider
            }
          />

          <>
            <Grow in={openDrawer} style={{ transformOrigin: 'left' }}>
              <Box style={{ height: !openDrawer ? 0 : 'auto' }}>
                <ServiceDetailMoreTools />
              </Box>
            </Grow>
            <Grow in={!openDrawer} style={{ transformOrigin: 'left' }}>
              <Box style={{ height: openDrawer ? 0 : 'auto' }}>
                <ServiceDetailMoreTools />
              </Box>
            </Grow>
          </>
        </Box>
      </Drawer>
    )
  })
}

export default ServiceSideBar
