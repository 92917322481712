import {
  ACTIVITY_LOGS_KEY,
  COMPANY_KEY,
  KYB_SERVICE_DISPLAY_KEY,
  SERVICE_ADMIN_KEY,
  SERVICE_APIKEY_KEY,
  SERVICE_BILLING_KEY,
  SERVICE_DISPLAY_KEY,
  SERVICE_TEAM_KEY,
  SERVICE_USAGE_KEY,
  SERVICE_WEBHOOK_KEY,
} from '@/constants/constants'
import useRootStore from '@/hooks/useRootStore'
import MicroFrontendCache from '@/MicroFrontendCache'

export const PreloadMicroFrontend = () => {
  const rootStore = useRootStore()
  const DashboardStore = rootStore.DashboardStore
  const microObjLogs = DashboardStore.getMicroConfigDetail(ACTIVITY_LOGS_KEY)
  const hostLogs = (microObjLogs && microObjLogs?.host) || ''

  const microObjCompany = DashboardStore.getMicroConfigDetail(COMPANY_KEY)
  const hostCompany = (microObjCompany && microObjCompany?.host) || ''
  //service detail
  const microObj = DashboardStore.getMicroConfigDetail(SERVICE_DISPLAY_KEY)
  const host = (microObj && microObj?.host) || ''

  const microObjApi = DashboardStore.getMicroConfigDetail(SERVICE_APIKEY_KEY)
  const hostApi = (microObjApi && microObjApi?.host) || ''

  const microObjBilling =
    DashboardStore.getMicroConfigDetail(SERVICE_BILLING_KEY)
  const hostBilling = (microObjBilling && microObjBilling?.host) || ''

  const microObjTeam = DashboardStore.getMicroConfigDetail(SERVICE_TEAM_KEY)
  const hostTeam = (microObjTeam && microObjTeam?.host) || ''

  const microObjWebhook =
    DashboardStore.getMicroConfigDetail(SERVICE_WEBHOOK_KEY)
  const hostWebhook = (microObjWebhook && microObjWebhook?.host) || ''

  const microObjUsage = DashboardStore.getMicroConfigDetail(SERVICE_USAGE_KEY)
  const hostUsage = (microObjUsage && microObjUsage?.host) || ''

  const microObjKybDisplay = DashboardStore.getMicroConfigDetail(
    KYB_SERVICE_DISPLAY_KEY
  )
  const hostKybDisplay = (microObjKybDisplay && microObjKybDisplay?.host) || ''

  const microObjAdmin = DashboardStore.getMicroConfigDetail(SERVICE_ADMIN_KEY)
  const hostAdmin: string = (microObjAdmin && microObjAdmin?.host) || ''

  return (
    <>
      <MicroFrontendCache
        history={window.history}
        host={host}
        name={microObj?.id || ''}
        containerStore={{}}
      />
      <MicroFrontendCache
        history={window.history}
        host={hostLogs}
        name={microObjLogs?.id || ''}
        containerStore={{}}
      />
      <MicroFrontendCache
        history={window.history}
        host={hostCompany}
        name={microObjCompany?.id || ''}
        containerStore={{}}
      />
      <MicroFrontendCache
        history={window.history}
        host={hostApi}
        name={microObjApi?.id || ''}
        containerStore={{}}
      />
      <MicroFrontendCache
        history={window.history}
        host={hostTeam}
        name={microObjTeam?.id || ''}
        containerStore={{}}
      />
      <MicroFrontendCache
        history={window.history}
        host={hostBilling}
        name={microObjBilling?.id || ''}
        containerStore={{}}
      />
      <MicroFrontendCache
        history={window.history}
        host={hostWebhook}
        name={microObjWebhook?.id || ''}
        containerStore={{}}
      />
      <MicroFrontendCache
        history={window.history}
        host={hostUsage}
        name={microObjUsage?.id || ''}
        containerStore={{}}
      />
      <MicroFrontendCache
        history={window.history}
        host={hostKybDisplay}
        name={microObjKybDisplay?.id}
        containerStore={{}}
      />
      <MicroFrontendCache
        history={window.history}
        host={hostAdmin}
        name={microObjAdmin?.id}
        containerStore={{}}
      />
    </>
  )
}
