import React, { useEffect } from 'react'

import get from 'lodash/get'
// Store
import StoreProviderWrapper from './providers/StoreProviderWrapper'
import rootStore from './stores/configuedStore'

// Router
import RouterProviderWrapper from './providers/RouterProviderWrapper'


// UI Ext
// import UISnackbarProviderWrapper from './providers/UISnackbarProviderWrapper'
// import UILoadingProviderWrapper from './providers/UILoadingProviderWrapper'
import UILayoutProviderWrapper from './providers/UILayoutProviderWrapper'
import { UINotificationProvider } from './providers/UINotificationProvider'

// import ThemeProviderWrapper from '@providers/ThemeProviderWrapper'
import { ThemeProvider } from '@blockpass-org/ui'
import PageRoutes from './pages/routes'
import Layout from './layout'
import PageRouteContainer from './layout/PageRoute';
// Assets
import ReactGA from "react-ga4";
import TagManager from 'react-gtm-module'

import { StylesProvider, createGenerateClassName } from '@mui/styles';


window.onunhandledrejection = (e: PromiseRejectionEvent) => {
  console.error('Error', e);
  throw new Error(e.reason.stack);
}
// eslint-disable-next-line
export const App: React.FC = ({ children }) => {

  const generateClassName = createGenerateClassName({
    productionPrefix: 'dashboard',
    seed: 'dashboard'
  });

  return (
    <StylesProvider generateClassName={generateClassName}>
      <ThemeProvider>
        <StoreProviderWrapper store={rootStore}>

          <RouterProviderWrapper>
            <Layout>
              <PageRoutes
                PageContainerComp={PageRouteContainer || React.Fragment}
              />
              <UILayoutProviderWrapper />
            </Layout>
          </RouterProviderWrapper>
          <UINotificationProvider />
          {/* <UISnackbarProviderWrapper /> */}
        </StoreProviderWrapper>
      </ThemeProvider >
    </StylesProvider >
  )
}
// }

const getFavIcon = () => {
  const blockpassEnv = get(window, 'env.BLOCKPASS_ENV') as string
  let env = blockpassEnv
  switch (blockpassEnv) {
    case 'production':
    case 'prod': {
      env = blockpassEnv
      break
    }
    case 'stag':
    case 'staging':
      {
        env = 'sandbox'
        break
      }

    case 'qa': {
      env = blockpassEnv
      break
    }

    default: {
      env = blockpassEnv
      break
    }
  }
  const iconPath = `${process.env.PUBLIC_URL}/${env}.png`
  return iconPath

}


const withSession = (Component: React.ComponentType<any>) => (props: any) => {

  useEffect(() => {
    // fav icon
    const icon = getFavIcon()
    let headTitle = document.querySelector('head')
    let setFavicon = document.createElement('link')
    setFavicon.setAttribute('rel', 'shortcut icon')
    setFavicon.setAttribute('href', icon)
    headTitle && headTitle.appendChild(setFavicon)

    let setFaviconA = document.createElement('link')
    setFaviconA.setAttribute('rel', 'apple-touch-icon')
    setFaviconA.setAttribute('href', icon)
    headTitle && headTitle.appendChild(setFaviconA)

    const gaID = get(window, 'env.GA_ID', 'G-9H7N0RCE5N') as string //default -> send to QA
    ReactGA.initialize(gaID)

    // init GTM
    const tagManagerArgs = {
      gtmId: get(window, 'env.GTM_ID', ''),
      auth: get(window, 'env.GTM_AUTH', ''),
      preview: get(window, 'env.GTM_PREVIEW', ''),
    }
    TagManager.initialize(tagManagerArgs)

    //zenDesk
    const url = get(window, 'env.ZENDESK_WIDGET_URL', '')
    if (url) {
      let beacon = document.getElementById('ze-snippet')
      if (!beacon) {
        beacon = document.createElement('script')
        beacon.setAttribute('type', 'text/javascript')
        beacon.setAttribute('id', 'ze-snippet')
        beacon.setAttribute('async', 'true')
        beacon.setAttribute('src', url)
        document.body.appendChild(beacon)
      }
    }

  })
  return <Component {...props} />
}

export default withSession(App)


