import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

export const ErrorIcon = props => (
  <SvgIcon width="32" height="32" viewBox="0 0 32 32" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 2.66671C23.3638 2.66671 29.3333 8.63624 29.3333 16C29.3333 23.3638 23.3638 29.3334 16 29.3334C8.63616 29.3334 2.66663 23.3638 2.66663 16C2.66663 8.63624 8.63616 2.66671 16 2.66671ZM17.3007 20.2696H14.6666V22.6667H17.3007V20.2696ZM17.3007 19.0847H14.6666V9.14977H17.3007V19.0847Z"
    />
  </SvgIcon>
)

export const QuestionIcon = props => (
  <SvgIcon width="32" height="32" viewBox="0 0 32 32" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 29.3334C23.3638 29.3334 29.3333 23.3638 29.3333 16C29.3333 8.63622 23.3638 2.66669 16 2.66669C8.63616 2.66669 2.66663 8.63622 2.66663 16C2.66663 23.3638 8.63616 29.3334 16 29.3334ZM14.9635 21.8333H17.0364V24H14.9635V21.8333ZM12.6302 9.86982C11.7829 10.783 11.3593 11.9896 11.3593 13.4896H13.2135C13.2482 12.5868 13.3906 11.8959 13.6406 11.4167C14.085 10.5556 14.8871 10.125 16.0468 10.125C16.9843 10.125 17.6562 10.375 18.0625 10.875C18.4687 11.375 18.6718 11.9653 18.6718 12.6459C18.6718 13.132 18.5329 13.6007 18.2552 14.0521C18.1024 14.3091 17.901 14.5556 17.651 14.7917L16.8177 15.6146C16.0191 16.3993 15.5017 17.0955 15.2656 17.7031C15.0295 18.3108 14.9114 19.1111 14.9114 20.1042H16.7656C16.7656 19.2292 16.8645 18.5677 17.0625 18.1198C17.2604 17.6719 17.6927 17.125 18.3593 16.4792C19.276 15.5903 19.8854 14.9167 20.1875 14.4584C20.4895 14 20.6406 13.4028 20.6406 12.6667C20.6406 11.4514 20.2291 10.4532 19.4062 9.6719C18.5833 8.89065 17.4878 8.50003 16.1197 8.50003C14.6406 8.50003 13.4774 8.95662 12.6302 9.86982Z"
    />
  </SvgIcon>
)

export const WarningIcon = props => (
  <SvgIcon width="32" height="32" viewBox="0 0 32 32" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 4.15332L29.3333 27.2473H2.66663L16 4.15332ZM14.8899 13.4796H17.1103V20.1407H14.8899V13.4796ZM17.1103 21.2503H14.8899V23.4707H17.1103V21.2503Z"
    />
  </SvgIcon>
)

export const SuccessIcon = props => (
  <SvgIcon width="32" height="32" viewBox="0 0 32 32" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29.3333 16C29.3333 23.3638 23.3638 29.3333 16 29.3333C8.63616 29.3333 2.66663 23.3638 2.66663 16C2.66663 8.63616 8.63616 2.66663 16 2.66663C23.3638 2.66663 29.3333 8.63616 29.3333 16ZM11.1975 14.8792L13.5757 17.2438L20.8007 10.0124L22.6666 11.9267L13.5757 21.0176L9.3333 16.7751L11.1975 14.8792Z"
    />
  </SvgIcon>
)

export const CloseIcon = props => (
  <SvgIcon width="40" height="40" viewBox="0 0 40 40" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.9999 22.8215L9.51811 16.3397L8.3396 17.5182L14.8214 24L8.33961 30.4818L9.51812 31.6603L15.9999 25.1785L22.4817 31.6603L23.6602 30.4818L17.1784 24L23.6603 17.5182L22.4817 16.3397L15.9999 22.8215Z"
    />
  </SvgIcon>
)


