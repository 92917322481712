import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { Typography, Button } from "@blockpass-org/ui";
import useRootStore from '@hooks/useRootStore'
import makeStyles from '@mui/styles/makeStyles';
import { Page403Icon } from '@components/Icon/CommonIcons'
import { COLOR } from '@theme/colors'
import {
  KYB_SERVICE_DISPLAY_KEY, SERVICE_DISPLAY_KEY, ACTIVITY_LOGS_KEY, COMPANY_KEY, SERVICE_ADMIN_KEY,
  SERVICE_APIKEY_KEY, SERVICE_BILLING_KEY, SERVICE_TEAM_KEY, SERVICE_WEBHOOK_KEY, SERVICE_USAGE_KEY
} from '@constants/constants'
import MicroFrontendCache from '../../MicroFrontendCache'
const useStyles = makeStyles((theme) => ({
  icon: {
    marginBottom: 16,
  },
  bold: {
    fontWeight: 500,
    maxWidth: 400,
    margin: 'auto !important',
    lineHeight: '32px  !important'
  },
  normal: {
    fontWeight: 400,
    maxWidth: 400,
    margin: '8px auto auto auto !important',
    lineHeight: '24px  !important'
  },
  table: {
    display: 'table',
    width: 'calc(100% -  64px)',
    height: 'calc(100vh - 144px)',
    padding: 32,
    backgroundColor: '#F2F2F2'
  },
  tableCell: {
    display: 'table-cell',
    textAlign: 'center',
    verticalAlign: 'middle',
    backgroundColor: COLOR.commonColor.white,
    borderRadius: 8
  },
  button: {
    // width: '248px',
    height: 40,
    marginTop: '16px !important'
  }
}))


const Page403 = observer(function Page403() {
  const classes = useStyles()
  const rootStore = useRootStore();
  const {
    LocalizationStore: { translate },
    RouteStore,
    DashboardStore
  } = rootStore

  useEffect(() => {
    DashboardStore.hideFooter = true
  })

  const onClickGoToServiceList = async () => {
    // to service list
    RouteStore.push('/services')

  }

  //cache
  const microObjLogs = DashboardStore.getMicroConfigDetail(
    ACTIVITY_LOGS_KEY
  )
  const hostLogs = (microObjLogs && microObjLogs?.host) || ''

  const microObjCompany = DashboardStore.getMicroConfigDetail(
    COMPANY_KEY
  )
  const hostCompany = (microObjCompany && microObjCompany?.host) || ''
  //service detail
  const microObj = DashboardStore.getMicroConfigDetail(
    SERVICE_DISPLAY_KEY
  )
  const host = (microObj && microObj?.host) || ''

  const microObjApi = DashboardStore.getMicroConfigDetail(
    SERVICE_APIKEY_KEY
  )
  const hostApi = (microObjApi && microObjApi?.host) || ''

  const microObjBilling = DashboardStore.getMicroConfigDetail(
    SERVICE_BILLING_KEY
  )
  const hostBilling = (microObjBilling && microObjBilling?.host) || ''

  const microObjTeam = DashboardStore.getMicroConfigDetail(
    SERVICE_TEAM_KEY
  )
  const hostTeam = (microObjTeam && microObjTeam?.host) || ''

  const microObjWebhook = DashboardStore.getMicroConfigDetail(
    SERVICE_WEBHOOK_KEY
  )
  const hostWebhook = (microObjWebhook && microObjWebhook?.host) || ''

  const microObjUsage = DashboardStore.getMicroConfigDetail(
    SERVICE_USAGE_KEY
  )
  const hostUsage = (microObjUsage && microObjUsage?.host) || ''

  const microObjKybDisplay = DashboardStore.getMicroConfigDetail(
    KYB_SERVICE_DISPLAY_KEY
  )
  const hostKybDisplay = (microObjKybDisplay && microObjKybDisplay?.host) || ''

  const microObjAdmin = DashboardStore.getMicroConfigDetail(
    SERVICE_ADMIN_KEY
  )
  const hostAdmin = (microObjAdmin && microObjAdmin?.host) || ''

  return (
    <div className={classes.table}>
      <div className={classes.tableCell}>
        <MicroFrontendCache
          history={window.history}
          host={host}
          name={microObj?.id || ''}
          containerStore={{}}
        />
        <MicroFrontendCache
          history={window.history}
          host={hostLogs}
          name={microObjLogs?.id || ''}
          containerStore={{}}
        />
        <MicroFrontendCache
          history={window.history}
          host={hostCompany}
          name={microObjCompany?.id || ''}
          containerStore={{}}
        />
        <MicroFrontendCache
          history={window.history}
          host={hostApi}
          name={microObjApi?.id || ''}
          containerStore={{}}
        />
        <MicroFrontendCache
          history={window.history}
          host={hostTeam}
          name={microObjTeam?.id || ''}
          containerStore={{}}
        />
        <MicroFrontendCache
          history={window.history}
          host={hostBilling}
          name={microObjBilling?.id || ''}
          containerStore={{}}
        />
        <MicroFrontendCache
          history={window.history}
          host={hostWebhook}
          name={microObjWebhook?.id || ''}
          containerStore={{}}
        />
        <MicroFrontendCache
          history={window.history}
          host={hostUsage}
          name={microObjUsage?.id || ''}
          containerStore={{}}
        />
        <MicroFrontendCache
          history={window.history}
          host={hostKybDisplay}
          name={microObjKybDisplay?.id}
          containerStore={{}}
        />
        <MicroFrontendCache
          history={window.history}
          host={hostAdmin}
          name={microObjAdmin?.id}
          containerStore={{}}
        />

        <Page403Icon className={classes.icon} />
        <div >
          <Typography className={classes.bold} variant='h6'>{translate('txtPage403Desc1')}</Typography>
          <Typography className={classes.normal} variant='subtitle1' color={COLOR.textColor.secondary}>{translate('txtPage403Desc2')}</Typography>
          <Button onClick={onClickGoToServiceList} className={classes.button}>{translate('txtReturnToServices')}</Button>
        </div>

      </div>
    </div>
  )

})

export default Page403
