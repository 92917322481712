
import { useEffect, useState } from 'react'
import { SERVICE_WEBHOOK_KEY } from '@constants/constants'
import CircularProgress from '@mui/material/CircularProgress';
import { observer } from 'mobx-react-lite'
import Cookies from 'js-cookie'
import Box from '@mui/material/Box';
import useRootStore from '@hooks/useRootStore'
import { COLOR } from '@theme/colors'
import makeStyles from '@mui/styles/makeStyles';
import ReactGA from "react-ga4";
import loadable from '@loadable/component'
const MicroFrontend = loadable(() => import('../../MicroFrontend'))


const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: COLOR.bgColorSecondary,
    margin: 0,
    height: 'auto',
    width: "100%",
  },
}))

interface Props {
  tabValue: any
}
const ServiceWebhook = observer(function ServiceWebhook(props: Props) {
  const [verifying, setVerifying] = useState(false);

  useEffect(() => {
    DashboardStore.hideFooter = true
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: 'ACv2 | Service Webhook Settings' });
    fetchData()
  })

  const classes = useStyles()

  const {
    RouteStore,
    LocalizationStore,
    DashboardStore,
    UINotificationStore,
    AuthStore,
  } = useRootStore()
  const { containerStore } = DashboardStore
  const microObj = DashboardStore.getMicroConfigDetail(
    SERVICE_WEBHOOK_KEY
  )

  const handleAuthorizeError = async (code) => {
    try {
      if (code === 401) { //expired
        //show popup -> onAction -> setAuthenticated = false
        AuthStore.setOpenSessionExpired(true)
      } else { //unauthorized
        RouteStore.push('/403')
      }
    } catch (error) {
      setVerifying(false)
    }
  }

  const handleShowSnackbar = async (options) => {
    UINotificationStore.showSnackbar(options)
  }
  const fetchData = async () => {
    try {
      const { microLoaded } = DashboardStore
      if (microLoaded) {
        return
      }
      const microObj = DashboardStore.getMicroConfigDetail(
        SERVICE_WEBHOOK_KEY
      )
      const token = AuthStore.accessToken || ''
      containerStore.baseUrl = (microObj && microObj.backendHost) || ''
      containerStore.accessToken = token
      containerStore.language = LocalizationStore.activeLanguage || 'en'
      containerStore.onAuthorizeError = handleAuthorizeError
      containerStore.onShowSnackbar = handleShowSnackbar

      containerStore.tabValue = 0 //0: settings | 1:logs
      const serviceInfo = DashboardStore.getServiceInfo()
      containerStore.service = serviceInfo
      containerStore.profile = AuthStore.getMicroUserProfile(DashboardStore.getClientId()) || {}

      DashboardStore.microLoaded = true
      setVerifying(false)

    } catch (error) {
      // Cookies.remove(COOKIES_SSID, {
      //   domain: COOKIES_DOMAIN,
      // })
      Cookies.set('logged_in', 'no')
      AuthStore.redirectToOauth()
      setVerifying(false)
    }
  }


  containerStore.tabValue = props.tabValue
  const host = (microObj && microObj?.host) || ''
  try {
    return (
      <Box className={classes.container}>
        {host === '' || verifying || !DashboardStore.microLoaded ? (
          <div >
            <div style={{ position: 'relative', width: '100%', height: "768px" }}>
              <Box style={{ position: 'absolute', top: '50%', left: "50%", marginTop: '-24px' }} >
                <CircularProgress />
              </Box>
            </div>
          </div>
        ) : (
          <MicroFrontend
            history={window.history}
            host={host}
            name={microObj.id}
            containerStore={containerStore}
          />
        )}
      </Box>
    )
  } catch (error) {
    console.error('Micro fronted error: ', error)
    //redirect to root page
    // document.location.href = "/";

    // reload page
    window.location.reload()
  }
})

export default ServiceWebhook

