/* eslint-disable */
// prettier-ignore
export default {
  "txPersonalAttributeDesc": "Vous pouvez demander des informations supplémentaires à vos utilisateurs lors de l’intégration. Ces attributs ne sont pas vérifiés par Blockpass.",
  "txt2FA": "2FA",
  "txt404": "La page que vous recherchez est introuvable !",
  "txtAML": "LBC",
  "txtAMLCheck": "Vérification AML",
  "txtAMLDesc1": "Faites une analyse par rapport à une base de données mondiale de listes de PPE/sanctions et de médias indésirables, et identifiez si vos clients sont politiquement exposés, sanctionnés ou ont un profil médiatique défavorable.",
  "txtAMLDesc2": "Cette vérification oblige les utilisateurs à fournir leur nom complet et leur date de naissance.",
  "txtAPIAndWebhooks": "API et webhooks",
  "txtAccountDeletion": "Suppression de compte",
  "txtAccountDeletionDesc": "Supprimer les comptes mobiles et Web",
  "txtAction": "Action",
  "txtActive": "Actif",
  "txtActivityLogs": "Journaux d'activité",
  "txtAdd": "Ajouter",
  "txtAddCompany": "Ajouter une entreprise",
  "txtAddMember": "Ajouter un membre",
  "txtAddMemberDesc": "Le membre recevra un email donnant accès à ce service.",
  "txtAddMemberEmailIsRequired": "Veuillez saisir l'e-mail de votre membre.",
  "txtAddMembers": "Ajouter des membres",
  "txtAddNewBpOperatorToService": "Attribuer",
  "txtAddNewCompany": "Ajouter une nouvelle entreprise",
  "txtAddNewOperatorSuccess": "Ajoutez un nouvel opérateur avec succès.",
  "txtAddNewsContent": "Ajouter des nouvelles",
  "txtAddNotification": "Ajouter une notification",
  "txtAddOperatorToService": "Ajouter un opérateur au service",
  "txtAddPaymentMethod": "Ajouter un mode de paiement",
  "txtAddPaymentMethodFail": "Quelque chose ne va pas ici. Veuillez ajouter le mode de paiement plus tard.",
  "txtAddPaymentMethodSuccessfully": "Votre carte de paiement a été ajoutée avec succès",
  "txtAddRichContent": "Ajouter du contenu riche",
  "txtAddTag": "Ajouter une étiquette",
  "txtAddTagPlaceholder": "Tapez une étiquette de tag...",
  "txtAddTagSuccess": "Balise « <0> » ajoutée avec succès.",
  "txtAddingTag": "Ajout d'une balise ",
  "txtAdmin": "Administrateur",
  "txtAdminConsole": "Console d'administration",
  "txtAdminSettingsUpdateSuccess": "Paramètres d'administration mis à jour avec succès.",
  "txtAdministration": "Administration",
  "txtAdvancedApprovalDesc": "Bot KYC avancé : ",
  "txtAdvancedApprovalDesc1": "Ensemble de plus de 10 règles pour approuver ou rejeter automatiquement les utilisateurs.",
  "txtAdvancedApprovalTooltip": "Advanced KYC Bot n’est pas disponible pour ce plan. Contactez-nous si vous souhaitez l'activer.",
  "txtAfterReturn": "après que l'API a renvoyé les données ",
  "txtAgeRestriction": "Restriction d'âge",
  "txtAgeRestrictionDesc1": "Vous pouvez exclure des candidats en fonction de leur âge. ",
  "txtAgeRestrictionDesc2": "Le candidat doit être au moins",
  "txtAgeRestrictionDesc3": "ans.",
  "txtAllPlanFeatureHeader": "Tous les forfaits incluent les fonctionnalités suivantes",
  "txtAllTimeAreUTC": "(Toutes les heures sont UTC)",
  "txtAllowed": "Autorisé",
  "txtAllowedCountries": "pays autorisés",
  "txtAnnouncements": "Annonces",
  "txtAnyoneWithTheLink": "Quelqu'un ayant le lien",
  "txtAnyoneWithTheLinkDesc1": "Toute personne disposant de ce lien peut s'inscrire auprès de votre service.",
  "txtAnyoneWithTheLinkDesc2": "Cette page permet aux candidats d'envoyer leurs informations KYC à votre tableau de bord. Copiez et collez le lien dans un e-mail ou à tout autre endroit où vous souhaitez le partager.",
  "txtApiKey": "Clé API",
  "txtApiKeyCopied": "Votre clé API copiée",
  "txtApiKeyDeleteDesc": "Une fois supprimée, la clé API ne peut pas être récupérée. Si vous n'êtes pas entièrement sûr que la clé puisse être supprimée, vous pouvez plutôt définir le statut sur inactif.",
  "txtApiKeys": "Clés API",
  "txtApply": " appliquer.",
  "txtApproveAdvanced": "Bot KYC avancé",
  "txtApproveBasic": "Bot KYC",
  "txtApproveDesc1": "Vous pouvez gagner du temps en sélectionnant le mode de révision. \n",
  "txtApproveDesc2": "Les robots KYC sont recommandés pour un grand nombre d’utilisateurs. Voir ",
  "txtApproveDesc3": "plus de détails",
  "txtApproveDesc4": " dans les documents",
  "txtApproveFreeManual": "Manuel : approuvez ou rejetez chaque utilisateur manuellement. Sélectionnez un plan pour accélérer votre processus d'intégration.",
  "txtApproveNone": "Révision manuelle",
  "txtApproverTooltipkyc": "Peut examiner et approuver KYC. Impossible de supprimer des profils ou de mettre à jour les paramètres",
  "txtApproverkyc": "Approbateur KYC",
  "txtAssignCompanyToService": "Ajouter des informations sur l'entreprise",
  "txtAssignRole": "Rôle assigné",
  "txtAssignUserRole": "Rôle assigné",
  "txtAttributeDataDesc": "Une fois un profil archivé, seules les métadonnées subsistent, les attributs d'identité sont supprimés.",
  "txtAttributesConfig": "Configuration des attributs",
  "txtAttributesConfigDesc": "Configurer les attributs disponibles dans les profils utilisateur",
  "txtAttributesGroupConfig": "Configuration du groupe d'attributs",
  "txtAttributesGroupConfigDesc": "Configurer le groupe d'attributs disponibles dans les profils utilisateur",
  "txtAutoApproval": "Approbation automatique",
  "txtAutoArchive": "Archiver automatiquement le profil de l'utilisateur",
  "txtAutoArchiveTooltip": "Archiver automatiquement le profil de l'utilisateur @@ar_ch_ti_me@@ secondes après que l'API a renvoyé les données",
  "txtAutoTranslate": "(traduit automatiquement)",
  "txtAutomationRules": "Règles d'automatisation",
  "txtAvatar": "Avatar",
  "txtAvaxAddress": "Adresse des avalanches",
  "txtBack": "Dos",
  "txtBasicApprovalDesc": "Bot KYC :  ",
  "txtBasicApprovalDesc1": "Approuver automatiquement les utilisateurs avec des contrôles clairs.",
  "txtBasicInfo": "Informations de base",
  "txtBestPractices": "les meilleures pratiques",
  "txtBilling": "Facturation",
  "txtBillingInfo": "Informations de facturation",
  "txtBillingInfomation": "Informations de facturation",
  "txtBillingOwner": "Propriétaire de facturation",
  "txtBinanceAddress": "Adresse Binance (BSC)",
  "txtBitcoinAddress": "Adresse Bitcoin",
  "txtBlockpass": "Blockpass",
  "txtBlockpassAccess": "Accès bloc-pass",
  "txtBlockpassAccessDesc": "Autoriser l'équipe d'assistance Blockpass à accéder à votre tableau de bord KYC",
  "txtBlockpassAccessPermission": "Vous n'êtes pas autorisé à accéder à cette fonctionnalité",
  "txtBlockpassKycRules": "Règles KYC Blockpass",
  "txtBpKYCRules": "Règles KYC Blockpass",
  "txtBrowse": "Parcourir",
  "txtBtcAdress": "Adresse Bitcoin",
  "txtBusinessInfomation": "Informations d'affaires",
  "txtBusinessTerms": "Conditions commerciales",
  "txtCanNotSetTagsOnChainKYCForMultipleCryptoService": "Impossible de définir les balises onchainKyc pour les services ayant plusieurs champs de chiffrement",
  "txtCancel": "Annuler",
  "txtCardHolderName": "Nom du titulaire",
  "txtCardInfomation": "Informations sur la carte",
  "txtCardanoAddress": "Adresse de Cardano (ADA)",
  "txtCaution": "Prudence !",
  "txtCautionDesc1": "Si vous intégrez actuellement des utilisateurs, il n'est pas recommandé de changer de forfait. Veuillez nous contacter pour obtenir de l'aide.",
  "txtCautionDesc2": "Sinon, vous pouvez continuer la configuration.",
  "txtCertificatesOrderConfig": "Configuration de la commande de certificats",
  "txtCertificatesOrderConfigDesc": "Configurer l'ordre des certificats dans les profils d'utilisateurs",
  "txtChangeBillingPlan": "Modifier le forfait de facturation",
  "txtChangePlan": "Changer de forfait",
  "txtChangeWebhook": "Webhook mis à jour",
  "txtChartEmptyTooltip": "Ce graphique s'affichera lorsque vos services seront utilisés par les utilisateurs.",
  "txtCheckDebugger": "Vérifier le débogueur",
  "txtCheckDebuggerDesc": "Recherchez des chèques par ID de chèque. Un chèque délivre généralement un certificat",
  "txtChecksConfig": "Vérifie la configuration",
  "txtChecksConfigDesc": "Configurer les attributs et le certificat définissant le contrôle",
  "txtChinese": "Chinois",
  "txtClickOrDragToUpload": "Cliquez ou faites glisser pour télécharger votre logo",
  "txtClientId": "identité du client",
  "txtClientIdExisted": "L'ID client existe déjà, veuillez utiliser un autre ID client",
  "txtClientIdTooltip": "Identifiant unique de votre service. \nIl est généré automatiquement en fonction\nsur le nom de votre service",
  "txtClose": "Fermer",
  "txtCodeHasBeenCopied": "le code a été copié",
  "txtCodeSample": "Exemple de code",
  "txtCompany": "Entreprise",
  "txtCompanyAddress": "Adresse de la société",
  "txtCompanyAddressCity": "Ville",
  "txtCompanyAddressCountry": "Pays",
  "txtCompanyAddressLine1": "Adresse Ligne 1",
  "txtCompanyAddressLine1IsRequired": "La ligne 1 de l’adresse de l’entreprise est obligatoire.",
  "txtCompanyAddressLine2": "ligne d'adresse 2 (optionnel)",
  "txtCompanyAddressPostalCode": "Code Postal",
  "txtCompanyAddressPostalCodeRequired": "Le code postal est requis.",
  "txtCompanyAddressState": "État",
  "txtCompanyCity": "Ville",
  "txtCompanyCountryIsRequired": "Le pays est requis",
  "txtCompanyCustomRequest": "Message",
  "txtCompanyEmailIsRequired": "L'adresse e-mail de l'entreprise est requise.",
  "txtCompanyGroup": "3. Entreprise",
  "txtCompanyInfo": "Information d'entreprise",
  "txtCompanyInfomation": "Informations sur la société",
  "txtCompanyName": "Nom de l'entreprise",
  "txtCompanyNameIsRequired": "Le nom de l'entreprise est requis",
  "txtCompanyNameRepIsRequired": "Le nom du représentant de l’entreprise est requis.",
  "txtCompanyNumber": "Numéro d'entreprise",
  "txtCompanyNumberIsRequired": "Le numéro d'entreprise est requis",
  "txtCompanyPhoneRepIsRequired": "Le téléphone du représentant de l’entreprise est requis.",
  "txtCompanyPostalCode": "Code Postal",
  "txtCompanyRepresentative": "Nom du représentant de l'entreprise",
  "txtCompanyState": "État",
  "txtCompanyVAT": "Numéro de taxe ou de TVA",
  "txtCompanyVATNumber": "Numéro de taxe ou de TVA",
  "txtCompanyVATNumberIsRequired": "Le numéro de TVA de l'entreprise est requis",
  "txtConfiguration": "Configuration",
  "txtConfirm": "Confirmer",
  "txtConfirmDeleteCompanyInfo": "Si vous revenez à l'étape précédente, toutes les informations saisies sur l'entreprise seront effacées.",
  "txtConnectWithUs": "Connecte-toi avec nous",
  "txtContactSale": "Contacter le service commercial",
  "txtContactSaleTeam": "Contactez notre équipe commerciale",
  "txtContactSupport": "Contacter le service commercial",
  "txtContent": "Contenu",
  "txtContinue": "Continuer",
  "txtContinueToConfigureService": "Continuez à configurer votre service",
  "txtCopied": "Copié!",
  "txtCopy": "Copie",
  "txtCopyAndClose": "Copier et fermer",
  "txtCopyApiKeyWarning": "Vous ne pourrez pas le récupérer dès que vous fermerez la fenêtre contextuelle. Rangez-le dans un endroit sûr. \nEn cas de perte, vous devrez générer une nouvelle clé.",
  "txtCopyLink": "Copier le lien",
  "txtCopyright": "© Blockpass ",
  "txtCount": "Compter",
  "txtCountryRestriction": "Restrictions nationales",
  "txtCountryRestrictionDesc1": "Vous pouvez exclure les résidents de certains pays ou n'accepter que les résidents d'autres pays. Cette option oblige les utilisateurs à fournir leur adresse résidentielle.",
  "txtCountryRestrictionDesc2": "Le pays indiqué ici n'est qu'un exemple. Vous devriez vérifier auprès de votre responsable de la conformité quels sont les pays à restreindre.",
  "txtCountryRestrictionDescAllow": "Autoriser uniquement les résidents de",
  "txtCountryRestrictionDescExclude": "Exclure les résidents de",
  "txtCoutryOfIncorporation": "Pays de constitution",
  "txtCreate": "Créer",
  "txtCreateAPIKey": "Créer une clé API",
  "txtCreateAPIKeyFooter1": "L'exposition publique d'une clé API peut entraîner la compromission des données personnelles de vos utilisateurs. ",
  "txtCreateAPIKeyFooter2": "Vous devez suivre ",
  "txtCreateAPIKeyFooter3": " pour utiliser en toute sécurité les clés API.",
  "txtCreateAccount": "Nouveau compte créé",
  "txtCreateApiKeySuccess": "Nouvelle clé API créée",
  "txtCreateBillingPlan": "Créer un plan de facturation",
  "txtCreateCertificate": "Nouveau certificat créé",
  "txtCreateCheckConfig": "Créer une configuration de chèque",
  "txtCreateCompany": "Créer une entreprise",
  "txtCreateFlowConfig": "Créer une configuration de flux",
  "txtCreateIdentityField": "Champ d'identité créé",
  "txtCreateKYCSettings": "Paramètres KYC créés",
  "txtCreateNewTag": "Créer une balise ",
  "txtCreateRuntimeCfg": "Configuration d'exécution créée",
  "txtCreateService": "Nouveau service créé",
  "txtCreateServiceDashboard": "Créer un service",
  "txtCreateServiceSuccess": "Nouveau service créé avec succès",
  "txtCreateVersion": "Création d'une nouvelle version de l'API",
  "txtCreateWebhook": "Webhook créé",
  "txtCreatedAt": "Créé à",
  "txtCryptoAddresses": "Adresses cryptographiques",
  "txtCurrentPlan": "Plan actuel",
  "txtCustomRegistrationUrl": "URL d'inscription personnalisée",
  "txtCustomUrlInvalid": "Cette URL n'est pas valide.",
  "txtCustomiseAppLink": "Lien vers l'application pour mobile",
  "txtDAY": "Jour",
  "txtDOB": "Date de naissance",
  "txtDashboard": "Tableau de bord",
  "txtDataExport": "Exportation de données",
  "txtDate": "Date",
  "txtDelete": "Supprimer",
  "txtDeleteAPIKey": "Supprimer la clé API",
  "txtDeleteApiKeySuccess": "Clé API supprimée",
  "txtDeleteBulkIdentityRecordConfirmMessage": "Êtes-vous sûr de vouloir supprimer ces comptes ?",
  "txtDeleteThisApiKey": "Supprimer cette clé API",
  "txtDeleteVersion": "Version d'API supprimée",
  "txtDeleteWebhook": "Webhook supprimé",
  "txtDescription": "Description",
  "txtDescriptionIsRequired": "Veuillez saisir une description pour cette clé API",
  "txtDetachPaymentMethod": "Détacher le mode de paiement",
  "txtDetails": "Détails",
  "txtDisabled": "Désactivé",
  "txtDiscard": "Jeter",
  "txtDiscardRichContent": "Supprimer le contenu riche",
  "txtDisplay": "Afficher",
  "txtDoNotSendAddMember": "Vous n'êtes pas autorisé à inviter un nouveau membre. Veuillez contacter le propriétaire du service. Code d'erreur 403.",
  "txtDocs": "Documents",
  "txtDocumentation": "Documentation",
  "txtDonNotHaveService": "Vous n'avez aucun service dans cette entreprise",
  "txtDontHavePaymentMethod": "Vous n'avez aucun moyen de paiement, veuillez ajouter un mode de paiement ci-dessous",
  "txtDowngradeBpOperator": "Rétrograder",
  "txtDownload": "Télécharger",
  "txtDownloadLogs": "Télécharger les journaux",
  "txtDrivingLicense": "Permis de conduire",
  "txtDropFileHere": "Déposez votre logo ici",
  "txtEditClientId": "Modifier l'ID client",
  "txtEditClientIdWarning": "Cet ID client ne peut pas être modifié ultérieurement et doit être unique.",
  "txtEditConfig": "Modifier la configuration",
  "txtEditMembers": "Modifier les membres",
  "txtEditNewsContent": "Modifier l'actualité",
  "txtEditSearch": "Modifier la recherche",
  "txtEmail": "E-mail",
  "txtEmailAddress": "Adresse e-mail",
  "txtEmailRepresentative": "Courriel du représentant de l'entreprise",
  "txtEmailWrongFormat": "Mauvais format d'e-mail",
  "txtEnable": "Activer",
  "txtEnableApiKeys": "Activer les clés API",
  "txtEnableApiKeysDes": "Autorisez les clients à utiliser des clés API pour accéder aux données des utilisateurs.",
  "txtEnableEndUserRegistrations": "Activer les inscriptions des utilisateurs finaux",
  "txtEnableEndUserRegistrationsDes": "Permet aux utilisateurs finaux de s'inscrire pour vérification auprès de ce service.",
  "txtEnableKycDashboard": "Activer le tableau de bord KYC",
  "txtEnableKycDashboardDes": "Autorisez les clients à accéder au tableau de bord KYC.",
  "txtEnableService": "Activer le service",
  "txtEnableServiceDes": "Cette option est dangereuse ; l’activation et la désactivation fréquentes du service peuvent provoquer de graves erreurs.",
  "txtEnabled": "Activé",
  "txtEnabled2FA": "2FA activé",
  "txtEndDate": "Date de fin",
  "txtEnglish": "Anglais",
  "txtEnterpriseDesc1": "Minimum 100 profils par mois",
  "txtEnterpriseDesc2": " pour plus d'informations.",
  "txtEnterpriseSentDesc1": "Merci d'avoir choisi le forfait Entreprise. Notre équipe commerciale vous contactera dans les plus brefs délais et configurera le service selon vos besoins. \n",
  "txtEnterpriseSentDesc2": "En attendant, veuillez ajouter un mode de paiement et compléter vos informations de facturation, elles sont nécessaires pour activer votre forfait",
  "txtErrorUnsupportFile": "Fichier non supporté",
  "txtEthAddress": "Adresse Ethereum",
  "txtEthereumAddress": "Adresse Ethereum",
  "txtEvent": "Événement",
  "txtExample": "Exemple",
  "txtExcept": "sauf",
  "txtExcluded": "Exclu",
  "txtExcludedCountries": "pays exclus",
  "txtExpiryDate": "Date d'expiration",
  "txtExploreKyc": "Explorez votre tableau de bord KYC Connect. ",
  "txtExportServiceConfig": "Exporter la configuration du service",
  "txtFaceMatch": "Correspondance des visages",
  "txtFaceMatchCheck": "Vérification de la correspondance faciale",
  "txtFaceMatchDesc1": "Blockpass compare l'image sur la pièce d'identité de l'utilisateur avec le selfie en direct demandé lors de l'inscription.",
  "txtFaceMatchDesc2": "Ce contrôle nécessite que les utilisateurs fournissent leur pièce d'identité et un selfie en direct.",
  "txtFacematchCheck": "Vérification Facematch",
  "txtFailedToSendMemberInvitation": "Échec de l'envoi de l'invitation de membre",
  "txtFamilyName": "Nom de famille",
  "txtFaq": "FAQ",
  "txtFileNotSupport": "Ce fichier n'est pas pris en charge",
  "txtFillCompanyInfo": "Remplissez les informations basées sur une entreprise existante ci-dessous ou ajoutez une nouvelle entreprise.",
  "txtFlowAddress": "Adresse du flux (FLOW)",
  "txtFlowDebugger": "Débogueur de flux",
  "txtFlowDebuggerDesc": "Recherchez des flux par ID de flux. Les flux peuvent inclure plusieurs contrôles",
  "txtFlowsConfig": "Configuration des flux",
  "txtFlowsConfigDesc": "Configurer les contrôles définissant le flux",
  "txtForbidden": "Vous n'êtes pas autorisé à effectuer cette action",
  "txtForceAddOperator": "Ajouter un opérateur au service",
  "txtForceAddOperatorDesc": "Les propriétaires de services n'ont pas autorisé Blockpass, vérifiez que vous avez l'autorisation d'affecter des opérateurs",
  "txtFreePlanDescription": "Vous recevez des identités non vérifiées. Limité à 10 par jour.",
  "txtFreeTrial": "Essai gratuit",
  "txtFrench": "Français",
  "txtFunctionUnavailable": "Fonction temporairement indisponible",
  "txtGeneralInfomation": "Informations générales",
  "txtGeoRestriction": "Restrictions géographiques",
  "txtGetServiceDisplayFailed": "Échec de l'affichage des services.",
  "txtGetServiceListFailed": "Échec de l’obtention de la liste de services.",
  "txtGetServiceWebhookListFailed": "L’obtention des webhooks de services échoue.",
  "txtGivenName": "Prénom",
  "txtGoToDashboard": "ALLER AU TABLEAU DE BORD",
  "txtGoToServiceDashboard": "Accédez au tableau de bord des services",
  "txtGoToServiceList": "PLUS TARD",
  "txtGotoDashboardDesc1": "Accédez au tableau de bord KYC Connect pour ce service.",
  "txtGotoDashboardDesc2": "Le tableau de bord est l'endroit où votre équipe peut consulter les identités téléchargées sur votre service par des individus.",
  "txtHelp": "Aide",
  "txtIdEngine": "Moteur d'identification",
  "txtIdEngineDesc": "Outil OCR du moteur d'identification de test",
  "txtIdentityDesc1": "Blockpass vérifie les passeports, les cartes d'identité nationales et les permis de conduire. ",
  "txtIdentityDesc2": "Les utilisateurs sont priés de prendre une photo en direct de leur document.",
  "txtIdentityDesc3": "Cette option nécessite que les utilisateurs fournissent leur pièce d'identité, leur nom complet et leur date de naissance.",
  "txtIdentityDocument": "Document d'identité",
  "txtIdentityDocumentCheck": "Vérification des documents d'identité",
  "txtIdentityLink1": "Voir la liste des pays pris en charge",
  "txtImageTooBig": "L'image est trop grande",
  "txtImgDimensionBetween10242048": "Veuillez télécharger une image entre 512 x 512 et 2 048 x 2 048 pixels.",
  "txtImgDimensionRule": "PNG, JPEG. Min 512x512 pixels, maximum 2048x2048 pixels.",
  "txtImpactChanging": "Chargement par impact",
  "txtInactive": "Inactif",
  "txtIncludeRawDataExceptPictureTooltip": "Renvoie les métadonnées et les attributs d'identité, à l'exception des images",
  "txtIncludeRawDataIncludePictureTooltip": "Renvoie les métadonnées et les attributs d'identité, y compris les images",
  "txtIncludeRawDataTooltip": "Renvoie les métadonnées et les attributs d'identité, à l'exception des images",
  "txtIncluding": "y compris",
  "txtIncorrectFormat": "Le champ ne doit contenir que des caractères normaux/MAJUSCULES, des chiffres et un trait de soulignement (_).",
  "txtInfomation": "Information",
  "txtInputYourServiceName": "Saisissez le nom de votre service",
  "txtInternalServerError": "Une erreur inattendue s'est produite sur le serveur",
  "txtInvalidEmailFormat": "Format d'email invalide",
  "txtInvalidEmails": "E-mails invalides",
  "txtInvalidParams": "La demande contient des paramètres non valides, veuillez revérifier votre saisie",
  "txtInvalidURL": "L'URL n'est pas valide",
  "txtInvalidUrl": "Cette URL n'est pas valide",
  "txtInvitationRemoved": "L'invitation a été supprimée",
  "txtInvitationResent": "L'invitation est renvoyée",
  "txtInvitationSent": "L'invitation a été envoyée à",
  "txtInviteAlreadySent": "Invitation déjà envoyée",
  "txtInviteUser": "Utilisateur invité",
  "txtIsCopiedToClipboard": "est copié dans le presse-papiers.",
  "txtIsSuccess": "Succès",
  "txtJapanese": "Japonais",
  "txtJobId": "Identifiant du travail",
  "txtKYCBot": "Bot KYC",
  "txtKYCDashboardAccess": "Accès au tableau de bord KYC",
  "txtKybDashboard": "Tableau de bord KYB",
  "txtKybService": "Service KYB",
  "txtKybServiceDesc": "KYB signifie « Connaissez votre entreprise ». Créez un service KYB pour vérifier l'identité des entreprises.",
  "txtKybServiceName": "Nom du service KYB",
  "txtKybServices": "Services KYB (en cours)",
  "txtKycDashboard": "Tableau de bord KYC",
  "txtKycService": "Service KYC",
  "txtKycServiceDesc": "KYC signifie « Connaissez votre client ». Créez un service KYC pour vérifier l’identité des individus.",
  "txtKycServiceName": "Nom du service KYC",
  "txtKycServices": "Services KYC",
  "txtLast180Days": "180 derniers jours",
  "txtLast30Days": "Les 30 derniers jours",
  "txtLast7Days": "Les 7 derniers jours",
  "txtLast90Days": "90 derniers jours",
  "txtLastMonth": "Le mois dernier",
  "txtLastRunAt": "Dernière exécution à",
  "txtLatestInvoice": "Dernière facture",
  "txtLatestInvoiceDesc": "Les factures sont envoyées chaque mois à l'adresse e-mail du propriétaire de facturation. Vous pouvez également télécharger ci-dessous la dernière facture émise.",
  "txtLearnMoreAboutApiKey": "En savoir plus sur l'API Blockpass ",
  "txtLengthTooShort": "La longueur du champ est trop courte",
  "txtLink": "Lien",
  "txtListDeleteEmail": "Liste des emails que vous souhaitez supprimer, séparés par des virgules, jusqu'à 50 emails",
  "txtListOfServices": "Liste des prestations",
  "txtListingRunningCheck": "Liste en cours de vérification",
  "txtListingRunningCheckDesc": "Description du listing en cours d'exécution",
  "txtLive": "En direct",
  "txtLockUser": "Utilisateur verrouillé",
  "txtLogin": "Se connecter",
  "txtLogo": "Logo",
  "txtLogoDesc": "PNG, JPEG, JPG. \nMin 512x512 px, maximum 2048x2048 px",
  "txtLogoRestriction": "La taille de l'image doit être comprise entre 1 024 x 1 024 et 2 048 x 2 048 pixels.",
  "txtLogoSizeError": "Le logo que vous venez de télécharger n'est pas de la bonne taille.",
  "txtLogout": "Se déconnecter",
  "txtLogs": "Journaux",
  "txtLongDescription": "Longue description",
  "txtLongDescriptionTooltip": "Description complète de votre service (texte non formaté uniquement)",
  "txtMONTH": "Mois",
  "txtMarkerPlace": "Marché",
  "txtMarkerPlaceDesc": "Gérer les entrées du marché",
  "txtMarketPlace": "Marché - En-cours",
  "txtMarketplace": "Marché",
  "txtMarketplaceSettingUpdateSuccess": "Paramètres du marché mis à jour avec succès.",
  "txtMaticAddress": "Adresse du polygone (Matic)",
  "txtMaxEmailsExceeded": "Maximum 50 e-mails par suppression",
  "txtMemberInfomation": "Informations sur les membres",
  "txtMetaDataOnlyTooltip": "Renvoie uniquement les métadonnées (statut du profil, identifiants,...)",
  "txtMore": "Plus",
  "txtMoreDetails": "Plus de détails",
  "txtMoreInfomation": "Plus d'informations",
  "txtName": "Nom",
  "txtNameOfCompany": "Nom de l'entreprise",
  "txtNameOfCompanyIsRequired": "Le nom de l'entreprise est requis",
  "txtNameOnCard": "Nom sur la carte",
  "txtNameOnCardIncomplete": "Le nom sur la carte est incomplet.",
  "txtNameRepresentative": "Nom du représentant de l'entreprise",
  "txtNationalID": "Carte d'identité nationale",
  "txtNationalId": "carte d'identité",
  "txtNewCompany": "Nouvelle compagnie",
  "txtNewsContent": "Contenu d'actualité",
  "txtNext": "Suivant",
  "txtNoActivityLogs": "Aucune activité ne correspond à votre recherche",
  "txtNoActivityLogsDesc": "Essayez d'être moins précis ou d'utiliser des mots-clés différents.",
  "txtNoApiKeyDesc": "Une clé API est requise pour extraire l’identité des individus, y compris les métadonnées, les informations personnelles et les images. ",
  "txtNoApiKeys": "Aucune clé API",
  "txtNoCompanyAdd": "Vous n'avez pas encore ajouté d'informations sur l'entreprise",
  "txtNoCompanyAddDesc": "Cliquez ci-dessous pour ajouter les premières informations sur l'entreprise.",
  "txtNoCountryRestriction": "Aucun pays. Utilisez la recherche pour ajouter un pays à la liste",
  "txtNoData": "Aucun enregistrement trouvé, essayez d'élargir votre requête !",
  "txtNoDataMemberInService": "Aucun opérateur ajouté, veuillez d'abord ajouter un opérateur à ce service ! ",
  "txtNoFieldsAreUpdated": "Aucun champ n'est mis à jour",
  "txtNoInvoiceDesc1": "Les factures sont envoyées chaque mois à l'adresse e-mail du propriétaire de facturation. ",
  "txtNoInvoiceDesc2": "Aucune facture émise à télécharger maintenant.",
  "txtNoServiceMatch": "Aucun service ne correspond à votre recherche",
  "txtNoServiceMatchDesc": "Essayez d'être moins précis ou d'utiliser des mots-clés différents.",
  "txtNoSetupCost": "Aucun frais d'installation",
  "txtNoUsageDetail": "aucune donnée à afficher",
  "txtNoUsageDetailDesc": "Il n'y a pas de statistiques disponibles pour le moment.",
  "txtNone": "Aucun",
  "txtNoneApprovalDesc": "Manuel: ",
  "txtNoneApprovalDesc1": "Approuvez ou rejetez chaque utilisateur manuellement.",
  "txtNormalDesc1": "Maximum 100 profils par mois.",
  "txtNotStoreCard": "Blockpass ne stocke pas les détails de votre carte.",
  "txtNotifications": "Notifications",
  "txtNotificationsDesc": "Envoyer des notifications push aux utilisateurs mobiles",
  "txtOnboarding": "Intégration",
  "txtOnboardingGroup": "2. Intégration",
  "txtOnboardingSetting": "Paramètres d'intégration",
  "txtOnly": " seulement ",
  "txtOpenDashboard": "Ouvrir le tableau de bord",
  "txtOperatorAssignment": "Affectation des opérateurs",
  "txtOperatorAssignmentKYC": "Affectation des opérateurs",
  "txtOperatorList": "Liste des opérateurs",
  "txtOverview": "Aperçu",
  "txtPOA": "Un justificatif de domicile",
  "txtPage403Desc1": "Vous n'avez pas la permission de\n accéder à ce service",
  "txtPage403Desc2": "Veuillez revenir aux Services et trouver le bon service ou contacter le propriétaire du service.",
  "txtParametersValidationError": "La demande contient des paramètres non valides, veuillez revérifier votre saisie",
  "txtPassport": "Passeport",
  "txtPassword": "Mot de passe",
  "txtPayAsYouGo": "Payez au fur et à mesure",
  "txtPaymentMethod": "Mode de paiement",
  "txtPaymentMethodDeleted": "Le mode de paiement ne peut pas être récupéré ou a été supprimé. ",
  "txtPermissionDenied": "Vous n'êtes pas autorisé à effectuer cette action",
  "txtPersonalAtt": "Qualités personnelles",
  "txtPersonalAttributes": "Qualités personnelles",
  "txtPhoneNumber": "Numéro de téléphone",
  "txtPhoneNumberWrongFormat": "Le numéro de téléphone doit être composé de chiffres",
  "txtPhoneRepresentative": "Numéro de téléphone du représentant de l'entreprise",
  "txtPickYourServiceName": "Choisissez un nom pour votre service",
  "txtPlan": "Plan",
  "txtPlanConfig": "Configuration des forfaits",
  "txtPlanConfigDesc": "le processus d'envoi de factures aux gens leur demandant de payer l'argent dû",
  "txtPlanDescription1": "Moins de @@num_ident@@ identités par jour et par entreprise.\n",
  "txtPlanDescription2": "Délai de traitement supérieur à 24 heures. Standard ",
  "txtPleaseCopyAPIKey": "Veuillez copier la clé API",
  "txtPleaseReplacePaymentMethod": "Veuillez remplacer le mode de paiement.",
  "txtPleaseSelectOperator": "Veuillez sélectionner un opérateur.",
  "txtPleaseSelectUser": "Veuillez sélectionner un utilisateur.",
  "txtPostalCodeWrongFormat": "Le format est faux.",
  "txtPrivacy": "Confidentialité",
  "txtPrivacyPolicy": "Politique de confidentialité",
  "txtProfile": "Profil",
  "txtPromote": "Promouvoir",
  "txtPromoteBpOperator": "Promouvoir",
  "txtPromoteOperator": "Ajouter un opérateur",
  "txtPromoteOperatorSuccess": "Promouvoir l'opérateur avec succès.",
  "txtProofOfAddress": "Un justificatif de domicile",
  "txtProvideNameOfService": "Donnez un nom à votre service",
  "txtPublicLinkDesc": "Toute personne sur Internet disposant du lien peut soumettre son profil KYC.",
  "txtPublishService": "Service publié",
  "txtPushNotification": "Notification push",
  "txtRedirect": "Réorienter",
  "txtRemove": "Retirer",
  "txtRemoveAccess": "Supprimer l'accès",
  "txtRemoveBlockpassAccess": "Supprimer l'accès Blockpass",
  "txtRemoveBlockpassAccessDesc": "Êtes-vous sûr de vouloir supprimer l'accès Blockpass ? Cela empêchera l'équipe d'assistance Blockpass d'accéder à votre tableau de bord KYC.",
  "txtRemoveBpOperator": "Retirer",
  "txtRemoveInvitation": "Supprimer l'invitation",
  "txtRemoveOperator": "Supprimer l'opérateur",
  "txtRemoveOperatorDesc": "Êtes-vous sûr de vouloir supprimer cet opérateur ?",
  "txtRemoveOperatorSuccess": "Supprimez l'opérateur avec succès.",
  "txtRemoveTagConfirmContent": "Êtes-vous sûr de vouloir supprimer cette balise ?",
  "txtRemoveTagConfirmTitle": "Supprimer la balise \"<0>\"",
  "txtRemoveTagSuccess": "La balise « <0> » a été supprimée.",
  "txtRemoveThisMember": "Supprimer ce membre",
  "txtRemoveUser": "Utilisateur supprimé",
  "txtReplace": "Remplacer",
  "txtReplacePaymentMethod": "Remplacer le mode de paiement",
  "txtReplacePaymentMethodDesc1": "La nouvelle carte sera utilisée pour tous vos services ",
  "txtReplacePaymentMethodDesc2": "Notez que les changements de mode de paiement affecteront de nombreux services qui l'utilisent. \nmode de paiement.",
  "txtReport": "Rapport",
  "txtRepresentative": "Représentant",
  "txtRequestAccess": "Demande d'accès",
  "txtRequestEnterprise": "Demande de forfait Entreprise",
  "txtRequestEnterpriseSent": "Demande de forfait Entreprise envoyée !",
  "txtRequired": "* requis",
  "txtRequiredOneIdentityDocument": "Veuillez sélectionner au moins une pièce d'identité",
  "txtRequirementPlaceHolder": "Parlez-nous de vos besoins et de votre calendrier",
  "txtResAddress": "Adresse résidentielle",
  "txtResend": "Renvoyer",
  "txtResidentialAddress": "Adresse résidentielle",
  "txtRestricted": "Limité",
  "txtRestrictedDesc1": "Seules les personnes authentifiées sur votre site internet peuvent s'inscrire.",
  "txtRestrictedDesc2": "Sélectionnez cette option si vous souhaitez soumettre le refId de l'utilisateur au widget.",
  "txtRestrictedDesc3": "Veuillez saisir l'URL où le widget est installé sur votre site Web.",
  "txtRestrictedDescLink": "Plus d'informations dans la documentation.",
  "txtRestrictedLinkDesc1": "Forcez les utilisateurs à se connecter à votre site Web avant de pouvoir soumettre leur profil KYC.",
  "txtRestrictedLinkDesc2": "Sélectionnez cette option si vous souhaitez envoyer le refId de l'utilisateur au widget. ",
  "txtRestrictedLinkDesc3": "Plus d'informations dans la documentation.",
  "txtReturnAttributeData1": "Renvoie les métadonnées et les attributs d'identité",
  "txtReturnAttributeData2": "des photos",
  "txtReturnAttributeDataExceptPicture1": "Renvoie les métadonnées et les attributs d'identité",
  "txtReturnAttributeDataExceptPicture2": "des photos",
  "txtReturnMetaData1": "Retour",
  "txtReturnMetaData2": "métadonnées (statut du profil, identifiants,...)",
  "txtReturnToServices": "Retour aux prestations",
  "txtRevoke": "Révoquer",
  "txtRevokeOperator": "Opérateur de révocation",
  "txtRevokeOperatorDesc": "Êtes-vous sûr de vouloir révoquer cet opérateur ?",
  "txtRevokeOperatorSuccess": "Révocation de l'opérateur avec succès.",
  "txtRole": "Rôle",
  "txtRuleArchiveTime": "Un nombre de 1 à 9999",
  "txtRunningFlowConfig": "Exécution de la configuration de Flow",
  "txtRunningFlowConfigDesc": "Description de la configuration de Flow en cours d'exécution",
  "txtRuntimeConfig": "Configuration d'exécution",
  "txtRuntimeConfigDesc": "Configurer les paramètres de la console et du tableau de bord",
  "txtRussian": "russe",
  "txtSAVE": "SAUVEGARDER",
  "txtSampleCode": "Exemple de code",
  "txtSave": "Sauvegarder",
  "txtSaveChange": "Sauvegarder les modifications",
  "txtSaveChanges": "Sauvegarder les modifications",
  "txtScheduleDeleteProfilesSuccess": "Planifier la suppression des profils avec succès",
  "txtScheduleForBulkDeletion": "Planification de la suppression groupée",
  "txtScope": "Portée",
  "txtSearchActivityLogs": "Rechercher par nom d'utilisateur/e-mail",
  "txtSearchByEmail": "Rechercher par email",
  "txtSearchByNameOrClientId": "Rechercher par nom ou identifiant client",
  "txtSearchCountry": "rechercher un pays",
  "txtSearchPlaceHolder": "Recherche...",
  "txtSeconds": "seconde(s)",
  "txtSeeList": "Voir liste.",
  "txtSelectACryptoAddress": "Sélectionnez une adresse cryptographique",
  "txtSelectAPlan": "Sélectionnez un forfait",
  "txtSelectAPlanToUnlock": "AMÉLIOREZ VOTRE FORFAIT",
  "txtSelectCryptoAddress": "Veuillez sélectionner une adresse cryptographique.",
  "txtSelectIdentityAttributes": "Attributs d'identité sélectionnés",
  "txtSelectPlan": "Sélectionnez le plan",
  "txtSelfie": "Selfie",
  "txtSendInvite": "Envoyer une invitation",
  "txtSendInviteEmail": "Invitation envoyée! Il est valable 3 jours",
  "txtServiceAccountant": "Comptable",
  "txtServiceAccountantTooltip": "Peut uniquement afficher l’onglet Utilisation et les paramètres du plan dans la console d’administration.",
  "txtServiceAllowBlockpassAccess": "Autoriser l'accès Blockpass",
  "txtServiceBillingOwner": "Propriétaire de facturation",
  "txtServiceBillingOwnerTooltip": "Toutes les autorisations dans la console et le tableau de bord. Peut mettre à jour les informations sur l'entreprise et le mode de paiement.",
  "txtServiceClientIdIsExisted": "L'ID client existe déjà, veuillez utiliser un autre ID client",
  "txtServiceCompanyInfomation": "informations sur l'entreprise",
  "txtServiceDetail": "Détail du service",
  "txtServiceEmailIsRequired": "Un e-mail d'assistance est requis",
  "txtServiceListInvalidPage": "Valeur de pagination invalide",
  "txtServiceLogo": "Logo des services",
  "txtServiceLogoDesc": "Vous pouvez télécharger un logo pour votre service maintenant ou le mettre à jour plus tard dans Paramètres.",
  "txtServiceName": "Nom du service",
  "txtServiceNameIsRequired": "Le nom du service est requis",
  "txtServiceNameOrClientId": "Nom du service ou ID client",
  "txtServiceNameOrClientIdPlaceholder": "Entrez le nom du service ou l'ID client",
  "txtServiceNameTooltip": "Choisissez un nom pour votre service (max 64 caractères)",
  "txtServiceNotFound": "Service non trouvé.",
  "txtServiceOwner": "Directeur",
  "txtServiceOwnerTooltip": "Toutes les autorisations dans la console et le tableau de bord.",
  "txtServicePortalIsRequired": "Un portail d'assistance est requis",
  "txtServiceReadOnlyRole": "Lecture seulement",
  "txtServiceReadOnlyRoleTooltip": "Ne peut afficher que les profils. Impossible de traiter le KYC ou de mettre à jour les paramètres",
  "txtServiceReviewerTooltipkyc": "Ne peut traiter que KYC. Impossible d'approuver, de supprimer des profils ou de mettre à jour les paramètres",
  "txtServiceReviewerkyc": "Réviseur KYC",
  "txtServiceTags": "Balises de service",
  "txtServiceTagsDes": "Ajouter ou supprimer des balises pour ce service",
  "txtServices": "Prestations de service",
  "txtServicesUsage": "Utilisation récente des services",
  "txtServicesUsageDesc": "le processus d'envoi de factures aux gens leur demandant de payer l'argent dû",
  "txtSettings": "Paramètres",
  "txtShareableDesc": "Cette page permet aux candidats d'envoyer leurs informations KYC à votre tableau de bord. Copiez et collez le lien dans un e-mail ou à tout autre endroit où vous souhaitez le partager.",
  "txtShareableLink": "Lien partageable",
  "txtShareableLinkMustBeSetIfUsingRestrictedShareableMethod": "Lien partageable manquant",
  "txtShortDescription": "Brève description",
  "txtShortDescriptionTooltip": "Un court texte décrivant votre service (texte non formaté uniquement)",
  "txtShowLess": "Montrer moins",
  "txtShowMore": "Montre plus",
  "txtShowRefId": "Afficher la fidélité",
  "txtShowSession": "Séance de spectacle",
  "txtShowThisService": "Afficher ce service",
  "txtShowThisServiceDes": "Activez ou désactivez ce service pour l'afficher sur la place de marché.",
  "txtSolanaAddress": "Adresse de Solana (SOL)",
  "txtStartDate": "Date de début",
  "txtStartingAt": "À partir de",
  "txtStartingAtTooltip": "Informations supplémentaires non disponibles.",
  "txtStatus": "Statut",
  "txtStringMaxLengthError": "Doit contenir <0> caractères ou moins",
  "txtStripeError": "Une erreur s'est produite lors de la vérification de vos informations de facturation. Veuillez nous contacter pour obtenir de l'aide.",
  "txtSubscribe": "S'abonner",
  "txtSubscribeTo": "Abonnez-vous à notre newsletter",
  "txtSubscribed": "Abonné",
  "txtSubscription": "Abonnement",
  "txtSupport": "Soutien",
  "txtSupportPortal": "URL de votre portail Web d'assistance",
  "txtSupportPortalTooltip": "URL du site Web de votre portail de service client",
  "txtSupportrEmail": "Votre e-mail d'assistance",
  "txtSupportrEmailTooltip": "Adresse e-mail du service client. Cette adresse est utilisée si vous ne fournissez pas d'URL de portail d'assistance. L'adresse par défaut est support@blockpass.org",
  "txtTags": "Mots clés)",
  "txtTaxNumberWrongFormat": "Format invalide.",
  "txtTeam": "Équipe",
  "txtTestService": "Service d'essais",
  "txtTheRequestWasMadeButNoResponseWasReceived": "Erreur réseau",
  "txtThisFieldIsRequired": "Ce champ est obligatoire.",
  "txtTitleAllow": "Pays autorisés",
  "txtTitleExclude": "Pays exclus",
  "txtToGetStart": "Pour commencer, veuillez vous connecter.",
  "txtToday": "Aujourd'hui",
  "txtTools": "Outils",
  "txtTooltipAddressCountryRestriction": "L'adresse est requise si les restrictions de pays sont activées",
  "txtTooltipAddressProofOfAddress": "L'adresse est requise si un justificatif de domicile est sélectionné",
  "txtTotal": "Total",
  "txtTotalForMonth": "Total pour le mois ",
  "txtTwoFaBannerDesc": "Pour protéger vos données, nous exigeons désormais l'authentification à deux facteurs (2FA). Vous pouvez l'activer maintenant ou il vous sera demandé de l'activer lors de votre prochaine connexion.",
  "txtTwoFaBannerTitle": "Sécurisez votre compte avec 2FA",
  "txtTwoFaButtonEnableNow": "Activer 2FA maintenant",
  "txtTypeSomthing": "Tapez quelque chose...",
  "txtUSDperCheck": "USD/chèque",
  "txtUnAuthorized": "La session est expirée ou invalide, veuillez vous reconnecter",
  "txtUnauthorized": "La session est expirée ou invalide, veuillez vous reconnecter",
  "txtUnlimittedCollaborator": "Collaborateurs illimités",
  "txtUnsubscribe": "Se désabonner",
  "txtUpdate": "Mise à jour",
  "txtUpdateAPIKey": "Mettre à jour la clé API",
  "txtUpdateApiKey": "Mettre à jour la clé API",
  "txtUpdateApiKeySuccess": "Clé API mise à jour",
  "txtUpdateApproveMode": "Mettre à jour le mode d'approbation",
  "txtUpdateBillingPlanDisplay": "Mettre à jour l'affichage du plan de facturation",
  "txtUpdateBillingPlanKycBot": "Mettre à jour la configuration du bot KYC",
  "txtUpdateBillingPlanVerification": "Mettre à jour la configuration du contrôle KYC",
  "txtUpdateBpOperatorRole": "Changer de rôle",
  "txtUpdateCertificate": "Certificat mis à jour",
  "txtUpdateCheckConfig": "Mettre à jour la configuration de vérification",
  "txtUpdateCompany": "Mettre à jour l'entreprise",
  "txtUpdateCompanyInfoDesc": "Mettez à jour les informations sur l'entreprise.",
  "txtUpdateEnterpriseDesc1": "Stockage gratuit des données personnelles",
  "txtUpdateFlowConfig": "Mettre à jour la configuration du flux",
  "txtUpdateFreePlanDescription": "Vous recevez des identités non vérifiées. Conservation des données : 1 semaine",
  "txtUpdateIdentityField": "Champ d'identité mis à jour",
  "txtUpdateInfo": "Mettre à jour les informations",
  "txtUpdateKYCSettings": "Paramètres KYC mis à jour",
  "txtUpdateNormalDesc1": "Conservation des données personnelles : jusqu’à 4 semaines",
  "txtUpdateOperator": "Opérateur de mise à jour",
  "txtUpdateOperatorSuccess": "Mettre à jour l'opérateur avec succès.",
  "txtUpdatePlanDescription1": "Conservation des données personnelles : jusqu’à 4 semaines",
  "txtUpdatePlanDescription2": "Le temps de traitement peut varier. Standard ",
  "txtUpdatePlanSuccess": "Mettre à jour le plan de facturation du service avec succès",
  "txtUpdateRuntimeCfg": "Configuration d'exécution mise à jour",
  "txtUpdateService": "Service mis à jour",
  "txtUpdateServiceSuccess": "Toutes les modifications enregistrées",
  "txtUpdateWebhook": "Mettre à jour le webhook",
  "txtUpdateWebhookSuccess": "Webhook mis à jour",
  "txtUploadALogo": "Télécharger un logo ",
  "txtUrlPrivacyPolicy": "URL de votre politique de confidentialité",
  "txtUrlPrivacyPolicyTooltip": "Un lien vers votre politique de confidentialité sera affiché avant l'enregistrement de l'utilisateur",
  "txtUrlTermsAndConditions": "URL de vos conditions générales",
  "txtUrlTermsAndConditionsTooltip": "Un lien vers vos conditions générales sera affiché avant l'enregistrement de l'utilisateur",
  "txtUsage": "Usage",
  "txtUsageMetrics": "Métriques d'utilisation",
  "txtUserDebugger": "Débogueur utilisateur",
  "txtUserDebuggerDesc": "Rechercher des utilisateurs par e-mail ou supportId",
  "txtUserId": "ID de l'utilisateur",
  "txtUserSessionExpired": "Session utilisateur expirée",
  "txtUserSessionExpiredDesc": "Vous avez été déconnecté. Veuillez vous reconnecter",
  "txtUsername": "Nom d'utilisateur",
  "txtUsernameOrEmail": "Nom d'utilisateur ou email",
  "txtUsernameOrEmailPlaceholder": "Entrez le nom d'utilisateur ou l'e-mail",
  "txtVATNumber": "numéro de TVA",
  "txtVerification": "Vérifications",
  "txtVerificationGroup": "1. Vérifications",
  "txtVerifications": "Vérifications",
  "txtViewDetails": "Voir les détails",
  "txtVisitThePage": "Visitez la page",
  "txtWEEK": "Semaine",
  "txtWarningApprovalMode": "Veuillez confirmer le mode d'approbation avant de passer à l'étape suivante",
  "txtWavesAddress": "Adresse des vagues",
  "txtWebhooks": "Webhooks",
  "txtWebsite": "URL de votre site Web",
  "txtWebsiteIntegration": "Intégration de widgets",
  "txtWebsiteTooltip": "Lien vers votre site officiel",
  "txtWebsiteUrlIsRequired": "Merci de remplir ce champ",
  "txtWelcomeBody": "Créez, configurez et surveillez des services pour vérifier l'identité de vos clients.",
  "txtWelcomeTitle": "Bienvenue dans la console d'administration Blockpass",
  "txtWelcomeTo": "Bienvenue à",
  "txtWhoCanResgister": "Qui peut s'inscrire ?",
  "txtWillBeDeleted": "sera supprimé. ",
  "txtYEAR": "Année",
  "txtYesterday": "Hier",
  "txtYourApiKey": "Votre clé API",
  "txtYourCard": "Votre carte",
  "txtYourCompanyInfo": "Renseignements sur votre entreprise",
  "txtYourEmailAddress": "Votre adresse e-mail",
  "txtYourLink": "Votre lien",
  "txtYourLinkCopiedToClipboard": "Votre lien copié dans le presse-papier",
  "txtYourPaymentMethod": "Votre mode de paiement",
  "txtYourPaymentMethodGroup": "4. Mode de paiement",
  "txtYourServiceConfigCopiedToClipboard": "Votre configuration de service copiée dans le presse-papiers",
  "txtYourServiceCreated": "Votre service a été créé",
  "txtYourServiceLink": "Votre lien de service",
  "txtYourWebsite": "https://votre-site-web.com",
  "txtYourWebsiteUrl": "L'adresse URL de votre site",
  "txtYourWebsiteUrlDesc": "Veuillez saisir l'URL du site Web sur lequel vous avez installé le widget",
  "txtdelete": "supprimer",
  "txtdonwloadMonitorReport": "Télécharger le rapport du moniteur",
  "txtsettingUpdated": "Paramètre mis à jour"
} // eslint-disable-line no-template-curly-in-string