import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { observer } from 'mobx-react-lite'

// import Slide from '@/components/Slide'
import useRootStore from '@hooks/useRootStore'

import Page404 from './404/Page404'
import Page403 from './403/Page403'
import ServiceList from './ServiceList'
import ServiceDetail from './ServiceDetail'
import AdminMenu from './AdminMenu'
import Welcome from './Welcome'
// micro
import SmartEngineUI from './SmartEngineUi/SmartEngineUI'
// import ServiceWebhook from './ServiceWebhook/ServiceWebhook'
import AdminBillingConsole from './AdminBillingConsole/AdminBillingConsole'
import AdminCheckKyc from './AdminCheckKyc/AdminCheckKyc'
import AdminFlowConfig from './AdminFlowConfig/AdminFlowConfig'
import AdminRunningFlow from './AdminRunningFlow/AdminRunningFlow'
import SystemLogs from './SystemLogs/SystemLogs'
import ActivityLogs from './ActivityLogs/ActivityLogs'
import Company from './Company/Company'

import ListingRunningCheck from './ListingRunningCheck/ListingRunningCheck'
import IdentityField from './IdentityField/IdentityField'
import IdentityGroup from './IdentityGroup/IdentityGroup'
import AdminMarketplace from './AdminMarketplace/AdminMarketplace'

import Features from '../constants/features'
// // Ext:
import { pagesExtFunc } from '../extends'

const RouteTables = pagesExtFunc([
  // Unauthorize
  {
    key: 1,
    path: '/',
    extra: {},
    scope: ['admin', 'operator', 'user', 'bpReadOnly', 'supporter'],
    Component: ServiceList,
  },
  {
    key: 2,
    path: '/services',
    extra: {},
    scope: ['admin', 'operator', 'user', 'bpReadOnly', 'supporter'],
    Component: ServiceList,
  },
  {
    key: 3,
    path: '/administration',
    extra: {},
    scope: ['admin'],
    Component: AdminMenu,
  },
  {
    key: 4,
    path: '/administration/billingconsole',
    extra: {},
    scope: ['admin'],
    Component: AdminBillingConsole,
  },
  {
    key: 5,
    path: '/administration/checkkyc',
    extra: {},
    scope: ['admin'],
    Component: AdminCheckKyc,
  },
  {
    key: 6,
    path: '/administration/flowconfig',
    extra: {},
    scope: ['admin'],
    Component: AdminFlowConfig,
  },
  {
    key: 7,
    path: '/administration/marketplace',
    extra: {},
    scope: ['admin'],
    Component: AdminMarketplace,
  },
  {
    key: 8,
    path: '/administration/runningflow',
    extra: {},
    scope: ['admin'],
    Component: AdminRunningFlow,
  },
  {
    key: 9,
    path: '/administration/identityfield',
    extra: {},
    scope: ['admin'],
    Component: IdentityField,
  },
  {
    key: 10,
    path: '/administration/identitygroup',
    extra: {},
    scope: ['admin'],
    Component: IdentityGroup,
  },
  {
    key: 11,
    path: '/administration/listingrunningcheck',
    extra: {},
    scope: ['admin'],
    Component: ListingRunningCheck,
  },
  {
    key: 12,
    path: '/administration/billingconsole/:planId',
    extra: {},
    scope: ['admin'],
    Component: AdminBillingConsole,
  },
  {
    key: 13,
    path: '/administration/listingrunningcheck/:checkId',
    extra: {},
    scope: ['admin'],
    Component: ListingRunningCheck,
  },

  {
    key: 14,
    path: '/administration/smartengineui',
    extra: {},
    scope: ['admin'],
    Component: SmartEngineUI,
  },
  {
    key: 15,
    path: '/welcome',
    extra: {},
    scope: ['user'],
    Component: Welcome,
  },
  {
    key: 16,
    path: '/administration/systemlog',
    extra: {},
    scope: ['admin', 'operator'],
    Component: SystemLogs,
  },
  {
    key: 17,
    path: '/logs',
    extra: {},
    scope: ['user', 'supporter'],
    Component: ActivityLogs,
  },
  {
    key: 18,
    path: '/services/update/:serviceId',
    extra: {},
    scope: ['admin', 'operator', 'user', 'bpReadOnly', 'supporter'],
    Component: ServiceDetail,
  },
  {
    key: 19,
    path: '/403',
    extra: {},
    scope: ['admin', 'operator', 'user', 'bpReadOnly', 'supporter'],
    Component: Page403,
  },
  {
    key: 20,
    path: '/companies',
    extra: {},
    scope: ['admin', 'operator', 'user', 'supporter'],
    Component: Company,
  },
  {
    key: 21,
    path: '/kyb/services',
    extra: {},
    scope: ['admin', 'operator', 'user', 'bpReadOnly', 'supporter'],
    Component: ServiceList,
  },
  {
    key: 22,
    path: '/kyb/services/update/:serviceId',
    extra: {},
    scope: ['admin', 'operator', 'user', 'bpReadOnly', 'supporter'],
    Component: ServiceDetail,
  },
])

const RouteTransitionWrapper = ({ children, lastAction }: any) => {
  if (!Features.ANIMATION_EXPERIMENT) return children

  return (
    <div>{children}</div>
  )
}

const PageRoutes: React.FC<{ PageContainerComp: React.FC }> = observer(
  function PageRoutes({ PageContainerComp }) {
    const { AuthStore, RouteStore, isBootCompleted, DashboardStore } =
      useRootStore()

    const activeRoutes = RouteTables.filter((itm: any) =>
      AuthStore.hasPermission(itm.scope)
    )
    RouteStore.activeRoutes = activeRoutes.map((itm: any) => itm.path)
    DashboardStore.activeHeader = RouteStore.getActiveMenu() < 0 ? 0 : RouteStore.getActiveMenu()
    // Waiting for boot completed and SplashAnimation complete
    // if (!isBootCompleted || !isSplashAnimCompleted) return <PageSplash />
    if (RouteStore.location.pathname) {
      if (RouteStore.location.pathname.includes('/kyb/')) {
        DashboardStore.isKYBService = true
      } else {
        DashboardStore.isKYBService = false
      }
    }
    return (
      <Switch location={RouteStore.location}>
        {isBootCompleted &&
          activeRoutes.map(({ key, path, extra, Component }: any) => (
            <Route
              key={key}
              exact
              path={path}
              {...extra}
              render={({ match }: any) => {
                RouteStore.params = match.params
                return (
                  <RouteTransitionWrapper
                    lastAction={RouteStore.lastAction}
                    path={path}
                  >
                    <PageContainerComp>
                      <Component />
                    </PageContainerComp>
                  </RouteTransitionWrapper>
                )
              }}
            />
          ))}
        <Route>
          <PageContainerComp>
            <Page404 />
          </PageContainerComp>
        </Route>
      </Switch>
    )
  }
)

export default PageRoutes
