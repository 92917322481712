
import { useEffect, useState } from 'react'

import {
  KYB_SERVICE_DISPLAY_KEY, SERVICE_DISPLAY_KEY, ACTIVITY_LOGS_KEY, COMPANY_KEY, SERVICE_ADMIN_KEY,
  SERVICE_APIKEY_KEY, SERVICE_BILLING_KEY, SERVICE_TEAM_KEY, SERVICE_WEBHOOK_KEY, SERVICE_USAGE_KEY
} from '@constants/constants'
import CircularProgress from '@mui/material/CircularProgress';
import { observer } from 'mobx-react-lite'
import Cookies from 'js-cookie'
import Box from '@mui/material/Box';
import useRootStore from '@hooks/useRootStore'
import { COLOR } from '@theme/colors'
import makeStyles from '@mui/styles/makeStyles';
import { toJS } from 'mobx'
import get from 'lodash/get'
import { pushDataLayer } from '@utils/index'
import MicroFrontendCache from '../../MicroFrontendCache'
import loadable from '@loadable/component'
const MicroFrontend = loadable(() => import('../../MicroFrontend'))

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: COLOR.bgColorSecondary,
    margin: 0,
    height: 'auto',
    width: "100%",
  },

}))

const SystemLogs = observer(function SystemLogs() {
  const [verifying, setVerifying] = useState(false);

  useEffect(() => {
    const timing = get((window as any), 'performance.timing', {})
    let pageloadtime = timing.loadEventStart - timing.navigationStart;


    if (!DashboardStore.sentLoadingTime) {
      pushDataLayer({
        event: 'pageloadtime',
        env: 'Desktop',
        screen_name: 'Activity Logs',
        session: 'Performance Timings',
        label: '',
        value: pageloadtime,
      })
      DashboardStore.sentLoadingTime = true
    } else {
      if (DashboardStore.timeStart !== 0) {
        let timeRouteChange = Date.now() - DashboardStore.timeStart
        pushDataLayer({
          event: 'route_change_time',
          env: 'Desktop',
          screen_name: 'Activity Logs',
          session: 'Performance Timings',
          label: '',
          value: timeRouteChange,
        })
      }
    }


    DashboardStore.hideFooter = false
    fetchData()
  })

  const classes = useStyles()

  const {
    RouteStore,
    LocalizationStore,
    DashboardStore,
    UINotificationStore,
    AuthStore
  } = useRootStore()
  const { containerStore, } = DashboardStore
  const microObj = DashboardStore.getMicroConfigDetail(
    ACTIVITY_LOGS_KEY
  )
  const handleAuthorizeError = async (code) => {
    try {
      if (code === 401) { //expired
        //show popup -> onAction -> setAuthenticated = false
        AuthStore.setOpenSessionExpired(true)
      } else { //unauthorized
        RouteStore.push('/403')
      }
    } catch (error) {
      setVerifying(false)
    }
  }

  const handleShowSnackbar = async (options) => {
    UINotificationStore.showSnackbar(options)
  }
  const fetchData = async () => {
    try {
      const { microLoaded } = DashboardStore
      if (microLoaded) {
        return
      }
      const microObj = DashboardStore.getMicroConfigDetail(
        ACTIVITY_LOGS_KEY
      )
      const token = AuthStore.accessToken || ''
      containerStore.baseUrl = (microObj && microObj.backendHost) || ''
      containerStore.accessToken = token
      containerStore.language = LocalizationStore.activeLanguage || 'en'
      containerStore.onAuthorizeError = handleAuthorizeError
      containerStore.onShowSnackbar = handleShowSnackbar

      containerStore.acHost = (microObj && microObj.acHost) || ''
      const serviceInfo = DashboardStore.getServiceInfo()
      containerStore.service = { ...toJS(serviceInfo) }
      containerStore.profile = AuthStore.getMicroUserProfile(DashboardStore.getClientId()) || {}
      // containerStore.pushDataLayer = pushDataLayer

      DashboardStore.microLoaded = true
      setVerifying(false)

    } catch (error) {
      Cookies.set('logged_in', 'no')
      AuthStore.setAuthenticated(false)
      setVerifying(false)
    }
  }
  // update - relogin 
  const token = AuthStore.accessToken || ''
  containerStore.accessToken = token
  containerStore.onAuthorizeError = handleAuthorizeError
  containerStore.onShowSnackbar = handleShowSnackbar
  containerStore.acHost = (microObj && microObj.acHost) || ''
  const serviceInfo = DashboardStore.getServiceInfo()
  containerStore.service = { ...toJS(serviceInfo) }
  containerStore.profile = AuthStore.getMicroUserProfile(DashboardStore.getClientId()) || {}


  const host = (microObj && microObj?.host) || ''
  const microObjCompany = DashboardStore.getMicroConfigDetail(
    COMPANY_KEY
  )
  const hostCompany = (microObjCompany && microObjCompany?.host) || ''
  //servie detail
  const microObjDisplay = DashboardStore.getMicroConfigDetail(
    SERVICE_DISPLAY_KEY
  )
  const hostDisplay = (microObjDisplay && microObjDisplay?.host) || ''

  const microObjApi = DashboardStore.getMicroConfigDetail(
    SERVICE_APIKEY_KEY
  )
  const hostApi = (microObjApi && microObjApi?.host) || ''

  const microObjBilling = DashboardStore.getMicroConfigDetail(
    SERVICE_BILLING_KEY
  )
  const hostBilling = (microObjBilling && microObjBilling?.host) || ''

  const microObjTeam = DashboardStore.getMicroConfigDetail(
    SERVICE_TEAM_KEY
  )
  const hostTeam = (microObjTeam && microObjTeam?.host) || ''

  const microObjWebhook = DashboardStore.getMicroConfigDetail(
    SERVICE_WEBHOOK_KEY
  )
  const hostWebhook = (microObjWebhook && microObjWebhook?.host) || ''

  const microObjUsage = DashboardStore.getMicroConfigDetail(
    SERVICE_USAGE_KEY
  )
  const hostUsage = (microObjUsage && microObjUsage?.host) || ''

  const microObjKybDisplay = DashboardStore.getMicroConfigDetail(
    KYB_SERVICE_DISPLAY_KEY
  )
  const hostKybDisplay = (microObjKybDisplay && microObjKybDisplay?.host) || ''

  const microObjAdmin = DashboardStore.getMicroConfigDetail(
    SERVICE_ADMIN_KEY
  )
  const hostAdmin = (microObjAdmin && microObjAdmin?.host) || ''

  try {
    return (
      <Box className={classes.container}>
        {host === '' || verifying || !DashboardStore.microLoaded ? (
          <div >
            <div style={{ position: 'relative', width: '100%', height: "768px" }}>
              <Box style={{ position: 'absolute', top: '50%', left: "50%", marginTop: '-24px' }} >
                <CircularProgress />
              </Box>
            </div>
          </div>
        ) : (
          <>
            <MicroFrontendCache
              history={window.history}
              host={hostDisplay}
              name={microObjDisplay?.id || ''}
              containerStore={{}}
            />
            <MicroFrontendCache
              history={window.history}
              host={hostCompany}
              name={microObjCompany?.id || ''}
              containerStore={{}}
            />

            <MicroFrontendCache
              history={window.history}
              host={hostApi}
              name={microObjApi?.id || ''}
              containerStore={{}}
            />
            <MicroFrontendCache
              history={window.history}
              host={hostTeam}
              name={microObjTeam?.id || ''}
              containerStore={{}}
            />
            <MicroFrontendCache
              history={window.history}
              host={hostBilling}
              name={microObjBilling?.id || ''}
              containerStore={{}}
            />
            <MicroFrontendCache
              history={window.history}
              host={hostWebhook}
              name={microObjWebhook?.id || ''}
              containerStore={{}}
            />
            <MicroFrontendCache
              history={window.history}
              host={hostUsage}
              name={microObjUsage?.id || ''}
              containerStore={{}}
            />
            <MicroFrontendCache
              history={window.history}
              host={hostKybDisplay}
              name={microObjKybDisplay?.id}
              containerStore={containerStore}
            />
            <MicroFrontendCache
              history={window.history}
              host={hostAdmin}
              name={microObjAdmin?.id}
              containerStore={{}}
            />
            <MicroFrontend
              history={window.history}
              host={host}
              name={microObj.id}
              containerStore={containerStore}
            />
          </>

        )}
      </Box>
    )
  } catch (error) {
    console.error('Micro fronted error: ', error)
    //redirect to root page
    // document.location.href = "/";

    // reload page
    window.location.reload()
  }
})

export default SystemLogs

