/* eslint-disable */
// prettier-ignore
export default {
  "txPersonalAttributeDesc": "オンボーディング中にユーザーに追加情報を要求できます。これらの属性は Blockpass によって検証されません。",
  "txt2FA": "2FA",
  "txt404": "お探しのページが見つかりません!",
  "txtAML": "AML",
  "txtAMLCheck": "AMLチェック",
  "txtAMLDesc1": "PEP/制裁リストと不利なメディアのグローバル データベースを照合して、顧客が政治的に暴露されているか、制裁を受けているか、または不利なメディア プロフィールを持っているかどうかを特定します。",
  "txtAMLDesc2": "このチェックでは、ユーザーはフルネームと生年月日を入力する必要があります。",
  "txtAPIAndWebhooks": "APIとWebhook",
  "txtAccountDeletion": "アカウントの削除",
  "txtAccountDeletionDesc": "モバイルアカウントとウェブアカウントを削除する",
  "txtAction": "アクション",
  "txtActive": "アクティブ",
  "txtActivityLogs": "アクティビティログ",
  "txtAdd": "追加",
  "txtAddCompany": "会社を追加",
  "txtAddMember": "メンバーを追加",
  "txtAddMemberDesc": "メンバーは、このサービスへのアクセスを許可する電子メールを受け取ります。",
  "txtAddMemberEmailIsRequired": "会員のメールアドレスを入力してください。",
  "txtAddMembers": "メンバーを追加する",
  "txtAddNewBpOperatorToService": "割当",
  "txtAddNewCompany": "新しい会社を追加",
  "txtAddNewOperatorSuccess": "新しい演算子が正常に追加されました。",
  "txtAddNewsContent": "ニュースを追加",
  "txtAddNotification": "通知を追加",
  "txtAddOperatorToService": "オペレーターをサービスに追加する",
  "txtAddPaymentMethod": "支払い方法を追加する",
  "txtAddPaymentMethodFail": "ここで何かが間違っています。後で支払い方法を追加してください。",
  "txtAddPaymentMethodSuccessfully": "支払いカードが正常に追加されました",
  "txtAddRichContent": "リッチコンテンツの追加",
  "txtAddTag": "タグ付けする",
  "txtAddTagPlaceholder": "タグのラベルを入力...",
  "txtAddTagSuccess": "「<0>」タグが正常に追加されました。",
  "txtAddingTag": "タグの追加 ",
  "txtAdmin": "管理者",
  "txtAdminConsole": "管理コンソール",
  "txtAdminSettingsUpdateSuccess": "管理者設定が正常に更新されました。",
  "txtAdministration": "管理",
  "txtAdvancedApprovalDesc": "高度なKYCボット： ",
  "txtAdvancedApprovalDesc1": "ユーザーを自動承認または拒否するための 10 個以上のルールのセット。",
  "txtAdvancedApprovalTooltip": "このプランでは高度なKYCボットは利用できません。有効にしたい場合は、お問い合わせください。",
  "txtAfterReturn": "API がデータを返した後 ",
  "txtAgeRestriction": "年齢制限",
  "txtAgeRestrictionDesc1": "年齢に基づいて応募者を除外できます。 ",
  "txtAgeRestrictionDesc2": "申請者は少なくとも",
  "txtAgeRestrictionDesc3": "歳。",
  "txtAllPlanFeatureHeader": "すべてのプランには以下の機能が含まれます",
  "txtAllTimeAreUTC": "(時間はすべて UTC)",
  "txtAllowed": "許可された",
  "txtAllowedCountries": "許可された国",
  "txtAnnouncements": "お知らせ",
  "txtAnyoneWithTheLink": "リンクを知っている人は誰でも",
  "txtAnyoneWithTheLinkDesc1": "このリンクを知っている人は誰でもサービスに登録できます。",
  "txtAnyoneWithTheLinkDesc2": "このページでは、申請者が自分の KYC 情報をダッシュ​​ボードに送信できます。リンクをコピーして、電子メールまたは共有したい場所に貼り付けます。",
  "txtApiKey": "APIキー",
  "txtApiKeyCopied": "API キーがコピーされました",
  "txtApiKeyDeleteDesc": "API キーは削除すると復元できません。キーを削除できるかどうか完全に確信が持てない場合は、代わりにステータスを非アクティブに設定できます。",
  "txtApiKeys": "APIキー",
  "txtApply": " 適用する。",
  "txtApproveAdvanced": "高度なKYCボット",
  "txtApproveBasic": "KYCボット",
  "txtApproveDesc1": "レビューのモードを選択すると時間を節約できます。 \n",
  "txtApproveDesc2": "KYC ボットは、大量のユーザーに推奨されます。見る ",
  "txtApproveDesc3": "さらに詳しく",
  "txtApproveDesc4": " ドキュメントの中で",
  "txtApproveFreeManual": "手動: 各ユーザーを手動で承認または拒否します。オンボーディング プロセスをスピードアップするプランを選択してください。",
  "txtApproveNone": "手動レビュー",
  "txtApproverTooltipkyc": "KYCを確認および承認できます。プロファイルの削除や設定の更新ができない",
  "txtApproverkyc": "KYC承認者",
  "txtAssignCompanyToService": "会社情報を追加",
  "txtAssignRole": "割り当てられた役割",
  "txtAssignUserRole": "割り当てられた役割",
  "txtAttributeDataDesc": "プロファイルがアーカイブされると、メタデータのみが残り、アイデンティティ属性は削除されます。",
  "txtAttributesConfig": "属性構成",
  "txtAttributesConfigDesc": "ユーザープロファイルで使用可能な属性を構成する",
  "txtAttributesGroupConfig": "属性グループ構成",
  "txtAttributesGroupConfigDesc": "ユーザープロファイルで使用可能な属性のグループを構成する",
  "txtAutoApproval": "自動承認",
  "txtAutoArchive": "ユーザーのプロフィールを自動アーカイブする",
  "txtAutoArchiveTooltip": "API がデータを返してから数秒後にユーザーのプロフィール @@ar_ch_ti_me@@ を自動アーカイブします",
  "txtAutoTranslate": "(自動翻訳)",
  "txtAutomationRules": "自動化ルール",
  "txtAvatar": "アバター",
  "txtAvaxAddress": "アバランチアドレス",
  "txtBack": "戻る",
  "txtBasicApprovalDesc": "KYCボット:  ",
  "txtBasicApprovalDesc1": "明確なチェックを付けてユーザーを自動承認します。",
  "txtBasicInfo": "基本情報",
  "txtBestPractices": "ベストプラクティス",
  "txtBilling": "請求する",
  "txtBillingInfo": "請求情報",
  "txtBillingInfomation": "請求情報",
  "txtBillingOwner": "請求所有者",
  "txtBinanceAddress": "バイナンス（BSC）アドレス",
  "txtBitcoinAddress": "ビットコインアドレス",
  "txtBlockpass": "Blockpass",
  "txtBlockpassAccess": "ブロックパスアクセス",
  "txtBlockpassAccessDesc": "Blockpass サポート チームが KYC ダッシュボードにアクセスできるようにする",
  "txtBlockpassAccessPermission": "この機能にアクセスする権限がありません",
  "txtBlockpassKycRules": "ブロックパスKYCルール",
  "txtBpKYCRules": "ブロックパスKYCルール",
  "txtBrowse": "ブラウズ",
  "txtBtcAdress": "ビットコインアドレス",
  "txtBusinessInfomation": "ビジネス情報",
  "txtBusinessTerms": "ビジネス用語",
  "txtCanNotSetTagsOnChainKYCForMultipleCryptoService": "複数の暗号フィールドがあるサービスに対して onchainKyc タグを設定できない",
  "txtCancel": "キャンセル",
  "txtCardHolderName": "クレジットカード名義人氏名",
  "txtCardInfomation": "カード情報",
  "txtCardanoAddress": "カルダノ (ADA) アドレス",
  "txtCaution": "注意 ！",
  "txtCautionDesc1": "現在ユーザーをオンボーディングしている場合、プランを変更することはお勧めできません。サポートが必要な場合は、お問い合わせください。",
  "txtCautionDesc2": "それ以外の場合は、設定を続行できます。",
  "txtCertificatesOrderConfig": "証明書の順序構成",
  "txtCertificatesOrderConfigDesc": "ユーザープロファイルで証明書の順序を構成する",
  "txtChangeBillingPlan": "料金プランの変更",
  "txtChangePlan": "プラン変更",
  "txtChangeWebhook": "Webhook を更新しました",
  "txtChartEmptyTooltip": "このグラフは、サービスがユーザーによって使用されると作成されます。",
  "txtCheckDebugger": "デバッガをチェックする",
  "txtCheckDebuggerDesc": "小切手IDで小切手を検索します。通常、小切手は証明書を発行します",
  "txtChecksConfig": "構成をチェックします",
  "txtChecksConfigDesc": "チェックを定義する属性と証明書を構成する",
  "txtChinese": "中国語",
  "txtClickOrDragToUpload": "クリックまたはドラッグしてロゴをアップロードします",
  "txtClientId": "クライアントID",
  "txtClientIdExisted": "クライアント ID はすでに存在します。別のクライアント ID を使用してください",
  "txtClientIdTooltip": "サービスの一意の識別子。 \nに基づいて自動生成されます\nサービス名に",
  "txtClose": "近い",
  "txtCodeHasBeenCopied": "コードがコピーされました",
  "txtCodeSample": "コードサンプル",
  "txtCompany": "会社",
  "txtCompanyAddress": "会社住所",
  "txtCompanyAddressCity": "市",
  "txtCompanyAddressCountry": "国",
  "txtCompanyAddressLine1": "住所1",
  "txtCompanyAddressLine1IsRequired": "会社住所行 1 は必須です。",
  "txtCompanyAddressLine2": "住所行 2 (オプション)",
  "txtCompanyAddressPostalCode": "郵便番号",
  "txtCompanyAddressPostalCodeRequired": "郵便番号は必須です。",
  "txtCompanyAddressState": "州",
  "txtCompanyCity": "市",
  "txtCompanyCountryIsRequired": "国は必須です",
  "txtCompanyCustomRequest": "メッセージ",
  "txtCompanyEmailIsRequired": "会社のメールアドレスは必須です。",
  "txtCompanyGroup": "3. 会社名",
  "txtCompanyInfo": "会社概要",
  "txtCompanyInfomation": "企業情報",
  "txtCompanyName": "会社名",
  "txtCompanyNameIsRequired": "会社名は必須です",
  "txtCompanyNameRepIsRequired": "会社の代表者名は必須です。",
  "txtCompanyNumber": "会社番号",
  "txtCompanyNumberIsRequired": "会社番号は必須です",
  "txtCompanyPhoneRepIsRequired": "会社代表者の電話番号が必要です。",
  "txtCompanyPostalCode": "郵便番号",
  "txtCompanyRepresentative": "会社代表者名",
  "txtCompanyState": "州",
  "txtCompanyVAT": "税または VAT 番号",
  "txtCompanyVATNumber": "税または VAT 番号",
  "txtCompanyVATNumberIsRequired": "会社の VAT 番号は必須です",
  "txtConfiguration": "構成",
  "txtConfirm": "確認する",
  "txtConfirmDeleteCompanyInfo": "前の手順に戻ると、入力した会社情報は消去されます。",
  "txtConnectWithUs": "私達と接続",
  "txtContactSale": "営業担当者にお問い合わせください",
  "txtContactSaleTeam": "当社の営業チームにお問い合わせください",
  "txtContactSupport": "営業担当者にお問い合わせください",
  "txtContent": "コンテンツ",
  "txtContinue": "続く",
  "txtContinueToConfigureService": "サービスの構成を続行します",
  "txtCopied": "コピーしました！",
  "txtCopy": "コピー",
  "txtCopyAndClose": "コピーして閉じる",
  "txtCopyApiKeyWarning": "ポップアップを閉じるとすぐには取得できなくなります。安全な場所に保管してください。 \n紛失した場合は、新しいキーを生成する必要があります。",
  "txtCopyLink": "リンクをコピーする",
  "txtCopyright": "©ブロックパス ",
  "txtCount": "カウント",
  "txtCountryRestriction": "国の制限",
  "txtCountryRestrictionDesc1": "一部の国の居住者を除外したり、他の国の居住者のみを受け入れたりすることができます。このオプションでは、ユーザーは自宅の住所を入力する必要があります。",
  "txtCountryRestrictionDesc2": "ここで示されている国は単なる例です。制限する国については、コンプライアンス担当者に確認する必要があります。",
  "txtCountryRestrictionDescAllow": "～の居住者のみを許可する",
  "txtCountryRestrictionDescExclude": "からの住民を除外する",
  "txtCoutryOfIncorporation": "設立国",
  "txtCreate": "作成する",
  "txtCreateAPIKey": "APIキーの作成",
  "txtCreateAPIKeyFooter1": "API キーを公開すると、ユーザーの個人データが侵害される可能性があります。 ",
  "txtCreateAPIKeyFooter2": "フォローしなければなりません ",
  "txtCreateAPIKeyFooter3": " API キーを安全に使用するため。",
  "txtCreateAccount": "新しいアカウントを作成しました",
  "txtCreateApiKeySuccess": "新しい API キーが作成されました",
  "txtCreateBillingPlan": "料金プランの作成",
  "txtCreateCertificate": "新しい証明書を作成しました",
  "txtCreateCheckConfig": "チェック構成の作成",
  "txtCreateCompany": "会社を作成する",
  "txtCreateFlowConfig": "フロー構成の作成",
  "txtCreateIdentityField": "作成されたIDフィールド",
  "txtCreateKYCSettings": "作成されたKYC設定",
  "txtCreateNewTag": "タグを作成する ",
  "txtCreateRuntimeCfg": "作成されたランタイム構成",
  "txtCreateService": "新しいサービスを作成しました",
  "txtCreateServiceDashboard": "サービスの作成",
  "txtCreateServiceSuccess": "新しいサービスが正常に作成されました",
  "txtCreateVersion": "新しい API バージョンを作成しました",
  "txtCreateWebhook": "作成された Webhook",
  "txtCreatedAt": "作成日",
  "txtCryptoAddresses": "暗号化アドレス",
  "txtCurrentPlan": "現在の計画",
  "txtCustomRegistrationUrl": "カスタム登録URL",
  "txtCustomUrlInvalid": "この URL は無効です。",
  "txtCustomiseAppLink": "モバイル用アプリリンク",
  "txtDAY": "日",
  "txtDOB": "生年月日",
  "txtDashboard": "ダッシュボード",
  "txtDataExport": "データのエクスポート",
  "txtDate": "日付",
  "txtDelete": "消去",
  "txtDeleteAPIKey": "APIキーの削除",
  "txtDeleteApiKeySuccess": "APIキーが削除されました",
  "txtDeleteBulkIdentityRecordConfirmMessage": "これらのアカウントを削除してもよろしいですか?",
  "txtDeleteThisApiKey": "この API キーを削除します",
  "txtDeleteVersion": "削除されたAPIバージョン",
  "txtDeleteWebhook": "削除された Webhook",
  "txtDescription": "説明",
  "txtDescriptionIsRequired": "この API キーの説明を入力してください",
  "txtDetachPaymentMethod": "支払い方法の切り離し",
  "txtDetails": "詳細",
  "txtDisabled": "無効",
  "txtDiscard": "破棄",
  "txtDiscardRichContent": "リッチコンテンツを破棄する",
  "txtDisplay": "画面",
  "txtDoNotSendAddMember": "新しいメンバーを招待する権限がありません。サービス所有者にお問い合わせください。エラーコード403。",
  "txtDocs": "ドキュメント",
  "txtDocumentation": "ドキュメンテーション",
  "txtDonNotHaveService": "この会社にはサービスがありません",
  "txtDontHavePaymentMethod": "お支払い方法がありません。以下にお支払い方法を追加してください",
  "txtDowngradeBpOperator": "ダウングレード",
  "txtDownload": "ダウンロード",
  "txtDownloadLogs": "ログをダウンロードする",
  "txtDrivingLicense": "運転免許証",
  "txtDropFileHere": "ここにロゴをドロップしてください",
  "txtEditClientId": "クライアントIDの編集",
  "txtEditClientIdWarning": "このクライアント ID は後で変更できず、一意である必要があります。",
  "txtEditConfig": "構成の編集",
  "txtEditMembers": "メンバーの編集",
  "txtEditNewsContent": "ニュースを編集する",
  "txtEditSearch": "検索の編集",
  "txtEmail": "Eメール",
  "txtEmailAddress": "電子メールアドレス",
  "txtEmailRepresentative": "会社代表者のメールアドレス",
  "txtEmailWrongFormat": "間違った電子メール形式",
  "txtEnable": "有効にする",
  "txtEnableApiKeys": "API キーを有効にする",
  "txtEnableApiKeysDes": "顧客が API キーを使用してユーザーのデータにアクセスできるようにします。",
  "txtEnableEndUserRegistrations": "エンドユーザー登録を有効にする",
  "txtEnableEndUserRegistrationsDes": "エンドユーザーがこのサービスに検証を登録できるようにします。",
  "txtEnableKycDashboard": "KYCダッシュボードを有効にする",
  "txtEnableKycDashboardDes": "顧客が KYC ダッシュボードにアクセスできるようにします。",
  "txtEnableService": "サービスを有効にする",
  "txtEnableServiceDes": "このオプションは危険です。サービスのオンとオフを頻繁に切り替えると、重大なエラーが発生する可能性があります。",
  "txtEnabled": "有効",
  "txtEnabled2FA": "2FA を有効化",
  "txtEndDate": "終了日",
  "txtEnglish": "英語",
  "txtEnterpriseDesc1": "毎月最低 100 個のプロファイル",
  "txtEnterpriseDesc2": " 詳細については。",
  "txtEnterpriseSentDesc1": "Enterprise プランをお選びいただきありがとうございます。当社の営業チームができるだけ早くご連絡し、お客様の要件に応じてサービスを設定します。 \n",
  "txtEnterpriseSentDesc2": "それまでの間、お支払い方法を追加し、お支払い情報を入力してください。プランを有効にするために必要です。",
  "txtErrorUnsupportFile": "サポートされていないファイル",
  "txtEthAddress": "イーサリアムアドレス",
  "txtEthereumAddress": "イーサリアムアドレス",
  "txtEvent": "イベント",
  "txtExample": "例",
  "txtExcept": "を除外する",
  "txtExcluded": "除外される",
  "txtExcludedCountries": "除外された国",
  "txtExpiryDate": "有効期限",
  "txtExploreKyc": "KYC Connect ダッシュボードを探索します。 ",
  "txtExportServiceConfig": "サービス構成のエクスポート",
  "txtFaceMatch": "顔の一致",
  "txtFaceMatchCheck": "顔一致チェック",
  "txtFaceMatchDesc1": "Blockpass は、ユーザーの ID 上の写真と、登録時に要求されたライブセルフィーを比較します。",
  "txtFaceMatchDesc2": "このチェックでは、ユーザーは身分証明書とライブセルフィーを提供する必要があります。",
  "txtFacematchCheck": "顔一致チェック",
  "txtFailedToSendMemberInvitation": "メンバー招待の送信に失敗しました",
  "txtFamilyName": "苗字",
  "txtFaq": "よくある質問",
  "txtFileNotSupport": "このファイルはサポートされていません",
  "txtFillCompanyInfo": "以下の既存の会社に基づいて情報を入力するか、新しい会社を追加します。",
  "txtFlowAddress": "フロー（FLOW）アドレス",
  "txtFlowDebugger": "フローデバッガ",
  "txtFlowDebuggerDesc": "フロー ID でフローを検索します。フローには複数のチェックを含めることができます",
  "txtFlowsConfig": "フロー構成",
  "txtFlowsConfigDesc": "フローを定義するチェックを構成する",
  "txtForbidden": "このアクションを実行する権限がありません",
  "txtForceAddOperator": "オペレーターをサービスに追加する",
  "txtForceAddOperatorDesc": "サービス所有者が Blockpass を許可していません。オペレーターを割り当てる権限があることを再確認してください",
  "txtFreePlanDescription": "未確認の身元情報を受け取ります。 1日10個限定。",
  "txtFreeTrial": "無料トライアル",
  "txtFrench": "フランス語",
  "txtFunctionUnavailable": "機能が一時的に利用できなくなります",
  "txtGeneralInfomation": "一般情報",
  "txtGeoRestriction": "地理的制限",
  "txtGetServiceDisplayFailed": "サービスの表示に失敗します。",
  "txtGetServiceListFailed": "サービスリストの取得に失敗しました。",
  "txtGetServiceWebhookListFailed": "サービスの取得 Webhook が失敗します。",
  "txtGivenName": "名",
  "txtGoToDashboard": "ダッシュボードに進め",
  "txtGoToServiceDashboard": "サービスダッシュボードに移動",
  "txtGoToServiceList": "後で",
  "txtGotoDashboardDesc1": "このサービスの KYC Connect ダッシュボードに移動します。",
  "txtGotoDashboardDesc2": "ダッシュボードは、個人がサービスにアップロードした ID をチームが確認できる場所です。",
  "txtHelp": "ヘルプ",
  "txtIdEngine": "IDエンジン",
  "txtIdEngineDesc": "テスト ID エンジン OCR ツール",
  "txtIdentityDesc1": "Blockpass はパスポート、国民 ID カード、運転免許証を認証します。 ",
  "txtIdentityDesc2": "ユーザーはドキュメントのライブ写真を撮るように求められます。",
  "txtIdentityDesc3": "このオプションでは、ユーザーは本人確認書類、フルネーム、生年月日を入力する必要があります。",
  "txtIdentityDocument": "身分証明書",
  "txtIdentityDocumentCheck": "本人確認書類の確認",
  "txtIdentityLink1": "サポートされている国のリストを参照",
  "txtImageTooBig": "画像が大きすぎます",
  "txtImgDimensionBetween10242048": "512x512 ～ 2048x2048 ピクセルの画像をアップロードしてください",
  "txtImgDimensionRule": "PNG、JPEG。最小 512x512 ピクセル、最大 2048x2048 ピクセル。",
  "txtImpactChanging": "衝撃充電",
  "txtInactive": "非活性",
  "txtIncludeRawDataExceptPictureTooltip": "写真を除くメタデータと ID 属性を返します",
  "txtIncludeRawDataIncludePictureTooltip": "写真を含むメタデータと ID 属性を返します",
  "txtIncludeRawDataTooltip": "写真を除くメタデータと ID 属性を返します",
  "txtIncluding": "含む",
  "txtIncorrectFormat": "フィールドには通常/大文字、数字、アンダースコア (_) のみを含める必要があります",
  "txtInfomation": "情報",
  "txtInputYourServiceName": "サービス名を入力してください",
  "txtInternalServerError": "サーバーで予期しないエラーが発生しました",
  "txtInvalidEmailFormat": "無効な電子メール形式",
  "txtInvalidEmails": "無効なメール",
  "txtInvalidParams": "リクエストに無効なパラメータが含まれています。入力を再確認してください",
  "txtInvalidURL": "URLが無効です",
  "txtInvalidUrl": "この URL は無効です",
  "txtInvitationRemoved": "招待状が削除されました",
  "txtInvitationResent": "招待状が再送信されました",
  "txtInvitationSent": "招待状が送信されました",
  "txtInviteAlreadySent": "招待はすでに送信されました",
  "txtInviteUser": "招待されたユーザー",
  "txtIsCopiedToClipboard": "クリップボードにコピーされます。",
  "txtIsSuccess": "成功",
  "txtJapanese": "日本語",
  "txtJobId": "ジョブID",
  "txtKYCBot": "KYCボット",
  "txtKYCDashboardAccess": "KYCダッシュボードへのアクセス",
  "txtKybDashboard": "KYBダッシュボード",
  "txtKybService": "KYBサービス",
  "txtKybServiceDesc": "KYBは「Know Your Business」の略です。企業の身元を確認するための KYB サービスを作成します。",
  "txtKybServiceName": "KYBサービス名",
  "txtKybServices": "KYBサービス(WIP)",
  "txtKycDashboard": "KYCダッシュボード",
  "txtKycService": "KYCサービス",
  "txtKycServiceDesc": "KYCは「Know Your Customer」の略です。個人の身元を確認するための KYC サービスを作成します。",
  "txtKycServiceName": "KYCサービス名",
  "txtKycServices": "KYCサービス",
  "txtLast180Days": "過去 180 日間",
  "txtLast30Days": "過去 30 日間",
  "txtLast7Days": "過去 7 日間",
  "txtLast90Days": "過去90日間",
  "txtLastMonth": "先月",
  "txtLastRunAt": "最終実行時刻",
  "txtLatestInvoice": "最新の請求書",
  "txtLatestInvoiceDesc": "請求書は毎月、請求先所有者の電子メール アドレスに送信されます。最後に発行された請求書を以下からダウンロードすることもできます。",
  "txtLearnMoreAboutApiKey": "Blockpass API について詳しく見る ",
  "txtLengthTooShort": "フィールドの長さが短すぎます",
  "txtLink": "リンク",
  "txtListDeleteEmail": "削除したいメールのリスト (カンマ区切り) (最大 50 件)",
  "txtListOfServices": "サービス一覧",
  "txtListingRunningCheck": "リスト実行チェック",
  "txtListingRunningCheckDesc": "実行チェックの説明をリストする",
  "txtLive": "ライブ",
  "txtLockUser": "ロックされたユーザー",
  "txtLogin": "ログイン",
  "txtLogo": "ロゴ",
  "txtLogoDesc": "PNG、JPEG、JPG。 \n最小 512x512 ピクセル、最大 2048x2048 ピクセル",
  "txtLogoRestriction": "画像サイズは 1024 x 1024 ～ 2048 x 2048 ピクセルである必要があります",
  "txtLogoSizeError": "アップロードしたロゴのサイズが正しくありません。",
  "txtLogout": "ログアウト",
  "txtLogs": "ログ",
  "txtLongDescription": "長い説明",
  "txtLongDescriptionTooltip": "サービスの完全な説明 (フォーマットされていないテキストのみ)",
  "txtMONTH": "月",
  "txtMarkerPlace": "市場",
  "txtMarkerPlaceDesc": "マーケットプレイスのエントリーを管理する",
  "txtMarketPlace": "マーケットプレイス - WIP",
  "txtMarketplace": "市場",
  "txtMarketplaceSettingUpdateSuccess": "マーケットプレイスの設定が正常に更新されました。",
  "txtMaticAddress": "ポリゴン (マティック) アドレス",
  "txtMaxEmailsExceeded": "削除ごとに最大 50 件のメール",
  "txtMemberInfomation": "会員情報",
  "txtMetaDataOnlyTooltip": "メタデータ (プロファイル ステータス、ID など) のみを返します。",
  "txtMore": "もっと",
  "txtMoreDetails": "さらに詳しく",
  "txtMoreInfomation": "詳細情報",
  "txtName": "名前",
  "txtNameOfCompany": "会社名",
  "txtNameOfCompanyIsRequired": "会社名は必須です",
  "txtNameOnCard": "カードに記載されている名前",
  "txtNameOnCardIncomplete": "カードの名前が不完全です。",
  "txtNameRepresentative": "会社代表者名",
  "txtNationalID": "国民IDカード",
  "txtNationalId": "国民ID",
  "txtNewCompany": "新会社",
  "txtNewsContent": "ニュースコンテンツ",
  "txtNext": "次",
  "txtNoActivityLogs": "検索に一致するアクティビティはありません",
  "txtNoActivityLogsDesc": "具体性を低くするか、別のキーワードを使用してみてください。",
  "txtNoApiKeyDesc": "API キーは、メタデータ、個人情報、画像などの個人の ID を抽出するために必要です。 ",
  "txtNoApiKeys": "APIキーがありません",
  "txtNoCompanyAdd": "まだ会社情報を追加していません",
  "txtNoCompanyAddDesc": "最初の会社情報を追加するには、以下をクリックしてください。",
  "txtNoCountryRestriction": "国はありません。検索を使用して国をリストに追加します",
  "txtNoData": "レコードが見つかりません。クエリの範囲を広げてみてください。",
  "txtNoDataMemberInService": "オペレーターが追加されていません。最初にこのサービスにオペレーターを追加してください。 ",
  "txtNoFieldsAreUpdated": "フィールドは更新されません",
  "txtNoInvoiceDesc1": "請求書は毎月、請求先所有者の電子メール アドレスに送信されます。 ",
  "txtNoInvoiceDesc2": "現在ダウンロードできる発行済みの請求書はありません。",
  "txtNoServiceMatch": "検索に一致するサービスはありません",
  "txtNoServiceMatchDesc": "具体性を低くするか、別のキーワードを使用してみてください。",
  "txtNoSetupCost": "セットアップ費用はかかりません",
  "txtNoUsageDetail": "表示するデータがありません",
  "txtNoUsageDetailDesc": "現在利用可能な統計はありません。",
  "txtNone": "なし",
  "txtNoneApprovalDesc": "マニュアル： ",
  "txtNoneApprovalDesc1": "各ユーザーを手動で承認または拒否します。",
  "txtNormalDesc1": "1 か月あたり最大 100 個のプロファイル。",
  "txtNotStoreCard": "Blockpass はカードの詳細を保存しません。",
  "txtNotifications": "通知",
  "txtNotificationsDesc": "モバイル ユーザーにプッシュ通知を送信する",
  "txtOnboarding": "オンボーディング",
  "txtOnboardingGroup": "2. オンボーディング",
  "txtOnboardingSetting": "オンボーディング設定",
  "txtOnly": " のみ ",
  "txtOpenDashboard": "ダッシュボードを開く",
  "txtOperatorAssignment": "オペレータの割り当て",
  "txtOperatorAssignmentKYC": "オペレータの割り当て",
  "txtOperatorList": "オペレーター一覧",
  "txtOverview": "概要",
  "txtPOA": "住所証明",
  "txtPage403Desc1": "あなたには許可がありません\n このサービスにアクセスする",
  "txtPage403Desc2": "「サービス」に戻って適切なサービスを見つけるか、サービス所有者に問い合わせてください。",
  "txtParametersValidationError": "リクエストに無効なパラメータが含まれています。入力を再確認してください",
  "txtPassport": "パスポート",
  "txtPassword": "パスワード",
  "txtPayAsYouGo": "使った分だけ",
  "txtPaymentMethod": "支払方法",
  "txtPaymentMethodDeleted": "支払い方法を取得できないか、削除されました。 ",
  "txtPermissionDenied": "このアクションを実行する権限がありません",
  "txtPersonalAtt": "個人の属性",
  "txtPersonalAttributes": "個人の属性",
  "txtPhoneNumber": "電話番号",
  "txtPhoneNumberWrongFormat": "電話番号は数字でなければなりません",
  "txtPhoneRepresentative": "当社代表者の電話番号",
  "txtPickYourServiceName": "サービスの名前を選択してください",
  "txtPlan": "プラン",
  "txtPlanConfig": "計画構成",
  "txtPlanConfigDesc": "未払いのお金の支払いを求める請求書を人々に送るプロセス",
  "txtPlanDescription1": "1 日あたりおよびビジネスあたりの ID 数は @@num_ident@@ 未満です。\n",
  "txtPlanDescription2": "処理時間は24時間以上。標準 ",
  "txtPleaseCopyAPIKey": "APIキーをコピーしてください",
  "txtPleaseReplacePaymentMethod": "支払い方法を変更してください。",
  "txtPleaseSelectOperator": "オペレーターを選択してください。",
  "txtPleaseSelectUser": "ユーザーを選択してください。",
  "txtPostalCodeWrongFormat": "形式が間違っています。",
  "txtPrivacy": "プライバシー",
  "txtPrivacyPolicy": "プライバシーポリシー",
  "txtProfile": "プロフィール",
  "txtPromote": "推進する",
  "txtPromoteBpOperator": "推進する",
  "txtPromoteOperator": "演算子の追加",
  "txtPromoteOperatorSuccess": "オペレータを積極的に昇格させます。",
  "txtProofOfAddress": "住所証明",
  "txtProvideNameOfService": "サービスの名前を入力します",
  "txtPublicLinkDesc": "インターネット上のリンクを知っている人は誰でも、KYC プロフィールを送信できます。",
  "txtPublishService": "公開サービス",
  "txtPushNotification": "プッシュ通知",
  "txtRedirect": "リダイレクト",
  "txtRemove": "取り除く",
  "txtRemoveAccess": "アクセス権の削除",
  "txtRemoveBlockpassAccess": "Blockpass アクセスを削除する",
  "txtRemoveBlockpassAccessDesc": "Blockpass アクセスを削除してもよろしいですか?これにより、Blockpass サポート チームが KYC ダッシュボードにアクセスできなくなります。",
  "txtRemoveBpOperator": "取り除く",
  "txtRemoveInvitation": "招待状を削除する",
  "txtRemoveOperator": "演算子の削除",
  "txtRemoveOperatorDesc": "この演算子を削除してもよろしいですか?",
  "txtRemoveOperatorSuccess": "オペレータが正常に削除されました。",
  "txtRemoveTagConfirmContent": "このタグを削除してもよろしいですか?",
  "txtRemoveTagConfirmTitle": "「<0>」タグを削除します",
  "txtRemoveTagSuccess": "「<0>」タグが削除されました。",
  "txtRemoveThisMember": "このメンバーを削除する",
  "txtRemoveUser": "削除されたユーザー",
  "txtReplace": "交換する",
  "txtReplacePaymentMethod": "支払い方法を変更する",
  "txtReplacePaymentMethodDesc1": "新しいカードはすべてのサービスに使用されます ",
  "txtReplacePaymentMethodDesc2": "支払い方法の変更は、これを使用する多くのサービスに影響を与えることに注意してください。 \n支払方法。",
  "txtReport": "報告",
  "txtRepresentative": "代表",
  "txtRequestAccess": "アクセスをリクエストする",
  "txtRequestEnterprise": "Enterprise プランのリクエスト",
  "txtRequestEnterpriseSent": "Enterprise プランのリクエストを送信しました。",
  "txtRequired": "* 必須",
  "txtRequiredOneIdentityDocument": "少なくとも 1 つの身分証明書を選択してください",
  "txtRequirementPlaceHolder": "要件とスケジュールについて詳しく教えてください",
  "txtResAddress": "住宅アドレス",
  "txtResend": "再送信",
  "txtResidentialAddress": "住宅アドレス",
  "txtRestricted": "制限付き",
  "txtRestrictedDesc1": "Web サイトで認証された人のみが登録できます。",
  "txtRestrictedDesc2": "ユーザー refId をウィジェットに送信する場合は、このオプションを選択します。",
  "txtRestrictedDesc3": "あなたのウェブサイト上でウィジェットがインストールされている URL を入力してください。",
  "txtRestrictedDescLink": "詳細についてはドキュメントを参照してください。",
  "txtRestrictedLinkDesc1": "KYC プロファイルを送信する前に、ユーザーに Web サイトへのログインを強制します。",
  "txtRestrictedLinkDesc2": "ユーザーの refId をウィジェットに送信する場合は、このオプションを選択します。 ",
  "txtRestrictedLinkDesc3": "詳細についてはドキュメントを参照してください。",
  "txtReturnAttributeData1": "メタデータとアイデンティティ属性を返す",
  "txtReturnAttributeData2": "ピクチャー",
  "txtReturnAttributeDataExceptPicture1": "メタデータとアイデンティティ属性を返す",
  "txtReturnAttributeDataExceptPicture2": "ピクチャー",
  "txtReturnMetaData1": "戻る",
  "txtReturnMetaData2": "メタデータ (プロファイルのステータス、ID など)",
  "txtReturnToServices": "サービスに戻る",
  "txtRevoke": "取り消す",
  "txtRevokeOperator": "オペレータを取り消す",
  "txtRevokeOperatorDesc": "このオペレータを取り消してもよろしいですか?",
  "txtRevokeOperatorSuccess": "オペレータを正常に取り消しました。",
  "txtRole": "役割",
  "txtRuleArchiveTime": "1 ～ 9999 の数字",
  "txtRunningFlowConfig": "フロー構成の実行",
  "txtRunningFlowConfigDesc": "実行中のフロー構成の説明",
  "txtRuntimeConfig": "ランタイム構成",
  "txtRuntimeConfigDesc": "コンソールとダッシュボードのパラメータを構成する",
  "txtRussian": "ロシア",
  "txtSAVE": "保存",
  "txtSampleCode": "サンプルコード",
  "txtSave": "保存",
  "txtSaveChange": "変更内容を保存",
  "txtSaveChanges": "変更内容を保存",
  "txtScheduleDeleteProfilesSuccess": "プロファイル削除のスケジュールを正常に設定する",
  "txtScheduleForBulkDeletion": "一括削除のスケジュール",
  "txtScope": "範囲",
  "txtSearchActivityLogs": "ユーザー名/メールアドレスで検索",
  "txtSearchByEmail": "メールで検索",
  "txtSearchByNameOrClientId": "名前またはクライアント ID で検索",
  "txtSearchCountry": "国を検索する",
  "txtSearchPlaceHolder": "検索...",
  "txtSeconds": "秒",
  "txtSeeList": "リストを参照してください。",
  "txtSelectACryptoAddress": "暗号化アドレスを選択してください",
  "txtSelectAPlan": "プランを選択してください",
  "txtSelectAPlanToUnlock": "プランをアップグレードする",
  "txtSelectCryptoAddress": "暗号化アドレスを選択してください。",
  "txtSelectIdentityAttributes": "選択されたアイデンティティ属性",
  "txtSelectPlan": "プランを選択",
  "txtSelfie": "セルフィー",
  "txtSendInvite": "招待を送る",
  "txtSendInviteEmail": "招待状を送信しました!有効期限は3日間です",
  "txtServiceAccountant": "会計士",
  "txtServiceAccountantTooltip": "Admin Console では、[使用状況] タブとプラン設定のみを表示できます。",
  "txtServiceAllowBlockpassAccess": "ブロックパスアクセスを許可する",
  "txtServiceBillingOwner": "請求所有者",
  "txtServiceBillingOwnerTooltip": "コンソールとダッシュボードのすべての権限。会社情報や支払い方法を更新できます。",
  "txtServiceClientIdIsExisted": "クライアント ID はすでに存在します。別のクライアント ID を使用してください",
  "txtServiceCompanyInfomation": "の会社情報",
  "txtServiceDetail": "サービス内容",
  "txtServiceEmailIsRequired": "サポートメールアドレスは必須です",
  "txtServiceListInvalidPage": "無効なページング値",
  "txtServiceLogo": "サービスロゴ",
  "txtServiceLogoDesc": "サービスのロゴを今すぐアップロードすることも、後で [設定] で更新することもできます。",
  "txtServiceName": "サービス名",
  "txtServiceNameIsRequired": "サービス名は必須です",
  "txtServiceNameOrClientId": "サービス名またはクライアントID",
  "txtServiceNameOrClientIdPlaceholder": "サービス名またはクライアントIDを入力してください",
  "txtServiceNameTooltip": "サービスの名前を選択してください (最大 64 文字)",
  "txtServiceNotFound": "サービスが見つかりません。",
  "txtServiceOwner": "マネージャー",
  "txtServiceOwnerTooltip": "コンソールとダッシュボードのすべての権限。",
  "txtServicePortalIsRequired": "サポートポータルが必要です",
  "txtServiceReadOnlyRole": "読み取り専用",
  "txtServiceReadOnlyRoleTooltip": "プロフィールの表示のみ可能です。 KYC を処理できない、または設定を更新できない",
  "txtServiceReviewerTooltipkyc": "KYCのみを処理できます。承認、プロファイルの削除、または設定の更新ができない",
  "txtServiceReviewerkyc": "KYC審査員",
  "txtServiceTags": "サービスタグ",
  "txtServiceTagsDes": "このサービスのタグを追加または削除します",
  "txtServices": "サービス",
  "txtServicesUsage": "最近のサービス利用状況",
  "txtServicesUsageDesc": "未払いのお金の支払いを求める請求書を人々に送るプロセス",
  "txtSettings": "設定",
  "txtShareableDesc": "このページでは、申請者が自分の KYC 情報をダッシュ​​ボードに送信できます。リンクをコピーして、電子メールまたは共有したい場所に貼り付けます。",
  "txtShareableLink": "共有可能なリンク",
  "txtShareableLinkMustBeSetIfUsingRestrictedShareableMethod": "共有可能なリンクがありません",
  "txtShortDescription": "簡単な説明",
  "txtShortDescriptionTooltip": "サービスを説明する短いテキスト (フォーマットされていないテキストのみ)",
  "txtShowLess": "表示を少なくする",
  "txtShowMore": "もっと見せる",
  "txtShowRefId": "refid を表示",
  "txtShowSession": "ショーセッション",
  "txtShowThisService": "このサービスを表示する",
  "txtShowThisServiceDes": "マーケットプレイスに表示するには、このサービスをオンまたはオフにします。",
  "txtSolanaAddress": "ソラナ（SOL）の住所",
  "txtStartDate": "開始日",
  "txtStartingAt": "から開始",
  "txtStartingAtTooltip": "追加情報はありません。",
  "txtStatus": "状態",
  "txtStringMaxLengthError": "長さは <0> 以下の文字にする必要があります",
  "txtStripeError": "お支払い情報の確認中に問題が発生しました。サポートについてはお問い合わせください。",
  "txtSubscribe": "購読する",
  "txtSubscribeTo": "ニュースレターを購読する",
  "txtSubscribed": "購読済み",
  "txtSubscription": "サブスクリプション",
  "txtSupport": "サポート",
  "txtSupportPortal": "サポート Web ポータルの URL",
  "txtSupportPortalTooltip": "カスタマー サービス ポータル Web サイトの URL",
  "txtSupportrEmail": "サポートメールアドレス",
  "txtSupportrEmailTooltip": "カスタマーサポートのメールアドレス。このアドレスは、サポート ポータルの URL を指定しない場合に使用されます。デフォルトのアドレスは support@blockpass.org です",
  "txtTags": "タグ",
  "txtTaxNumberWrongFormat": "無効な形式。",
  "txtTeam": "チーム",
  "txtTestService": "テストサービス",
  "txtTheRequestWasMadeButNoResponseWasReceived": "ネットワークエラー",
  "txtThisFieldIsRequired": "この項目は必須です。",
  "txtTitleAllow": "許可されている国",
  "txtTitleExclude": "除外される国",
  "txtToGetStart": "始めるには、ログインしてください。",
  "txtToday": "今日",
  "txtTools": "ツール",
  "txtTooltipAddressCountryRestriction": "国の制限が有効になっている場合は住所が必要です",
  "txtTooltipAddressProofOfAddress": "住所証明を選択した場合は住所が必須です",
  "txtTotal": "合計",
  "txtTotalForMonth": "月の合計 ",
  "txtTwoFaBannerDesc": "データを保護するために、2 要素認証 (2FA) が必要になりました。今すぐ有効にするか、次回のログイン時に有効にするように求められます。",
  "txtTwoFaBannerTitle": "2FA でアカウントを保護する",
  "txtTwoFaButtonEnableNow": "今すぐ 2FA を有効にする",
  "txtTypeSomthing": "何かを入力...",
  "txtUSDperCheck": "米ドル/小切手",
  "txtUnAuthorized": "セッションが期限切れか無効です。再度ログインしてください。",
  "txtUnauthorized": "セッションが期限切れか無効です。再度ログインしてください。",
  "txtUnlimittedCollaborator": "無制限のコラボレーター",
  "txtUnsubscribe": "購読を解除する",
  "txtUpdate": "アップデート",
  "txtUpdateAPIKey": "APIキーの更新",
  "txtUpdateApiKey": "APIキーを更新する",
  "txtUpdateApiKeySuccess": "APIキーが更新されました",
  "txtUpdateApproveMode": "更新承認モード",
  "txtUpdateBillingPlanDisplay": "料金プラン表示の更新",
  "txtUpdateBillingPlanKycBot": "KYC ボット構成を更新する",
  "txtUpdateBillingPlanVerification": "KYC チェック構成を更新する",
  "txtUpdateBpOperatorRole": "役割を変更する",
  "txtUpdateCertificate": "更新された証明書",
  "txtUpdateCheckConfig": "アップデートチェック構成",
  "txtUpdateCompany": "会社を更新する",
  "txtUpdateCompanyInfoDesc": "会社情報を更新します。",
  "txtUpdateEnterpriseDesc1": "個人データの無料保管",
  "txtUpdateFlowConfig": "フロー構成の更新",
  "txtUpdateFreePlanDescription": "未確認の身元情報を受け取ります。データ保持: 1週間",
  "txtUpdateIdentityField": "ID フィールドを更新しました",
  "txtUpdateInfo": "アップデート情報",
  "txtUpdateKYCSettings": "更新されたKYC設定",
  "txtUpdateNormalDesc1": "個人データの保持: 最大 4 週間",
  "txtUpdateOperator": "更新演算子",
  "txtUpdateOperatorSuccess": "オペレータを正常に更新しました。",
  "txtUpdatePlanDescription1": "個人データの保持: 最大 4 週間",
  "txtUpdatePlanDescription2": "処理時間は異なる場合があります。標準 ",
  "txtUpdatePlanSuccess": "サービス料金プランが正常に更新されました",
  "txtUpdateRuntimeCfg": "更新されたランタイム構成",
  "txtUpdateService": "更新されたサービス",
  "txtUpdateServiceSuccess": "すべての変更が保存されました",
  "txtUpdateWebhook": "Webhook を更新する",
  "txtUpdateWebhookSuccess": "Webhook が更新されました",
  "txtUploadALogo": "ロゴをアップロードする ",
  "txtUrlPrivacyPolicy": "プライバシー ポリシーの URL",
  "txtUrlPrivacyPolicyTooltip": "ユーザー登録前にプライバシーポリシーへのリンクが表示されます",
  "txtUrlTermsAndConditions": "利用規約の URL",
  "txtUrlTermsAndConditionsTooltip": "ユーザー登録前に利用規約へのリンクが表示されます",
  "txtUsage": "使用法",
  "txtUsageMetrics": "使用状況メトリクス",
  "txtUserDebugger": "ユーザーデバッガ",
  "txtUserDebuggerDesc": "メールまたはsupportIdでユーザーを検索",
  "txtUserId": "ユーザーID",
  "txtUserSessionExpired": "ユーザーセッションの有効期限が切れました",
  "txtUserSessionExpiredDesc": "ログアウトされました。もう一度ログインしてください",
  "txtUsername": "ユーザー名",
  "txtUsernameOrEmail": "ユーザー名または電子メール",
  "txtUsernameOrEmailPlaceholder": "ユーザー名またはメールアドレスを入力してください",
  "txtVATNumber": "VAT番号",
  "txtVerification": "検証",
  "txtVerificationGroup": "1. 検証",
  "txtVerifications": "検証",
  "txtViewDetails": "詳細を見る",
  "txtVisitThePage": "ページにアクセスしてください",
  "txtWEEK": "週",
  "txtWarningApprovalMode": "次のステップに進む前に、承認モードを確認してください",
  "txtWavesAddress": "ウェーブアドレス",
  "txtWebhooks": "Webhook",
  "txtWebsite": "ウェブサイトの URL",
  "txtWebsiteIntegration": "ウィジェットの統合",
  "txtWebsiteTooltip": "公式ウェブサイトへのリンク",
  "txtWebsiteUrlIsRequired": "このフィールドを記入してください",
  "txtWelcomeBody": "顧客の身元を確認するサービスを作成、構成、監視します。",
  "txtWelcomeTitle": "Blockpass 管理コンソールへようこそ",
  "txtWelcomeTo": "へようこそ",
  "txtWhoCanResgister": "誰が登録できますか?",
  "txtWillBeDeleted": "削除されます。 ",
  "txtYEAR": "年",
  "txtYesterday": "昨日",
  "txtYourApiKey": "API キー",
  "txtYourCard": "あなたのカード",
  "txtYourCompanyInfo": "あなたの会社情報",
  "txtYourEmailAddress": "あなたのメールアドレス",
  "txtYourLink": "あなたのリンク",
  "txtYourLinkCopiedToClipboard": "リンクがクリップボードにコピーされました",
  "txtYourPaymentMethod": "お支払い方法",
  "txtYourPaymentMethodGroup": "4. 支払い方法",
  "txtYourServiceConfigCopiedToClipboard": "サービス構成がクリップボードにコピーされました",
  "txtYourServiceCreated": "サービスが作成されました",
  "txtYourServiceLink": "サービスリンク",
  "txtYourWebsite": "https://あなたのウェブサイト.com",
  "txtYourWebsiteUrl": "ウェブサイトの URL",
  "txtYourWebsiteUrlDesc": "ウィジェットをインストールした Web サイトの URL を入力してください",
  "txtdelete": "消去",
  "txtdonwloadMonitorReport": "モニターレポートのダウンロード",
  "txtsettingUpdated": "設定が更新されました"
} // eslint-disable-line no-template-curly-in-string