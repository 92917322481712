import { makeObservable, observable } from "mobx";
import BaseViewStore from "@stores/base/BaseViewStore";

const STORE_NAME = "ServiceDetail.ViewStore";
// ---------------------------------

class ViewStore extends BaseViewStore {
  constructor(rootStore) {
    super(rootStore, STORE_NAME);
    this._initObserver();
    makeObservable(this, {
      ready: observable,
      loading: observable,
      selectedIndex: observable,
      selectedKey: observable,
      previousSelectedKey: observable,
      previousSelectedIndex: observable,
      isExpand:  observable,
      isSubMenu:  observable,

      openSubMenu: observable,
    });
  }

  _initObserver() {
    this.ready = false
    this.loading = false
    this.selectedIndex = 0
    this.previousSelectedKey = 'display'
    this.previousSelectedIndex = 0
    this.selectedKey = ''
    this.isExpand = false
    this.isSubMenu = false
    this.openSubMenu = false
  }



  
  // -----------------------------------------------------//
  //  Override Life circle
  // -----------------------------------------------------//
  async start(rootStore) {
    await super.start(rootStore);

    this.ready = true;
  }

  
  //
  setSelectedTab(index, key, isExpand = false, forceOpenSubmenu = false) {

    // webhook -> no change active
    if(key === 'webhook') {
      this.isExpand = isExpand
      // selected webhook - no active load detail
      if(this.openSubMenu &&
        (this.previousSelectedKey === 'webhooksettings' || this.previousSelectedKey === 'webhooklogs')) {
          this.selectedIndex = index
      }
      if(!this.openSubMenu &&
      (this.previousSelectedKey === 'webhooksettings' || this.previousSelectedKey === 'webhooklogs')) {
        this.selectedIndex = this.previousSelectedIndex
        this.selectedKey = this.previousSelectedKey
      }  
      if( forceOpenSubmenu === true){
        if(this.previousSelectedKey === 'webhooksettings' || this.previousSelectedKey === 'webhooklogs') {
          this.selectedIndex = this.previousSelectedIndex
          this.selectedKey = this.previousSelectedKey
        } else { //select webhook + open submenu
          this.selectedIndex = this.previousSelectedIndex
          this.selectedKey = this.previousSelectedKey
        }
      }
       
      return false  
    } else {
      
         
     
      if(this.selectedIndex === index)
        return false
     
      this.selectedIndex = index
      this.selectedKey = key
      this.isExpand = isExpand

      //previous      
      this.previousSelectedKey = this.selectedKey
      this.previousSelectedIndex = this.selectedIndex
      
    }
    return true
    
  }

  getUpdateDetail = () => {
   
    // if(this.isExpand){
    //   return this.previousSelectedKey
    // }
    // else 
    return this.selectedKey
  }
}

export default ViewStore;
